<template>

    <!-- 
        Головний таб, містить в собі -
        - опис
        - передумови
        - чек лист
        - тест кейси
        - та інше
    -->

    <div class="card" >
        <div class="card-body" >
            <div class="text-muted" >
                <h6 class="mb-3 fw-semibold text-uppercase">{{ $t('description') }}</h6>
                
                <div class="ql-container">
                    <div class="ql-editor" v-html="form.description">
                    </div>
                </div>
            </div>

            <!-- 
                Середовище тестування (операційна система, версія і тд)
            -->
            <deviceBlock 
                v-if="form.environments.length > 0"
                :objCard="this.form"
            />

            <!-- передумови -->
            <div class="preconditions mt-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('Prerequisites') }}</h6>
                    <hr>
                </div>
                <ul class="ps-4 vstack gap-2 mt-3">
                    <template v-for="item in form.preconditions" :key="item">
                        <li>{{ item.preconditionText }}</li>
                    </template>
                </ul>
            </div>

            <div v-if="form.testingPlan == 'checklist'" class="checklist mt-4 mb-4" >
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('Checklist') }}</h6>
                    <hr>
                </div>
                
                <div class="d-flex mt-3" >
                    <p class="text-muted flex-grow-1">{{ $t('descTodoChecklist') }}</p>
                    <div class="flex-shrink-0" >
                        <button 
                            @click="showNoteCL = !showNoteCL"
                            id="info" 
                            type="button" 
                            class="btn btn-soft-info waves-effect waves-light"
                        >
                            <i class="bx bxs-info-circle"></i> {{ $t('note') }}
                        </button>
                    </div>
                </div>

                <b-col lg="6 mt-2" v-if="showNoteCL">
                    <b-alert show variant="info">
                        <b>{{ $t('NoteOntheResults') }}:</b>
                        <ul class="ps-4 vstack gap-2 mt-3">
                            <li>Успішно (passed) - перевірка пройдена успішно, багів не знайдено</li>
                            <li>Не вдалось (failed) - знайдений один або більше багів</li>
                            <li>Блокуючий (blocked) - неможливо перевірити, тому що один з багів блокує поточну перевірку</li>
                            <li>В роботі (in_progress) - поточний пункт, над яким працює тестувальник</li>
                            <li>Не розпочато (not_run) - ще не перевірено</li>
                            <li>Пропущено (skipped) - пункт перевірятися не буде з певної причини. Наприклад, поточний функціонал ще не реалізований.</li>
                        </ul>
                    </b-alert>
                </b-col>


                <div class="live-preview mt-3">
                    <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">{{ $t('CheckItem') }}</th>
                                    <th scope="col">{{ $t('Result') }}</th>
                                    <th scope="col">{{ $t('reports') }}</th>
                                    <th scope="col">{{ $t('action') }}</th>
                                </tr>
                            </thead>
                            <tbody>

                                <template v-for="item in form.clauses" :key="item">
                                    <tr>
                                        <td class="fw-medium">{{ item.clauseName }}</td>
                                        <td 
                                            :class="mutateStatusChecklist(item.result).color"
                                            style="padding: 0px;padding-top: 8px;"
                                        >
                                        
                                            <select 
                                                :class="`form-select ${mutateStatusChecklist(item.result).color} text-white`" 
                                                v-model="item.result" 
                                                style="border: none;"
                                            >
                                                <option v-for="option in listStatusChecklist" :key="option" :value="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>

                                            <template 
                                                v-if="item.reports.length > 0"
                                                v-for="i in item.reports" :key="i"
                                            >
                                                <span 
                                                    @click="openReport(e)"
                                                    class="badge badge-soft-info fs-12 copy" 
                                                    style="margin-right: 4px;"
                                                >
                                                    {{ i.id }} <i class="ri-external-link-line"></i>
                                                </span>
                                            </template> 
                                            <template v-else>
                                                <span>-</span>
                                            </template>

                                        </td>
                                        <td>
                                            <span>
                                                <button 
                                                    @click="addReport(item)"
                                                    type="button" 
                                                    class="btn btn-sm btn-soft-info fs-13"
                                                >
                                                    {{ $t('AddReport') }}
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div v-if="form.testingPlan == 'testcases'" class="testCases mt-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('TestCases') }}</h6>
                    <hr>
                </div>
                
                <div class="d-flex mt-3" >
                    <p class="text-muted flex-grow-1">{{ $t('descTodoChecklist') }}.</p>
                    <div class="flex-shrink-0" >
                        <button 
                            @click="showNoteCL = !showNoteCL"
                            id="info" 
                            type="button" 
                            class="btn btn-soft-info waves-effect waves-light"
                        >
                            <i class="bx bxs-info-circle"></i> {{ $t('note') }}
                        </button>
                    </div>
                </div>

                <!-- Нотатка по статусам -->
                <b-col lg="6 mt-2" v-if="showNoteCL">
                    <b-alert show variant="info">
                        <b>{{ $t('NoteOntheResults') }}:</b>
                        <ul class="ps-4 vstack gap-2 mt-3">
                            <li>Успішно (passed) - перевірка пройдена успішно, багів не знайдено</li>
                            <li>Не вдалось (failed) - знайдений один або більше багів</li>
                            <li>Блокуючий (blocked) - неможливо перевірити, тому що один з багів блокує поточну перевірку</li>
                            <li>В роботі (in_progress) - поточний пункт, над яким працює тестувальник</li>
                            <li>Не розпочато (not_run) - ще не перевірено</li>
                            <li>Пропущено (skipped) - пункт перевірятися не буде з певної причини. Наприклад, поточний функціонал ще не реалізований.</li>
                        </ul>
                    </b-alert>
                </b-col>

                <div class="live-preview mt-3">
                    <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th scope="col">№</th>
                                    <th scope="col">{{ $t('Step') }}</th>
                                    <th scope="col">{{ $t('TestData') }}</th>
                                    <th scope="col">{{ $t('expectedResult') }}</th>
                                    <th scope="col">{{ $t('Result') }}</th>
                                    <th scope="col">{{ $t('reports') }}</th>
                                    <th scope="col">{{ $t('action') }}</th>
                                </tr>
                            </thead>
                            <tbody>

                                <template v-for="(item, index) in form.clauses" :key="index">
                                    <tr>
                                        <td class="fw-medium">{{ index+1 }}</td>
                                        <td class="fw-medium">{{ item.clauseName }}</td>
                                        <td class="fw-medium">{{ item.test_data }}</td>
                                        <td class="fw-medium">{{ item.expectedResult }}</td>
                                        <td 
                                            :class="mutateStatusChecklist(item.result).color"
                                            style="padding: 0px;padding-top: 8px;"
                                        >
                                        
                                            <select 
                                                :class="`form-select ${mutateStatusChecklist(item.result).color} text-white`" 
                                                v-model="item.result" 
                                                style="border: none;"
                                            >
                                                <option v-for="option in listStatusChecklist" :key="option" :value="option.value">
                                                    {{ option.text }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <template 
                                                v-if="item.reports.length > 0"
                                                v-for="i in item.reports" :key="i"
                                            >
                                                <span class="badge badge-soft-info fs-12 copy" style="margin-right: 4px;">{{ i.id }} <i class="ri-external-link-line"></i></span>
                                            </template> 
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </td>
                                        <td>
                                            <span><button type="button" class="btn btn-sm btn-soft-info fs-13">{{ $t('AddReport') }}</button></span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            
        </div>
    </div>

    <!-- Перегляд картки репорту -->
    <reportBox 
        v-if="showReportBox.show == true"
        @close="showReportBox.show = false"
        :objCard="showReportBox.obj"
    />

    <!-- Картка створення репорту -->
    <createdReport
        v-if="showCreatedReport.show == true"
        @close="showCreatedReport.show = false"
        :objItem="showCreatedReport.item"
        :objCard="form"
    />

</template>

<script>
import deviceBlock from './params_device/index'
import reportBox from '../reports/view/index'
import createdReport from '../reports/created'
import { mutateStatusChecklist } from '@/usabilityScripts/globalMutate'

export default {
    props: ['objCard'],
    components: {
        deviceBlock,
        reportBox,
        createdReport
    },
    data() {
        return {
            form: '',
            showNoteCL: false, // прапорець відображення нотатки
            listStatusChecklist: [ // статуси до пунктів чек листа
                { value: 'passed', text: 'Успішно' },
                { value: 'failed', text: 'Не вдалося' },
                { value: 'blocked', text: 'Блокуючий' },
                { value: 'in_progress', text: 'В роботі' },
                { value: 'not_run', text: 'Не перевірено' },
                { value: 'skipped', text: 'Пропущено' },
            ],
            showReportBox: {
                obj: '',
                show: false
            },
            showCreatedReport: {
                obj: '',
                item: '',
                show: false
            }
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        addReport(item){
            this.showCreatedReport.item = item;
            this.showCreatedReport.show = true;
        },
        openReport(e){
            // this.showReportBox.obj = e;
            // this.showReportBox.show = true;
        },
        mutateStatusChecklist(e){
            // мутейт статусу пункту чек листа
            return mutateStatusChecklist(e)
        },
    }
}
</script>

<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
}

.header-line-container h6 {
    margin: 0;
    padding-right: 10px; /* Відступ між заголовком і лінією */
}

.header-line-container hr {
    flex-grow: 1;
    border: none;
    /* border-top: 2px solid #000; /* Можна змінити товщину та колір лінії */
    margin: 0;
}
</style>