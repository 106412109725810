<template>
    <!-- Модальне вікно для вибору тегів -->

    <modal @close="$emit('close')">
        <!-- Заголовок модального вікна -->
        <template v-slot:title>{{ $t('labels') }}</template>

        <!-- Тіло модального вікна з фільтром, списком тегів та кнопкою додавання нового тегу -->
        <template v-slot:body>
            <b-card v-if="this.typeTags != 'tags'">
                <!-- Поле для пошуку тегів -->
                <input type="text" v-model="search" class="form-control" :placeholder="$t('searc_inp')">

                <label class="fs-12 fw-semibold mt-3">{{ $t('labels') }}</label>
                <!-- Список тегів, які можна вибирати -->
                <div class="list-group">
                    <ul class="list-group list-group-flush border-dashed">
                        <!-- Елемент списку для кожного тегу -->
                        <li
                            class="list-group-item ps-0 dragable"
                            v-for="item in filteredItems" 
                            :key="item"
                        >
                            <div class="d-flex align-items-start tagsSelect">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <!-- Чекбокс для вибору тегу -->
                                    <input 
                                        type="checkbox" 
                                        class="form-check-input ms-0 fs-16 mt-2"  
                                        :id="item.tagName"
                                        :value="item.tagId"
                                        v-model="idsTags"
                                    />
                                </div>
                                <!-- Інформація про тег та його колір -->
                                <div class="flex-grow-1">
                                    <label 
                                        class="form-check-label mb-0 ps-2" 
                                        :style="`background: ${item.color}`"
                                        :for="item.tagName"
                                    >
                                        {{item.tagName}}
                                    </label>
                                </div>

                                <!-- Кнопка для редагування тегу -->
                                <div class="flex-shrink-0" >
                                    <div class="mt-1">
                                        <button type="button" class="btn btn-info btn-sm btn-icon waves-effect" @click="editModal(item)"><i class="ri-pencil-line fs-14"></i></button>
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                <hr />

                <!-- Кнопка для додавання нового тегу -->
                <div>
                    <button type="button" class="btn btn-soft-dark waves-effect waves-light w-100" @click="createBox = true">{{ $t('addNewTag') }}</button>
                </div>
            </b-card>
            <b-card v-else>
                <!-- Поле для пошуку тегів -->
                <input type="text" v-model="search" class="form-control" :placeholder="$t('searc_inp')">

                <label class="fs-12 fw-semibold mt-3">{{ $t('labels') }}</label>
                <!-- Список тегів, які можна вибирати -->
                <div class="list-group">
                    <ul class="list-group list-group-flush border-dashed" style="max-height: 500px; overflow: auto">
                        <!-- Елемент списку для кожного тегу -->
                        <li
                            class="list-group-item ps-0 dragable"
                            v-for="item, index in this.tags.filter(el => {
                                return this.search == '' || el.hashtagName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1
                            })" 
                            :key="index"
                        >
                            <div class="d-flex align-items-start tagsSelectTickets">
                                <div class="form-check ps-0 flex-sharink-0">
                                    <!-- Чекбокс для вибору тегу -->
                                    <input 
                                        type="checkbox" 
                                        class="form-check-input ms-0 fs-16 mt-2"  
                                        :id="item.hashtagName"
                                        :value="item.hashtagId"
                                        v-model="idsTags"
                                    />
                                </div>
                                <!-- Інформація про тег та його колір -->
                                <div class="flex-grow-1">
                                    <label 
                                        v-if="!item.new"
                                        class="form-check-label mb-0 ps-2" 
                                        :for="item.hashtagName"
                                    >
                                        {{item.hashtagName}}
                                    </label>
                                    <input 
                                        style="margin: 0 5%; width: 90%;"
                                        v-else
                                        type="text" 
                                        class="form-control mb-0 ps-2" 
                                        v-model="item.hashtagName"
                                    />
                                </div>

                                <!-- Кнопка для видалення тегу -->
                                <div class="flex-shrink-0" >
                                    <div class="mt-1">
                                        <button type="button" class="btn btn-danger btn-sm btn-icon waves-effect" @click="deleteHashTag(item)">
                                            <i class="ri-delete-bin-line fs-14"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </li>
                    </ul>
                </div>
                <hr />

                <!-- Кнопка для додавання нового тегу -->
                <div v-if="this.typeTags !== 'tags'">
                    <button type="button" class="btn btn-soft-dark waves-effect waves-light w-100" @click="createBox = true">{{ $t('addNewTag') }}</button>
                </div>
                <div v-else>
                    <button type="button" class="btn btn-soft-dark waves-effect waves-light w-100" @click="this.addItem()">{{ $t('addNewTag') }}</button>
                </div>
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка "Скасувати"-->
                <button 
                    v-if="!item && this.typeTags != 'tags'" 
                    class="btn btn-danger"
                    @click="$emit('close')"
                >
                    {{ $t('cancel') }}
                </button>

                <!-- Кнопка "Додати" або "Зберегти", видима при редагуванні -->
                <button 
                    v-if="!item && this.typeTags != 'tags'" 
                    class="btn btn-success" 
                    @click="$emit('selectedTags', idsTags);$emit('close')"
                >
                    {{ $t('Add') }}
                </button>


                <!-- Кнопка "Додати" або "Зберегти", видима при редагуванні -->
                <button 
                    v-if="!item && this.typeTags === 'tags'" 
                    class="btn btn-success" 
                    @click="saveTagsByTicket"
                >
                    {{ $t('save') }}
                </button>
            </div>
        </template>
    </modal>

    <!-- Вікно для створення/редагування тегу -->
    <createTag 
        v-if="createBox"
        @close="closeEdit"
        :item="item"
        @addTag="addTag"
        @editTag="editTag"
        @deleteTag="deleteTag"
        :typeTags="this.typeTags"
    />

    <!-- Вікно для створення/редагування тегу -->
    <deleteHashTagModal 
        v-if="showDeleteHashTagModal"
        @close="this.showDeleteHashTagModal = false"
        :item="item"
    />

</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import modal from '@/components/modal/dialog'
import createTag from '@/components/tags/add'
import deleteHashTagModal from '@/components/tags/deleteHashTag'
import { storeS } from '@/store'
import { Tags, faqPostTags, lessonTags, HashTags, Tickets } from '@/API'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Tags();
let apifaqTags = new faqPostTags();
let apilessonTags = new lessonTags();
let apiHashTags = new HashTags()
let apiTickets = new Tickets()

export default{
    props: ['typeTags', 'checkedTags', 'ticketId'],
    components: {
        deleteHashTagModal,
        modal,
        createTag
    },
    data(){
        return{
            form: '',
            search: '', // пошук
            createBox: false, // прапорець на відображення модального вікна створення
            item: '',
            tags: [],
            idsTags: []
        }
    },
    created(){
        console.log('f', this.checkedTags);
        this.checkedTags.forEach(el => {
            console.log(el)
            this.idsTags.push(el.hashtagId)
        })

        if(this.typeTags === 'tags') {
            apiHashTags.getHashtags().then(result => {
                if(result.status === 'done') {
                    this.tags = result.data.items
                }
            });
        } else {
            this.switchTags();
        }
    },
    methods: {
        updateHashtags() {
            apiServe.getTicket(id).then(result => {
                if(result.status === 'done') {
                    this.idsTags = result.data.hashtags
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка оновлення даних про тікет тікету'; 
                    var statusRequest = result.status;
                    var api = 'getTicket';
                    var fileRequest = 'src/components/tags/list.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addItem(){
            let id = uuidv4()
            this.tags.push({hashtagName: null, hashtagId: id, new: true})
            this.idsTags.push(id)
        },
        saveTagsByTicket() {
            let obj = {
                hashtags: []
            }

            this.idsTags.forEach((el) => {
                obj.hashtags.push({
                    hashtagName: this.tags.find(elements => elements.hashtagId === el).hashtagName,
                    hashtagId: isNaN(Number(el)) ? null : el
                })
            })
            
            apiHashTags.saveHashtags(this.ticketId, obj).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.$emit('close')
                    this.$emit('updateCard')
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка збереження хештегів по тікету'; 
                    var statusRequest = "error";
                    var api = 'saveHashtags';
                    var fileRequest = 'src/components/tags/list.vue';
                    var params = id;
                    var response = JSON.stringify({
                        id: this.ticketId,
                        form: obj
                    })

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        deleteHashTag(item) {
            apiHashTags.deleteHashtag(item.hashtagId).then(result => {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('done'))
                    this.updateHashtags()
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка видалення хештегу'; 
                    var statusRequest = "error";
                    var api = 'deleteHashtag';
                    var fileRequest = 'src/components/tags/list.vue';
                    var params = id;
                    var response = JSON.stringify({
                        id: item.hashtagId,
                    })

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        switchTags(){
            // Вибір типу тегів
            switch (this.typeTags) {
                case 'faq':
                    this.tags = this.tagsFaq
                    break;
                case 'kw':
                    this.tags = this.tagsLessons
                    break;
                // case 'tags':
                //     this.tags = this.mainTags
                //     break;
            
                default:
                    break;
            }
        },
        addTag(type, e){
            // Додавання нового тегу
            switch (type) {
                case 'faq':
                    apifaqTags.addTag(e).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Added'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
                case 'kw':
                    apilessonTags.addTag(e).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Added'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
                case 'tags':
                    apiServe.addTag(e).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Added'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
            
                default:
                    break;
            }
        },
        editTag(type, e){
            // Редагування тегу
            switch (type) {
                case 'faq':
                    apifaqTags.editTag(e, e.tagId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('changeswereSuccessful'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
                case 'kw':
                    apilessonTags.editTag(e, e.tagId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('changeswereSuccessful'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
                case 'tags':
                    apiServe.editTag(e, e.tagId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('changeswereSuccessful'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
            
                default:
                    break;
            }
        },
        deleteTag(type, e){
            // Видалення тегу
            switch (type) {
                case 'faq':
                    apifaqTags.deleteTag(e.tagId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
                case 'kw':
                    apilessonTags.deleteTag(e.tagId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
                case 'tags':
                    apiServe.deleteTag(e.tagId).then(result => {
                        if(result.status == 'done'){
                            this.$toast.success(this.$t('Removed'))
                            this.tags = result.data.items;
                        } else {
                            this.$toast.error(this.$t('error'))
                        }
                    })
                    break;
            
                default:
                    break;
            }
        },
        editModal(e){
            // Відкриття модального вікна для редагування тегу
            this.item = e;
            this.createBox = true
        },
        closeEdit(){
            // Закриття вікна редагування тегу
            this.item = '';
            this.createBox = false;
        }
    },
    computed: {
        filteredItems: function() {
            // Фільтрація тегів за полем пошуку
            return Object.values(this.tags)
                .filter(item => {
                    return this.search == '' || item.tagName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        tagsFaq(){
            // беремо теги по faq з глобального стору
            return storeS.tagsFaq
        },
        tagsLessons(){
            // беремо теги по урокам з глобального стору
            return storeS.tagsLessons
        },
        // mainTags(){
        //     // беремо власні теги з глобального стору
        //     return storeS.tags
        // },
    }
}
</script>

<style>
.tagsSelect label {
    padding: 8px;
    border-radius: 7px;
    margin: 0px 11px;
    color: white;
    font-weight: bold;
    word-break: break-word;
    width: 90%;
}

.tagsSelectTickets label {
    padding: 8px;
    border-radius: 7px;
    margin: 0px 11px;
    font-weight: bold;
    word-break: break-word;
    width: 90%;
}

.tagsSelect label:hover{
    transition: .3s;
    filter: saturate(85%) brightness(85%);
}
</style>