<template>
    <!-- модальное окно просмотра заявки -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="this.$emit('close')" :styleAdd="'backdrop-filter: blur(0px) !important;'">
        <template v-slot:title>
            {{ form.status == 'new' ? $t('newApplication') : $t('Application') }} #{{ this.form.requestId }}
        </template>
        <template v-slot:status>
            <span :style="form.action ? `margin-left: 5px;` : ''" :class="`badge badge ${this.mutateStatus(this.form.status, this.form).color} align-middle fs-14`">{{this.mutateStatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <div class="dropdown">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item" v-if="this.perms[105] == true && this.form.status != 'jobs'" @click="this.$emit('edit', this.form)">{{ this.$t('Edit') }}</button>
                    <button class="dropdown-item" v-if="this.perms[105] == true" @click="this.showModalDelay = true">{{ this.$t('postpone') }}</button>
                    <button class="dropdown-item" v-if="this.perms[102] == true" @click="this.showModalTransfer = true">{{ this.$t('Submit') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                <h5 class="alert-heading" v-if="this.user.userid != form.transferWorkerId">
                    <b>{{ this.form.inJobWorkerName }} {{ $t('submittedRequest') }} {{ nameWorker(form.transferWorkerId) }}</b>
                </h5>
                <h5 class="alert-heading" v-else>
                    <b>{{ this.form.inJobWorkerName }} {{ $t('sentYouRequest') }}</b>
                </h5>
            </b-alert>
            <b-row>
                <b-col lg="4">
                    <aboutBlock 
                        :obj="this.form"
                        :trigger="this.form.triggers"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4">
                    <clientBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4" v-if="form.inJobWorkerId != null">
                    <jobsBlock
                        :obj="this.form"
                    />
                </b-col>

                <b-col lg="4" v-if="form.closeWorkerId != null">
                    <closedBlock 
                        :obj="this.form"
                    />
                </b-col>
                <b-col lg="4" v-if="form.cancelWorkerId != null">
                    <canceledBlock 
                        :obj="this.form"
                    />
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>

                <button 
                    v-if="this.form.status != 'jobs' && this.perms[104] == true"
                    type="button"  
                    class="btn btn-success r waves-effect waves-light"
                    @click="addInJob"
                >
                    {{ $t('takeToWork') }}
                </button>

                <button 
                    v-if="this.perms[103] == true"
                    type="button"  
                    class="btn btn-danger r waves-effect waves-light"
                    @click="this.showModalCancel = true"
                >
                    {{ $t('cancel') }}
                </button>

                <button 
                    v-if="this.perms[103] == false"
                    type="button"  
                    class="btn btn-warning r waves-effect waves-light"
                    @click="this.showModalReportCancel = true"
                >
                    {{ $t('AddReport') }}
                </button>

                <button 
                    v-if="this.perms[103] == true && this.form.status != 'jobs'"
                    type="button"  
                    class="btn btn-danger r waves-effect waves-light"
                    @click="this.showModalRemove = true"
                >
                    {{ $t('Delete') }}
                </button>
                
                <button 
                    v-if="this.form.status == 'transfered' && this.form.transfer.workerId == this.user.userid"
                    type="button"
                    @click="rejectTransfer()"
                    :class="`btn btn-warning ss waves-effect waves-light`"
                >
                    {{ $t('Refusal') }}
                </button> <!-- відмовитись від передачі заявки -->

                <button 
                    v-if="this.form.status == 'transfered' && this.form.transfer.workerId == this.user.userid"
                    type="button"
                    @click="confirmTransfer()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('ConfirmTransfer') }}
                </button> <!-- підтвердити передачу -->

                <button 
                    v-if="this.perms[104] && this.form.status == 'jobs' && this.form.inJobWorkerId == this.user.userid"
                    type="button"
                    @click="this.showModalClose = true"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('done') }}
                </button> <!-- підтвердити передачу -->


        </template>
    </modal>

    <modalDelay 
        v-if="this.showModalDelay === true"
        :obj="this.form"
        @toDelay="addInDelay"
        @close="this.showModalDelay = false"
    />

    <modalTransfer 
        v-if="this.showModalTransfer === true"
        :obj="this.form"
        @transferRequest="addInTransfer"
        @close="this.showModalTransfer = false"
    />
    
    <modalCancel 
        v-if="this.showModalCancel === true"
        :obj="this.form"
        @toCancel="addInCancel"
        @close="this.showModalCancel = false"
    />
    
    <modalReportCancel 
        v-if="this.showModalReportCancel === true"
        :obj="this.form"
        @toCancelReport="addCancelReport"
        @close="this.showModalReportCancel = false"
    />
    
    <modalRemove 
        v-if="this.showModalRemove === true"
        :obj="this.form"
        @remove="addInDelete"
        @close="this.showModalRemove = false"
    />
    
    <modalClose 
        v-if="this.showModalClose === true"
        :obj="this.form"
        @toClose="addInClose"
        @close="this.showModalClose = false"
    />

</template>

<script>
import modal from '@/components/modal/modal-lg'
import { mutateStatus } from '@/usabilityScripts/globalMutate.js'
import { TriggerCalls } from '@/API.js'
import aboutBlock from './blocks/about'
import clientBlock from './blocks/client'
import jobsBlock from './blocks/jobs'
import closedBlock from './blocks/closed'
import canceledBlock from './blocks/canceled'
import { storeS } from '../../../../store'
import modalDelay from './actions/delay.vue'
import modalTransfer from './actions/transfer.vue'
import modalCancel from './actions/cancel.vue'
import modalReportCancel from './actions/cancelReport.vue'
import modalRemove from './actions/delete.vue'
import modalClose from './actions/close.vue'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiTriger = new TriggerCalls()  

export default ({
    data(){
        return {
            componentKey: 0,
            form: '',
            showModalDelay: false,
            showModalTransfer: false,
            showModalCancel: false,
            showModalReportCancel: false,
            showModalRemove: false,
            showModalClose: false
        }
    },
    props: ["shortapplic", 'ifPrevopen', 'ifNextopen'],
    components: {
        modal,
        aboutBlock,
        clientBlock,
        jobsBlock,
        closedBlock,
        canceledBlock,
        modalDelay,
        modalTransfer,
        modalCancel,
        modalReportCancel,
        modalRemove,
        modalClose
    },
    created(){        
        // console.log('this', this, this.shortapplic)
        this.form = this.shortapplic
    },
    methods: {
        updateCard() {
            apiTriger.getTriggerCall(this.form.requestId).then(result => {
                if(result.status == 'done') {
                    this.componentKey++
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка отримання заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'getTriggerCall';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = this.form.requestId
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addInJob() {
            apiTriger.addTriggerInJob(this.form.requestId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("TakentoWork"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка взяття в роботу заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'addTriggerInJob';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = this.form.requestId
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addCancelReport(id, obj) {
            apiTriger.addReportForCancel(id, obj).then(result => {
                if (result.status == 'done') {
                    this.$toast.success(this.$t("addReportOnCancelRequest"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка додавання репорту на відміну тріггера'; 
                    var statusRequest = result.status;
                    var api = 'addReportForCancel';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj,
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        callNow() {
            apiTriger.callNowByRequest(this.form.requestId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("done"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка зміна статусу на "Зателефонувати зараз" на заявку тріггера'; 
                    var statusRequest = result.status;
                    var api = 'callNowByRequest';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = this.form.requestId
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addInCancel(id, obj) {
            apiTriger.cancelRequest(obj, id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("canceled"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка відміни заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'cancelRequest';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj,
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addInClose(id, obj){
            apiTriger.closeRequest(obj, id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("closed"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка закриття заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'closeRequest';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj,
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addInDelay(id, obj){
            apiTriger.delayRequest(obj, id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("Delayed") + " " + this.$t("to") + " " + obj.delayedDate + " " + obj.delayedTime)
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка відкладання заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'addInDelay';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj,
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addInDelete(id, obj) {
            apiTriger.deleteRequest(obj, id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("Removed"))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка видалення заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'deleteRequest';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj,
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        addInEdit(obj, id) {
            apiTriger.editTriggerRequest(obj, id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("alertChanged"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка редагування заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'editTriggerRequest';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj,
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        getClient(e) {
            this.eventBus.emit('getClient', {status: true, number: e.userPhone, requestId: {id: e.requestId, trigger: true}})
        },
        addInTransfer(id, workerId, obj){
            apiTriger.addTransferRequest(obj, workerId, id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t("threw"))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error') + " " + (result.error[0] ? result.error[0] : ''));

                    var errorText = 'Помилка додавання запиту на передачу заявки тріггера'; 
                    var statusRequest = result.status;
                    var api = 'addTransferRequest';
                    var fileRequest = 'src/views/calls/trigger/view/index.vue';
                    var params = {
                        "obj": obj, 
                        "workerId": workerId, 
                        "id": id
                    }
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        prevOpen(){
            //пред заявка
            this.$emit('close');
            this.$emit('prevOpen', this.form.requestId)
        },
        nextOpen(){
            //след заявка
            this.$emit('close');
            this.$emit('nextOpen', this.form.requestId)
        },
        mutateStatus(e, obj){
            return mutateStatus(e, obj);
        },
    },
    mounted() {
        this.form.triggers = Object.keys(this.form.triggers)[0] // для функції мутейта так як в іншому місці скрипт не встигає провести операцію передачі аргумента в такому вигляді
    },
    computed:{
        perms() {
            return storeS.perms
        },
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers //база работников
        },
        scale(){
            return window.devicePixelRatio
        }
    }
})
</script>


<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>
