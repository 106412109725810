<template>
    <globalTable 
        :columns="columns" 
        :rows="filteredItems" 
        @search="searchB" 
        @open="open"
    />

    <!-- открыть карточку репорта -->
    <viewreport 
        v-if="showModalReport == true" 
        @close="showModalReport = false" 
        :obj="this.objCard" 
        @removeReport="removeReport" />
    <!-- открыть карточку репорта -->


</template>

<script>
import globalTable from '@/components/globaltable/index';
import viewreport from '../../../formright/reports/view'
import { ReportsTickets } from '@/API'
import { storeS } from '@/store';
import { nameWorker } from '@/usabilityScripts/getNameWorker'

let apiServe = new ReportsTickets();

export default{
    props: ['obj'],
    components: {
        globalTable,
        viewreport
    },
    data(){
        return{
            objCard: "",
            showModalReport: false,
            search: '',
            form: {},
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "reportId",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t('added'),
                    text: "createWorkerId",
                    align: "left",
                    status: true,
                    mutate: (item) => this.nameWorker(item)
                },
                {
                    name: this.$t("Point_name"),
                    text: "reportName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('reportDesc'),
                    text: "description",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t("actualResult"),
                    text: "actualResult",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('expectedResult'),
                    text: "expectedResult",
                    align: "left",
                    status: true
                },
                
            ]
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.rows = this.obj.reports
        },
        getReports() {
            apiServe.getReportsTickets(this.obj.ticketId).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data
                }
            })
        },
        open(e){
            apiServe.getReport(e.reportId).then(result => {
                if(result.status == 'done'){
                    this.objCard = result.data;
                    this.showModalReport = true
                }
            })
        },
        searchB(e){
            this.search = e
        },
        nameWorker(item){
            return nameWorker(item)
        }
    },
    mounted(){
        this.eventBus.on('getReports', (status) => {
            if(status == true){
                this.getReports()
            }
        })
    },
    computed: {
        filteredItems: function() {
            return Object.values(this.rows)
                // По полю поиска
                .filter(item => {
                    return this.search == '' || item.reportId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 ;
                })
        },
        workers(){
            return storeS.workers
        }
    }
}
</script>