<template>
    <modal :title="$t('client') + ' ' + Number(this.objcard.personalData?.phone)" @close="close" :stylebody="null" >
        <template v-slot:lefthead>
            <b-button v-if="this.objcomp != ''" variant="outline-dark" class="waves-effect waves-light fs-12" @click="this.objcomp = ''; this.visibleAlert = false;this.checkPrroWork = false"><i class="ri-arrow-left-line align-bottom"></i> </b-button>
        </template> 
        <template v-slot:status >
            <br v-if="this.objcard.monitor === true"/><span v-if="this.objcard.monitor === true" class="badge bg-warning">{{ $t("onMonitoring") }}</span>
            <br/><span v-if="checkPrroWork" class="badge bg-danger">{{ $t('WorksWithPRRO') }}</span>
            <!-- <span :class="`badge ${mutateStatus(form.status).cl} align-middle fs-12`">{{ mutateStatus(form.status).r }}</span> -->
            <!-- <span v-if="this.formRequest" :class="`badge ${ this.$t(mutatePriority(formRequest.priority).cl)}  align-middle fs-12`" style="margin-left: 5px;">{{ this.$t(mutatePriority(formRequest.priority).r) }}</span> -->
        </template>
        <template v-slot:head-bottom>
            <!-- шапка модального вікна -->
            <headermodal 
                :obj="this.form" 
                :objComp="this.objcomp"
                :key="componentKey"
                @close="close" 
                @updatedata="updatedata"
                @opencalculator="opencalculator"
                @openstatistics="openstatistics" 
                @openshop="openshop"
                @openknowledge="openknowledge" 
                @openfaq="openfaq" 
                @opensmsbox="opensmsbox" 
                @openapplicbox="openapplicbox" 
                @opentaskBox="opentaskBox" 
                @openticketBox="openticketBox" 
                @openservicebox="openservicebox" 
                @openinvoicesbox="openinvoicesbox" 
                @openblockComment="openblockComment" 
                @createdconnectbox="createdconnectbox"
                @openmailbox="openmailbox" 
                @openScriptBox="openScriptBox"
                @extendPayment="showExtendPayment = true"
                @replenBalance="changeBalance"
                @changeSubsfee="showChangeSub = true"
                @addToMonitoring="openMonitoringCreate"
            />
        </template>
        <template v-slot:body>
            <b-row>
                <b-col lg="2" class="border-end sticky-block">
                    <!-- <requestcol 
                        v-if="this.formRequest"
                        :obj="this.formRequest"
                    /> -->
                    
                    <!-- Лівий блок з даними по акаунту (основні дані) -->
                    <maincol  
                        @call="call"
                        @openDashboard="showDashboardBox = true"
                        :obj="this.form"
                        :owner="this.objcomp ? this.objcomp : ''"
                        @opensmsbox="opensmsbox" 
                        @openmailbox="openmailbox"
                    />

                    <!-- Лівий блок зі списком компаній -->
                    <company 
                        v-if="this.objcomp != '' || this.objcomp != 'noEnterComp'"
                        :obj="this.form"
                        :company="this.objcomp"
                        :companyId="objcomp.companyId"
                        :companyName="objcomp.companyName"
                        :key="componentKey"
                        @opencompany="opencompany"
                    />
                </b-col>
                
                
                <!-- Блок праворуч, коли немає компаній -->
                <b-col lg="10" v-if="this.objcomp == '' && (this.objcard.сompanies?.length == 0 && this.objcard.deleted_companies.length == 0)">
                    <div class="">
                        <b-tabs 
                            pills 
                            :nav-wrapper-class="`nav-custom nav-custom-${colorTabs}`"
                        >
                            <b-tab :title="$t('Comments')" active>
                                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                                    <comments :specialId="this.requestId" :client="this.form" :number="this.form.userPhone"/>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>

                <!-- 
                    Якщо компанія є, але клієнт в неї ще не увійшов, та відбувається прозвон
                    - викликаємо таб з коментарями як не для зареєстрованих noEnterComp
                -->
                <b-col lg="10" v-if="this.objcomp == 'noEnterComp'">
                    <div class="">
                        <b-tabs 
                            pills 
                            :nav-wrapper-class="`nav-custom nav-custom-${colorTabs}`"
                        >
                            <b-tab :title="$t('Comments')" active>
                                <div style="padding: 20px 0px;margin: 10px auto;border-radius: 7px;" >
                                    <comments :specialId="this.requestId" :client="this.form" :number="this.form.userPhone"/>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </b-col>
                

                <!-- список компаній праворуч -->
                <b-col lg="10" v-if="this.objcomp == '' && (this.objcard.сompanies?.length != 0 || this.objcard.deleted_companies.length != 0)">
                    <companies 
                        :companyFromSearch="this.companyId"
                        :companies="this.form.сompanies"
                        :deletedcompanies="this.form.deleted_companies"
                        :accountId="this.form.accountId"
                        @opencompany="opencompany"
                        @updateCard="updateCardClient"
                    />
                </b-col>
                
                <!-- таби з інформацією по компанії по центру-->
                <b-col v-if="this.objcomp != ''" lg="8" style="background: var(--vz-body-bg);">
                    <activitycol  
                        v-if="this.objcomp.companyId != null"
                        :obj="this.objcomp"
                        :key="componentKey"
                        :colorTabs="colorTabs"
                        :client="this.form"
                        :referallsData="this.form.referallsData"
                        :specialId="this.requestId"
                        :tab="this.tabIndex"
                        @openservicebox="openservicebox" 
                        @openinvoicesbox="openinvoicesbox" 
                        @createdconnectbox="createdconnectbox"
                        @closeOne="closeOne"
                        @close = "this.$emit('close')"
                    />
                </b-col>

                <!-- оплата по акаунту праворуч (стара версія) -->
                <!-- this.objcomp.more.companyServer == 's-1' &&  -->
                <b-col v-if="(this.objcomp?.more?.companyServer == 's-1' || this.objcomp?.more?.companyServer == 'online-2') && this.objcomp.companyDataObj && (this.objcomp.companyDataObj.companyId != null || this.objcomp.companyId != null)" lg="2" class="sticky-block">
                    <othercol  
                        :obj="this.objcomp.companyDataObj"
                        :data="this.form"
                        :client="this.form"
                        :key="componentKey"
                        :formRequest="this.formRequest"
                    />
                </b-col>

                <!-- оплата по акаунту праворуч (нова версія) -->
                <b-col v-if="(this.objcomp?.more?.companyServer != 's-1' && this.objcomp?.more?.companyServer != 'online-2') && this.objcomp.companyDataObj && (this.objcomp.companyDataObj.companyId != null || this.objcomp.companyId != null)" lg="2" class="sticky-block">
                    <tariffNewPlan  
                        :obj="this.objcomp.companyDataObj"
                        :data="this.form"
                        :client="this.form"
                        :key="componentKey"
                        :formRequest="this.formRequest"
                    />
                </b-col>

            </b-row>
        </template>
    </modal>

    <!-- статистика -->
    <template v-if="statisticsShow">
        <modallg @close="statisticsShow = false" :title="$t('statistics')">
            <template v-slot:head-bottom>
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="statisticsShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <statistics 
                    :json="this.form" 
                    :jsonComp="this.objcomp"
                />
            </template>
        </modallg>
    </template>
    <!-- статистика -->

    <!-- база знань -->
    <template v-if="knowledgeboxShow">
        <modallg @close="knowledgeboxShow = false">
            <template v-slot:head-bottom>
              <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="knowledgeboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <knowledgeBox :phone="form.user_login"></knowledgeBox>
            </template>
        </modallg>
    </template>
    <!-- база знань -->

    <!-- faq -->
    <template v-if="faqboxShow">
        <modallg @close="faqboxShow = false">
            <template v-slot:head-bottom>
              <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="faqboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
            </template>
            <template v-slot:body>
                <faqBox></faqBox>
            </template>
        </modallg>
    </template>
    <!-- faq -->

    <!-- script -->
    <template v-if="scriptboxShow == true">
        <modallg @close="this.scriptboxShow = false">
            <template v-slot:head-bottom>
              <!-- <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="this.scriptboxShow = false"><i class="ri-close-fill align-bottom"></i></button> -->
            </template>
            <template v-slot:title>
              <h5>Scripts</h5>
            </template>
            <template v-slot:body>
                <scriptbox @close="this.scriptboxShow = false"></scriptbox>
            </template>
        </modallg>
    </template>
    <!-- script -->

    <!-- смс бокс -->
    <createsmsBox v-if="modalSmsbox" @close="modalSmsbox = false" @sendSMS="sendSMS" :phone="Number(this.objcard.personalData?.phone)" @updatedata="updatedata" :obj="this.objcomp"></createsmsBox>
    <!-- смс бокс -->

    <!-- mail бокс -->
    <createmailBox v-if="modalMailbox" :users="this.form" @close="modalMailbox = false"></createmailBox>
    <!-- mail бокс -->

    <!-- створення заявки -->
    <createapplicBox v-if="modalShow" @close="modalShow = false" :phone="Number(this.objcard.personalData?.phone)"></createapplicBox>
    <!-- створення заявки -->

    <!-- створення завдання -->
    <createTaskBox v-if="modalTaskBox" @close="modalTaskBox = false" :phone="Number(this.objcard.personalData?.phone)"></createTaskBox>
    <!-- створення завдання -->

    <!-- створення тікета -->
    <createTicketBox v-if="modalTicketBox" @close="modalTicketBox = false" :phone="Number(this.objcard.personalData?.phone)"></createTicketBox>
    <!-- створення тікета -->

    <!-- створення рахунку -->
    <invoices v-if="modalInvoicesBox" @close="modalInvoicesBox = false" :phone="Number(this.objcard.personalData?.phone)" :license="form.license"></invoices>
    <!-- створення рахунку -->

    <!-- калькулятор -->
    <calculator v-if="modalcalculator" @close="modalcalculator = false" :country="form.user_country" :demo_license="demo_license" ></calculator>
    <!-- калькулятор -->

    <!-- інтернет-магазин -->
    <template v-if="modalshop">
      <modallg @close="modalshop = false">
        <template v-slot:head-bottom>
          <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="knowledgeboxShow = false"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <shop></shop>
        </template>
      </modallg>
    </template>
    <!-- інтернет-магазин -->

    <!-- створення послуги -->
    <service v-if="serviceShow" @close="serviceShow = false" :number="Number(this.objcard.personalData?.phone)"></service>
    <!-- створення послуги -->

    <!-- створення налаштувань підключення -->
    <settins_box v-if="connectsShow" @close="connectsShow = false" :userPhone="Number(this.objcard.personalData?.phone)" :sklad="objcomp.companyDataObj.tradepoints"></settins_box>
    <!-- створення налаштувань підключення -->

    <acceptSMSVue v-if="showAcceptSMS" @close = "showAcceptSMS = false" :obj="this.obj"></acceptSMSVue>

    <!-- імітація адмінки скаю -->
    <dashboardSky 
        v-if="showDashboardBox" 
        @close="showDashboardBox = false" 
        :obj="this.objcomp" 
    />

    <!-- Модальне вікно для продовження оплати компанії/змінити день наступної оплати -->
    <extendPayment 
        v-if="showExtendPayment"
        @close="showExtendPayment = false;opencompany(objcomp.companyId)"
        :obj="this.objcomp"
        :companyId="objcomp.companyId"
    />

    <!-- Модальне вікно для поповнення/списання коштів балансу в ручному режимі -->
    <replenBalance 
        v-if="showReplenBalance"
        @close="showReplenBalance = false"
        :obj="this.objcomp"
        :type="typePay"
        :companyId="objcomp.companyId"
    />

    <!-- Модальне вікно для змінити плати компанії за підписку/змінити коефіцієнт -->
    <changeSubsfee 
        v-if="showChangeSub"
        @close="showChangeSub = false;opencompany(objcomp.companyId)"
        :obj="this.objcomp"
        :companyId="objcomp.companyId"
    />

</template>

<script>
import comments from './blocks/activity/comments/index.vue'
import scriptbox from '@/views/scriptsForManagers/index.vue'
import modal from '@/components/modal'
import modallg from '@/components/modal/modal-lg'
import headermodal from './header'
import maincol from './blocks/main/index'
import activitycol from './blocks/activity/index'
import othercol from './blocks/other/index'
import tariffNewPlan from './blocks/other/tariffNewPlan'
import requestcol from './blocks/request/index'
import company from './blocks/other/company'
import companies from './blocks/companies'
import knowledgeBox from '@/components/knowledge-base/index'
import faqBox from '@/components/faq/index'
import statistics from "./client_stat/index"
import createapplicBox from '@/views/application/createapplic.vue'
import createsmsBox from '@/views/application/createsms.vue'
import createmailBox from '@/views/application/createmail.vue'
import createTaskBox from '@/views/tasks/createtask.vue'
import createTicketBox from '@/views/newtickets/createticket.vue'
import service from "@/views/services/createservice.vue"
import settins_box from "./blocks/activity/connects/create"
import invoices from "@/views/invoices/createinvoices.vue"
import calculator from "@/components/calc_tariff.vue"
import shop from "@/components/shop/index.vue"
import { SuperAdmin, Clients } from '@/API.js';
import acceptSMSVue from '@/views/application/acceptSMS.vue';
import dashboardSky from './dashboardSky/index'
import extendPayment from './dangerActions/extendPayment'
import replenBalance from './dangerActions/replenBalance'
import changeSubsfee from './dangerActions/changeSubsfee'
// import { storeS } from '@/store'
import { mutateStatusAccounts,
        mutatePriorityAccounts } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new SuperAdmin();
let apiClients = new Clients()

export default({
    props:['objcard', 'parrent', 'companyId', 'requestId'],
    components: {
        scriptbox,
        acceptSMSVue,
        modal,
        headermodal,
        maincol,
        activitycol,
        othercol,
        requestcol,
        company,
        companies,
        knowledgeBox,
        faqBox,
        createsmsBox,
        createmailBox,
        createapplicBox,
        createTaskBox,
        createTicketBox,
        service,
        calculator,
        invoices,
        shop,
        settins_box,
        modallg,
        statistics,
        comments,
        dashboardSky,
        extendPayment,
        replenBalance,
        changeSubsfee,
        tariffNewPlan
    },
    data(){
        return{
            visibleAlert: false,
            scriptboxCreateShow: false,
            scriptboxShow: false,
            showAcceptSMS: false,
            obj: '',
            form:'',
            objcomp: '',
            componentKey:'',
            colorTabs: 'success',
            knowledgeboxShow: false,
            faqboxShow: false,
            modalSmsbox: false,
            modalMailbox: false,
            modalShow: false,
            modalTaskBox: false,
            modalTicketBox: false,
            modalInvoicesBox: false,
            modalcalculator: false,
            modalshop: false,
            serviceShow: false,
            connectsShow: false,
            statisticsShow: false,
            demo_license: {
                demo_count_tt: "0",
                demo_count_terminal: "0",
                demo_terminal_count_active: "0",
                demo_store_count_active: "0",
                demo_products_count: "0",
                demo_cookcard_count: "0"
            },
            showDashboardBox: false,
            showExtendPayment: false,
            showReplenBalance: false,
            showChangeSub: false,
            typePay: 'replen',
            checkPrroWork: false,
            tabindex: '',
        }
    },
    created(){
        this.form = this.objcard;
    },
    beforeUnmount() {
        if(this.parrent != undefined ||  this.parrent != null) {
            this.$emit('openParrent', this.parrent)
        }
    },
    methods: {
        openMonitoringCreate(num, compId) {
            let obj = {
                "number": num,
                "compId": compId ? compId : ""
            }

            this.eventBus.emit('createNewMonitoring', {
                data: obj
            })
        },
        openScriptBox() {
            this.scriptboxShow = true
        },
        sendSMS(e){
            this.obj = e 
            this.showAcceptSMS = true
        },
        close(){
            this.$emit('close')
        },
        call(e) {
            
            this.$emit('call', e)
        },
        opencompany(accID, tab){
            apiServe.getCompClient(accID).then(result => {
                if(result.status == "done"){
                    if(result.data.hasOwnProperty('companyName')) {
                        
                        this.objcomp = result.data;
                        this.colorTabs = result.data.delete.is_delete == true ? 'danger' : 'success';
                        this.componentKey += 1
                        
                        if(this.objcomp.more.companyServer != 's-1'){
                            let devices = this.objcomp.companyDataObj.devices
                            devices.forEach(element => {
                                if(element.protocol == "uaprro"){
                                    this.checkPrroWork = true
                                }
                            });
                        }

                        this.tabIndex = tab
                    
                        // this.$toast.success(`Update`);
                    } else {
                        this.objcomp = 'noEnterComp'
                        this.$toast.warning('Клієнт не входив в компанію!')
                    }
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка відкриття компанії'; 
                    var statusRequest = result.status;
                    var api = 'getCompClient';
                    var fileRequest = 'src/components/calendar/addEvent.vue';
                    var params = accID;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        closeOne() {
            this.$emit("closeOne", this.form)
        },
        openstatistics: function(){
            this.statisticsShow = true
        },
        openshop: function(){
            console.log('openshop')
            this.modalshop = true
        },
        openknowledge: function(){
            this.knowledgeboxShow = true
        },
        openfaq: function(){
            this.faqboxShow = true
        },
        opensmsbox: function(){
            this.modalSmsbox = true
        },
        openapplicbox: function(){
            this.modalShow = true
        },
        opentaskBox: function(){
            this.modalTaskBox = true
        },
        openticketBox: function(){
            this.modalTicketBox = true
        },
        openservicebox: function(){
            this.serviceShow = true
        },
        createdconnectbox: function(){
            this.connectsShow = true
        },
        openmailbox: function() {
           this.modalMailbox = true
        },
        opencalculator: function(){
            // Налаштування параметрів ліцензії для калькулятора

            console.log("this.obj", this.objcomp);

            if(this.objcomp) {
                // Присвоєння кількості активних торгових точок
                this.demo_license.demo_store_count_active = this.objcomp.companyDataObj.license.countActiveTradepoints;

                // Присвоєння загальної кількості торгових точок
                this.demo_license.demo_count_tt = this.objcomp.companyDataObj.license.countTradepoints;
                
                // Присвоєння загальної кількості терміналів
                this.demo_license.demo_count_terminal = this.objcomp.companyDataObj.terminals.length;

                // Присвоєння кількості активних терміналів
                this.demo_license.demo_terminal_count_active = this.objcomp.companyDataObj.license.countActiveTerminals;

                // Присвоєння кількості товарів
                this.demo_license.demo_products_count = this.objcomp.companyDataObj.tradepoints[0].productsCount;

                // Присвоєння кількості тех. карт
                this.demo_license.demo_cookcard_count = this.objcomp.companyDataObj.tradepoints[0].countTechcards;

                // Проходження по всіх торгових точках для знаходження максимальних значень товарів та технічних карт
                for(var i=0; i < this.objcomp.companyDataObj.tradepoints.length; i++){

                    // Перевірка та оновлення максимальної кількості товарів
                    if(Number(this.demo_license.demo_products_count) < Number(this.objcomp.companyDataObj.tradepoints[i].productsCount)){
                        this.demo_license.demo_products_count = this.objcomp.companyDataObj.tradepoints[i].productsCount
                    }
                    
                    // Перевірка та оновлення максимальної кількості технічних карт
                    if(Number(this.demo_license.demo_cookcard_count) < Number(this.objcomp.companyDataObj.tradepoints[i].countTechcards)){
                        this.demo_license.demo_cookcard_count = this.objcomp.companyDataObj.tradepoints[i].countTechcards
                    }
                }
            }

            // Активація модального вікна калькулятора після завершення обчислень
            this.modalcalculator = true
        },
        openblockComment: function(){
            this.blockComment = !this.blockComment;
        },
        openinvoicesbox: function(){
            this.modalInvoicesBox = true;
        },
        changeBalance(i){
            this.typePay = i;
            this.showReplenBalance = true;
        },
        mutateStatus(e){
            return mutateStatusAccounts(e)
        },
        mutatePriority(e){
            return mutatePriorityAccounts(e)
        },
        updateCardClient(){
            // Оновлюємо картку клієнта
            apiServe.getCard(this.form.userPhone).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        }
    },
})
</script>