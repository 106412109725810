<template>
    <b-card>
        <div class="form-check form-check-success mb-3" v-if="this.specialId">
            <input class="form-check-input" type="checkbox" id="formCheck8" v-model="checked">
            <label class="form-check-label" for="formCheck8">{{ $t('LeaveCall') }}/{{ this.$t('postpone') }}</label>
            <p class="text-danger fw-semibold" style="font-size: 16px;"><b>{{ this.$t('comHereWillPostpone') }}</b></p>
        </div>
        <div v-if="checked == true && this.specialId">
            <div  class="mb-3">
                <label class="form-label mb-2">{{ $t('specify_date_timeNeedCall') }}</label>
                <b-row>
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('date') }}</h4>
                            <flatpickr v-model="date" :config="configDate" class="form-control border-0 dash-filter-picker shadow" @onChange="change"></flatpickr>
                        </div>
                    </b-col>
                    <b-col lg="6">
                        <div class="mb-3">
                            <h4 class="fs-15">{{ $t('time') }}</h4>
                            <setTime :timeProps="this.time" @setHour="setHour" @setMinute="setMinute"></setTime>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div  class="mb-3">
                <label class="form-label mb-2">{{ $t('infoFutureCall') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('NoAnswer')" style="margin-right: 5px;">{{ $t('NoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('AskedToDialLater')" style="margin-right: 5px;">{{ $t('AskedToDialLater') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('Busy')" style="margin-right: 5px;">{{ $t('Busy') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('autoAnswer')" style="margin-right: 5px;">{{ $t('autoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('notInterest')" style="margin-right: 5px;">{{ $t('notInterest') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('notActual')" style="margin-right: 5px;">{{ $t('notActual') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="comment += $t('spectateForPayment')" style="margin-right: 5px;">{{ $t('spectateForPayment') }}</span>
                </div>
                <textarea class="form-control bg-light border-light" v-model="comment" :placeholder="$t('infoFutureCall')"></textarea>
            </div>
            <div class="form-check" v-if="this.obj.monitor === true">
                <input class="form-check-input" type="checkbox" v-model="this.commentInMonitoring" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    {{ this.$t("copyInMonitoring") }}
                </label>
            </div>
            <div class="grid-buttons">
                <button class="btn btn-success" @click="addCall()">{{ $t('Add') }}</button>
                <button class="btn btn-info" @click="copy(this.comment)">{{ $t('Copy') }}</button>
            </div>
        </div>
        <div class="row g-3">
            <!-- v-if="this.specialId" -->
            <div class="col-lg-12">
                <label for="exampleFormControlTextarea1" class="form-label fw-semibold">{{ $t('Comment') }}</label>
                <div class="mb-2">
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('NoAnswer')" style="margin-right: 5px;">{{ $t('NoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('AskedToDialLater')" style="margin-right: 5px;">{{ $t('AskedToDialLater') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('Busy')" style="margin-right: 5px;">{{ $t('Busy') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('autoAnswer')" style="margin-right: 5px;">{{ $t('autoAnswer') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('notInterest')" style="margin-right: 5px;">{{ $t('notInterest') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('notActual')" style="margin-right: 5px;">{{ $t('notActual') }}</span>
                    <span class="fs-14 badge bg-info copy mb-2" @click="this.form.comment += $t('spectateForPayment')" style="margin-right: 5px;">{{ $t('spectateForPayment') }}</span>
                </div>
                <p class="text-success fw-semibold">{{ $t('commentWillbeAdded') }}</p>
                <p class="text-danger fw-semibold" style="font-size: 16px;" v-if="this.specialId"><b>{{ this.$t('comHereWillClose') }}</b></p>
                <textarea class="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="3" v-model="form.comment" :placeholder="$t('enterComment')"></textarea>
            </div>
            <div class="alert alert-success" role="alert" v-if="this.specialId" style="display: grid; grid-template-columns: 2fr 2fr 1fr;">
                <span style="align-self: center;">{{ this.$t('openTheApplicHere')}}</span>
                <button type="button" class="btn btn-warning" style="grid-column-start: 3;" @click="openCall(this.specialId)">{{ this.$t('open') }}</button>
            </div>
            <div class="form-check" v-if="this.obj.monitor === true">
                <input class="form-check-input" type="checkbox" v-model="this.commentInMonitoring" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                    {{ this.$t("copyInMonitoring") }}
                </label>
            </div>
            <div class="grid-buttons">
                <a class="btn btn-success" @click="addComment()">{{ $t('save') }}</a>
                <a class="btn btn-info" @click="copy(this.form.comment)">{{ $t('Copy') }}</a>
            </div>
        </div>
    </b-card>
</template>

<script>
import setTime from "../../../../../../../components/setTime.vue";
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import Vueflatpickr from "vue-flatpickr-component";
import { storeS } from '@/store';
import { mutateTariffingOutlets } from '@/usabilityScripts/globalMutate'
import { Clients, Applications, ColdCalls, TriggerCalls } from '@/API'
import { storeSip } from '@/telephony/telephony.js'
import {copy} from '@/usabilityScripts/copy.js'

let apiServe = new Clients();
let apiApplic = new Applications();
let apiCold = new ColdCalls()
let apiTriger = new TriggerCalls()

export default {
    props: ['obj', 'number', 'specialId'],
    components: {
        flatpickr: Vueflatpickr, setTime
    },
    data(){
        return{
            commentInMonitoring: false,
            closeRequest: false, //для закриття заявки
            reqId: '', //для закриття заявки
            date: '',
            time: '',
            form: {
                userPhone: '',
                comment: ""
            },
            checked: true,
            comment: "",
            configDate: {
                mode: "single",
                minDate: "today",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: "",
                defaultDate: []
            },
            configTime: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true,
                minTime: "06:00",
                maxTime: "23:00"
            },
            store_count_active: '',
            count_tt: '',
            products_counts_count: '',
            cookcard_count: '',
            objcard: '',
        }
    },
    created(){
        // час для переносу
        let actualDate = new Date()

        if(actualDate.getHours() >= 9 && actualDate.getHours() < 14) {
            this.date = "2023-" + this.mounthMutate(String(actualDate).split(' ')[1]) + '-' + String(actualDate).split(' ')[2] 
            this.time = '14:00'
        } else if(actualDate.getHours() >= 14 && actualDate.getHours() < 16) {
            this.date = "2023-" + this.mounthMutate(String(actualDate).split(' ')[1]) + '-' + String(actualDate).split(' ')[2]
            this.time = '16:00'
        } else if(actualDate.getHours() >= 16) {
            let  today = new Date(); 
            var tomorrow = new Date(today.getTime() + (24 * 60 * 60 * 1000));
            this.date = "2023-" + this.mounthMutate(String(tomorrow).split(' ')[1]) + '-' + (String(tomorrow).split(' ')[2])
            this.time = '10:00'
        }

        this.form.userPhone = this.number
        if(storeS.lang == 'uk'){
            this.configDate.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.configDate.locale = Russian
        }

        this.eventB.off('closeReq')
        this.eventB.on('closeReq', (status) => {
            if(status.status == true) {
                this.closeRequest = true
            }
        })

        this.eventB.off('getCom')
        this.eventB.on('getCom', (status) => {
            if(status.status == true) {
                this.copy(this.form.comment)
            }
        })
    },
    methods: {
        openCall(id){
            console.log(id)
            if(id.isCold === true) {
                apiCold.getCall(id.id).then(result => {
                    this.objcard = result.data;
                    this.eventBus.emit('modalInModal', {
                        status: true,
                        data: this.objcard,
                        type: 'coldRequest',
                        closeOne: true,
                        formForReturnInClientCard: this.client
                    })
                })
            } else if(id.trigger == true){
                apiTriger.getTriggerCall(id.id).then(result => {
                    if(result.status == 'done') {
                        this.objCard = result.data
                        this.eventBus.emit('modalInModal', {
                            status: true,
                            data: this.objcard,
                            type: 'trigger',
                            closeOne: true,
                            formForReturnInClientCard: this.client
                        })
                    } else {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка отримання заявки тріггера'; 
                        var statusRequest = result.status;
                        var api = 'getTriggerCall';
                        var fileRequest = 'src/views/calls/trigger/all.vue';
                        var params = e.requestId
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            } else {
                apiApplic.getRequest(id).then(result => {
                    if(result.status == 'done'){
                        this.objcard = result.data;
                        this.eventBus.emit('modalInModal', {
                            status: true,
                            data: this.objcard,
                            type: 'request',
                            closeOne: true,
                            formForReturnInClientCard: this.client
                        })
                    } else {
                        this.$toast.error(this.$t('err'));
                    }
                })
            }
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        setHour(e){
            this.time = String(new Date(new Date().setHours(e)).getHours()) + ':' + this.time.split(':')[1]
        },
        setMinute(e) {
            this.time = this.time.split(':')[0] + ':' + String(new Date(new Date().setMinutes(e)).getMinutes())
        },
        mounthMutate(e) {
            var c = ''
            switch (e) {
                case 'Feb':
                c = '2'
                break;
                case 'Mar':
                c = '3'
                break;
                case 'Apr':
                c = '4'
                break;
                case 'May':
                c = '5'
                break;
                case 'Jun':
                c = '6'
                break;
                case 'Jul':
                c = '7'
                break;
                case 'Aug':
                c = '8'
                break;
                case 'Sep':
                c = '9'
                break;
                case 'Oct':
                c = '10'
                break;
                case 'Nov':
                c = '11'
                break;
                case 'Dec':
                c = '12'
                break;
                case 'Jan':
                c = '1'
                break;
            }
            return c
        },
        addComment(){
            this.form["commentInMonitoring"] = this.commentInMonitoring
            this.form.userPhone = this.number
            if (!this.form.userPhone) {
                //перевіряємо чи вказана номер
                this.$toast.error(this.$t('EnterNumberC'));
                return
            }
            if (!this.form.comment) {
                //перевіряємо чи вказаний комент
                this.$toast.error(this.$t('EnterComment'));
                return
            }

            if(this.specialId) {
                if(this.specialId.isCold === true) {
                    apiCold.closeCall(Number(this.specialId.id), this.form).then(result=>{
                        if(result.status == 'done') {
                            this.$toast.success(this.$t('success'))
                            this.$emit('getdata')
                            this.form.comment = ''
                        } else {
                            this.$toast.error(this.$t('error'));

                            var errorText = 'Помилка закриття дзвінка'; 
                            var statusRequest = result.status;
                            var api = 'closeCall';
                            var fileRequest = 'src/views/accounts/unregistered/view/blocks/activity/comments/comment.vue';
                            var params = JSON.stringify({id: Number(this.specialId.id), form: this.form})
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }
                    }) 
                } else {
                    apiApplic.closeRequest(Number(this.specialId), this.form).then(result=>{
                        if(result.status == 'done') {
                            this.$toast.success(this.$t('success'))
                            this.$emit('getdata')
                            this.form.comment = ''
                        } else {
                            this.$toast.error(this.$t('error'));

                            var errorText = 'Помилка закриття дзвінка'; 
                            var statusRequest = result.status;
                            var api = 'closeRequest';
                            var fileRequest = 'src/views/accounts/unregistered/view/blocks/activity/comments/comment.vue';
                            var params = JSON.stringify({id: Number(this.specialId), form: this.form})
                            var response = result

                            // Викликаємо функцію для відправки повідомлення в телеграм
                            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                            .then(response => console.log('Telegram API response:', response.data))
                            .catch(error => console.error('Telegram API error:', error));
                        }
                    })
                }
            } 
            else {
                apiServe.fixationData(this.form).then(result => {
                    if(result.status == 'done'){
                        this.$emit('getdata')
                        this.form.comment = ''
                        this.$toast.success(this.$t('success'))
                    } else {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка отримання заявки тріггера'; 
                        var statusRequest = result.status;
                        var api = 'fixationData';
                        var fileRequest = 'src/views/accounts/unregistered/view/blocks/activity/comments/comment.vue';
                        var params = JSON.stringify({form: this.form})
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
            this.form.comment = ''
        },
        addCall() {
            if(this.specialId) {
                var form = {
                    delayedTime: this.time,
                    delayedDate: this.date,
                    comment: this.comment,
                    commentInMonitoring: this.commentInMonitoring 
                }
                if(this.specialId.isCold === true) {
                    apiCold.delayCall(Number(this.specialId), form).then(result=>{
                        if(result.status == 'done') {
                            this.$toast.success(this.$t('success'))
                            this.$emit('getdata')
                            this.form.comment = ''
                        } else {
                            if(result.error[0] == "Request already exists") {
                                this.$toast.error(this.$t('aplic_already_yet'))
                            } else {
                                this.$toast.error(this.$t('error'));

                                var errorText = 'Помилка відкладання заявки'; 
                                var statusRequest = result.status;
                                var api = 'delayCall';
                                var fileRequest = 'src/views/accounts/unregistered/view/blocks/activity/comments/comment.vue';
                                var params = JSON.stringify({id: Number(this.specialId), form: form})
                                var response = result

                                // Викликаємо функцію для відправки повідомлення в телеграм
                                sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                                .then(response => console.log('Telegram API response:', response.data))
                                .catch(error => console.error('Telegram API error:', error));
                            }
                        }
                        this.closeRequest = false
                    })
                } else {
                    apiApplic.delayRequest(Number(this.specialId), form).then(result=>{
                        if(result.status == 'done') {
                            this.$toast.success(this.$t('success'))
                            this.$emit('getdata')
                            this.form.comment = ''
                        } else {
                            if(result.error[0] == "Request already exists") {
                                this.$toast.error(this.$t('aplic_already_yet'))
                            } else {
                                this.$toast.error(this.$t('error'));

                                var errorText = 'Помилка відкладання заявки'; 
                                var statusRequest = result.status;
                                var api = 'delayRequest';
                                var fileRequest = 'src/views/calls/trigger/all.vue';
                                var params = JSON.stringify({id: Number(this.specialId), form: form})
                                var response = result

                                // Викликаємо функцію для відправки повідомлення в телеграм
                                sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                                .then(response => console.log('Telegram API response:', response.data))
                                .catch(error => console.error('Telegram API error:', error));
                            }
                        }
                        this.closeRequest = false
                    })
                }
            }
        },
        setTime(e){
            var today = new Date()
            var finalDate = new Date(today)
            var currentHours = today.getHours()
            // finalDate.setHours(currentHours + e)lunch
            finalDate.setHours(e == 14 ? e : e == 16 ? e : currentHours + e)
            var dateStr = finalDate.getFullYear() + "-" +
            ("00" + (finalDate.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (finalDate.getDate())).slice(-2) + " " +
            ("00" + finalDate.getHours()).slice(-2) + ":" +
            ("00" + finalDate.getMinutes()).slice(-2) + ":" +
            ("00" + finalDate.getSeconds()).slice(-2);
            // this.date = dateStr
        },
        mutateTariffing(e){
          return mutateTariffingOutlets(e)
        },
    },
    computed: {
        settingSip(){
            return storeSip.info
        },
        numberSip() {
            return storeSip.info.incomingNumber
        }
    },
}
</script>

<style scoped>
.grid-buttons{
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}
@media (max-width: 576px) {
    .grid-buttons{ 
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
    }
}
</style>