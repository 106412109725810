<template>

     <!-- 
        Компонент модального вікна створення баг-репорту
    -->

    <modal >

        <!-- Заголовок -->
        <template v-slot:title>{{ $t('createReport') }}</template>  

        <!-- Опис під заголовок -->
        <template v-slot:status><span class="text-muted mb-0 fs-13">{{ $t('Checkpoint') }}: {{ stepTitle }}</span></template>

        <!-- Кнопки навбару вікна -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email"  @click="showInfo = true"><i class="ri-information-line align-bottom"></i></button>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <template v-slot:body>

            <!-- Проект (адмінка, пос-термінал, та інше) -->

            <!-- Основне -->
            <div class="preconditions mb-3">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('main') }}</h6>
                    <hr>
                </div>
            </div>

            <b-col lg="6">
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('project') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" v-model="form.project" disabled>
                </div>
            </b-col>

            <!-- Назва репорту -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('nameReport') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.name" placeholder="Назва має відповідати на питання Що? Де? Коли?">
            </div>

            <!-- Версія фікс -->
            <!-- <div class="mb-3">
                <h4 class="fs-15">{{ $t('Версія фікс') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.version" placeholder="Наприклад, 1.22">
            </div> -->

            <b-row>

                <b-col lg="6">
                    <!-- Серйозність дефекту -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('SeverityofTheDefect') }}</h4>
                        <b-form-select v-model="form.severityDefect" :options="listSeriousness_defect"></b-form-select>
                    </div>
                </b-col>

                <b-col lg="6">
                    <!-- Пріоритет -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('priority') }}</h4>
                        <b-form-select v-model="form.priority" :options="listPriority"></b-form-select>
                    </div>
                </b-col>

            </b-row>

            <!-- Опис -->
            <div class="preconditions mt-4 mb-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('desc') }}</h6>
                    <hr>
                </div>
            </div>

            <!-- Короткий опис -->
            <div class="mb-3">
                <label class="form-label">{{ $t('ShortDescription') }}</label>
                <QuillEditor v-model:content="form.description" theme="snow" contentType="html" toolbar="full"/>
            </div>

            <!-- Кроки відтворення -->
            <div class="mb-3">
                <label class="form-label">{{ $t('howtoProduce') }}</label>
                <!-- <QuillEditor v-model:content="form.howProduce" theme="snow" contentType="html" toolbar="full"/> -->
                <b-row>
                    <b-col lg="6">
                        <div class="d-flex mb-3">
                            <span class="text-muted mb-0 flex-grow-1">{{ $t('indicateAllStepbyStep') }}</span>
                        </div>
                        <div class="flex-grow-1 ms-2 mb-2">
                            <div class="input-group">
                                <input type="text" v-model="step.taskName" @keydown.enter="addStep(step)" class="form-control"  >
                                <button @click="addStep(step)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.form.actions" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-light text-black">#{{index+1}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.actionName" @keydown.enter="add(input,index)">
                                            <button @click="addStep(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="removeItem(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </b-col>
                </b-row>
            </div>

            <!-- Актуальний результат -->
            <div class="mb-3">
                <label class="form-label">{{ $t('actualResult') }}</label>
                <input type="text" class="form-control" v-model="form.factualResult">
            </div>

            <!-- Очікуваний результат -->
            <div class="mb-3">
                <label class="form-label">{{ $t('expectedResult') }}</label>
                <input type="text" class="form-control" v-model="form.expectedResult">
            </div>

            <!-- Основне -->
            <div class="preconditions mt-4 mb-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('TestingEnvironment') }}</h6>
                    <hr>
                </div>
            </div>

            <!-- Рядок з вибором опцій -->
            <b-row>     

                <!-- Блок для вибору операційної системи -->
                <b-col lg="3">
                    <h4 class="fs-15">{{ $t('SelectOS') }}</h4>
                    <b-form-select v-model="selectedOS" :options="listOS" ></b-form-select>
                </b-col>

                <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
                <b-col lg="3" v-if="selectedOS == 'windows'">
                    <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                    <b-form-select v-model="selectedVerOS" :options="listVerionsWindows" ></b-form-select>
                </b-col>

                <b-col lg="3" v-if="selectedOS == 'android'">
                    <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                    <b-form-select v-model="selectedVerOS" :options="listVerionsAndroid" ></b-form-select>
                </b-col>
                <b-col lg="3" v-if="selectedOS == 'macos'">
                    <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                    <b-form-select v-model="selectedVerOS" :options="listVerionsMacOS" ></b-form-select>
                </b-col>
                <b-col lg="3" v-if="selectedOS == 'ios'">
                    <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                    <b-form-select v-model="selectedVerOS" :options="listVerionsIOS" ></b-form-select>
                </b-col>

                <!-- Блок для вибору застосунку для тестування -->
                <b-col lg="3">
                    <h4 class="fs-15">{{ $t('TestingApplication') }}</h4>
                    <b-form-select v-model="selectedApp" :options="listApp" ></b-form-select>
                </b-col>

                <!-- Блок для вибору браузера, якщо обрано застосунок "Браузер" -->
                <b-col lg="3" v-if="selectedApp == 'browser'">
                    <h4 class="fs-15">{{ $t('SelectBrowser') }}</h4>
                    <b-form-select v-model="selectedBrowser" :options="listBrowser" ></b-form-select>
                </b-col>

                <!-- Блок для вибору версії застосунку або браузера, залежно від умов -->
                <b-col :lg="`3 ${selectedApp == 'browser' ? 'mt-2' : ''}`" v-if="selectedApp == 'appskyservice' || (selectedApp == 'browser' && selectedBrowser == 'chrome')">
                    <h4 class="fs-15">{{ selectedApp == 'appskyservice' ? $t('ApplicationVersion') : $t('BrowserVersion') }}</h4>

                    <!-- Вибір версії застосунку для Windows -->
                    <b-form-select 
                        v-model="selectedVesAppBrow" 
                        v-if="selectedApp == 'appskyservice' && selectedOS == 'windows'" 
                        :options="listVerAppSkyWindows" 
                    ></b-form-select>
                    
                    <!-- Вибір версії застосунку для Android -->
                    <b-form-select 
                        v-model="selectedVesAppBrow" 
                        v-if="selectedApp == 'appskyservice' && selectedOS == 'android'" 
                        :options="listVerAppSkyAndroid" 
                    ></b-form-select>

                    <!-- Вибір версії застосунку для IOS -->
                    <b-form-select 
                        v-model="selectedVesAppBrow" 
                        v-if="selectedApp == 'appskyservice' && (selectedOS == 'ios' || selectedOS == 'macos')" 
                        :options="listVerAppSkyIOS" 
                    ></b-form-select>

                    <!-- Вибір версії застосунку для Браузера -->
                    <b-form-select 
                        class="mt-2"
                        v-model="selectedVesAppBrow" 
                        v-if="selectedApp == 'browser' && selectedBrowser == 'chrome'" 
                        :options="listVerChrome" 
                    ></b-form-select>
                </b-col>

            </b-row>

            <button 
                @click="addPlatform()"
                type="button"
                class="btn btn-outline-info btn-icon waves-effect waves-light mt-2"
            >
                <i class="ri-add-line"></i>
            </button>

            <template v-for="(item, index) in form.environments" :key="index">
                <b-row class="mt-3"> 

                    <b-col lg="11">
                        <b-row>
                            <!-- {{ item }} -->
                            <!-- Блок для вибору операційної системи -->
                            <b-col lg="3">
                                <h4 class="fs-15">{{ $t('SelectOS') }}</h4>
                                <b-form-select v-model="item.osName" :options="listOS" ></b-form-select>
                            </b-col>

                            <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
                            <b-col lg="3" v-if="item.osName == 'windows'">
                                <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                <b-form-select v-model="item.osVersion" :options="listVerionsWindows" ></b-form-select>
                            </b-col>

                            <b-col lg="3" v-if="item.osName == 'android'">
                                <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                <b-form-select v-model="item.osVersion" :options="listVerionsAndroid" ></b-form-select>
                            </b-col>
                            <b-col lg="3" v-if="item.osName == 'macos'">
                                <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                <b-form-select v-model="item.osVersion" :options="listVerionsMacOS" ></b-form-select>
                            </b-col>
                            <b-col lg="3" v-if="item.osName == 'ios'">
                                <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                <b-form-select v-model="item.osVersion" :options="listVerionsIOS" ></b-form-select>
                            </b-col>

                            <!-- Блок для вибору застосунку для тестування -->
                            <b-col lg="3">
                                <h4 class="fs-15">{{ $t('TestingApplication') }}</h4>
                                <b-form-select v-model="item.testingApplication" :options="listApp" ></b-form-select>
                            </b-col>

                            <!-- Блок для вибору браузера, якщо обрано застосунок "Браузер" -->
                            <b-col lg="3" v-if="item.testingApplication == 'browser'">
                                <h4 class="fs-15">{{ $t('SelectBrowser') }}</h4>
                                <b-form-select v-model="item.browser" :options="listBrowser" ></b-form-select>
                            </b-col>

                            <!-- Блок для вибору версії застосунку або браузера, залежно від умов -->
                            <b-col :lg="`3 ${item.testingApplication == 'browser' ? 'mt-2' : ''}`" v-if="item.testingApplication == 'appskyservice' || (item.testingApplication == 'browser' && item.browser == 'chrome')">
                                <h4 class="fs-15">{{ selectedApp == 'appskyservice' ? $t('ApplicationVersion') : $t('BrowserVersion') }}</h4>

                                <!-- Вибір версії застосунку для Windows -->
                                <b-form-select 
                                    v-model="item.applicationVersion" 
                                    v-if="item.testingApplication == 'appskyservice' && item.osName == 'windows'" 
                                    :options="listVerAppSkyWindows" 
                                ></b-form-select>
                                
                                <!-- Вибір версії застосунку для Android -->
                                <b-form-select 
                                    v-model="item.applicationVersion" 
                                    v-if="item.testingApplication == 'appskyservice' && item.osName == 'android'" 
                                    :options="listVerAppSkyAndroid" 
                                ></b-form-select>

                                <!-- Вибір версії застосунку для IOS -->
                                <b-form-select 
                                    v-model="item.applicationVersion" 
                                    v-if="item.testingApplication == 'appskyservice' && (item.osName == 'ios' || item.osName == 'macos')" 
                                    :options="listVerAppSkyIOS" 
                                ></b-form-select>

                                <!-- Вибір версії застосунку для Браузера -->
                                <b-form-select 
                                    v-model="form.selectedVesAppBrow" 
                                    v-if="item.testingApplication == 'browser' && item.browser == 'chrome'" 
                                    :options="listVerChrome" 
                                ></b-form-select>
                                <!-- <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select>
                                <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select> -->
                            </b-col>
                        </b-row>
                    </b-col>
                    
                    <div class="col-lg-1">
                        <h4 class="fs-15" >{{ $t('action') }}</h4>
                        <button @click="removePlatform(index)" type="button" class="btn btn-soft-danger btn-icon close-btn-email" title="Видалити середовище" >
                            <i class="ri-delete-bin-2-line align-bottom" ></i>
                        </button>
                    </div>

                </b-row>
            </template>
            
            <files 
                :obj="files" 
                :objFiles="files" 
                @uploadFiles="uploadFiles"
                @downloadFile="downloadFile"
                @deleteFile="deleteFile"
                @getFile="getFile"
            />
                
        </template>

        <!-- Кнопки футера вікна -->
        <template v-slot:footer-bottom>

            <!-- Кнопка закрити -->
            <button 
                class="btn link-success fw-medium" 
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i>
                {{ $t('close') }} 
            </button>

            <!-- Кнопка додати -->
            <button 
                type="button" 
                class="btn btn-primary" 
                v-on:click="add"
            >
                {{ $t('Add') }}
            </button>

        </template>

    </modal>

    <!-- Компонент з гайдом на створення репорту -->
    <infoBox
        v-if="showInfo"
        @close="showInfo = false"
    />

</template>

<script>
// import modal from '@/components/modal/modal-lg' // компонент модального вікна
import modal from '@/components/modal-small'
import files from '@/components/files/index'
import infoBox from './infoCreatedReport' // вікно гайд створення
import { testingReports } from '@/API.js';

let apiServe = new testingReports();

export default {
    props: [
        // Пункт до якого додається репорт
        'objItem',
        // Об'єкт завдання тестування
        'objCard'
    ],
    components: {
        modal,
        files,
        infoBox
    },
    data() {
        return {
            showInfo: false,
            form: {
                name: '', // назва репорту
                reportUuid: '', // для файлу
                // version: '', // версія (поки не актуально)
                severityDefect: '', // серйозність дефекту
                priority: '', // пріоритет
                description: '', // опис
                factualResult: '', // фактичний результат
                expectedResult: '', // очікуваний результат
                clauseId: '', // id пункту чек-листа або тест-кейсу (якщо є)
                testingId: '', // id картки завдання
                actions: [], // покрокові дії
                environments: [], // середовище тестування
            },
            stepTitle: '',
            selectedOS: 'windows',
            selectedVerOS: '',
            selectedApp: '',
            selectedBrowser: '',
            selectedVesAppBrow: '',
            step: {
                taskName: '',
                checked: 0
            },
            listSeriousness_defect: [
                { value: null, text: 'Оберіть серйозність дефекту', disabled: true },
                { value: "blocker", text: this.$t('Блокуючий') },
                { value: "critical", text: this.$t('Критична помилка') },
                { value: "major", text: this.$t('Значна') },
                { value: "minor", text: this.$t('Незначна') },
                { value: "trivial", text: this.$t('Тривіальний') },
                { value: "tweak", text: this.$t('Незручність') },
                { value: "text", text: this.$t('Текстова помилка') },
            ],
            listPriority: [
                {
                    text: this.$t('Critical'),
                    value: "3"
                },
                {
                    text: this.$t('priorityHigh'),
                    value: "2"
                },
                {
                    text: this.$t('priorityMedium'),
                    value: "1"
                },
                {
                    text: this.$t('priorityLow'),
                    value: "0"
                }
            ],
            selectedOS: 'windows',
            listOS: [
                { "value": null, "text": 'Оберіть ОС', disabled: true },
                { "value": "windows", "text": "Windows"},
                { "value": "android", "text": "Android"},
                { "value": "ios", "text": "IOS"},
                { "value": "macos", "text": "MacOS"},
            ],
            selectedVerOS: '',
            listVerionsWindows: [
                { "value": null, "text": 'SelectOSVersion', disabled: true },
                { "value": "windows11", "text": "Windows 11"},
                { "value": "windows10", "text": "Windows 10"},
                { "value": "windows81", "text": "Windows 8.1"},
                { "value": "windows8", "text": "Windows 8"},
            ],
            listVerionsAndroid: [
                { "value": null, "text": 'SelectOSVersion', disabled: true },
                { "value": "14.0", "text": "Android 14.0"},
                { "value": "13.0", "text": "Android 13.0"},
                { "value": "12.1", "text": "Android 12.1"},
                { "value": "12.0", "text": "Android 12.0"},
                { "value": "11.0", "text": "Android 11.0"},
                { "value": "10.0", "text": "Android 10.0"},
                { "value": "9.0", "text": "Android 9.0"},
                { "value": "8.1", "text": "Android 8.1"},
                { "value": "8.0", "text": "Android 8.0"},
                { "value": "7.1", "text": "Android 7.1"},
                { "value": "7.0", "text": "Android 7.0"},
                { "value": "6.0", "text": "Android 6.0"},
                { "value": "5.1", "text": "Android 5.1"},
                { "value": "5.0", "text": "Android 5.0"},
                { "value": "4.4", "text": "Android 4.4"},
            ],
            listVerionsIOS: [
                { "value": null, "text": 'SelectOSVersion', disabled: true },
                { "value": "17.2", "text": "IOS 17.2"},
                { "value": "17.1", "text": "IOS 17.1"},
                { "value": "17", "text": "IOS 17"},
                { "value": "16", "text": "IOS 16"},
                { "value": "15", "text": "IOS 15"},
                { "value": "14", "text": "IOS 14"},
                { "value": "13", "text": "IOS 13"},
                { "value": "12", "text": "IOS 12"},
                { "value": "11", "text": "IOS 11"},
                { "value": "10.3.4", "text": "IOS 10.3.4"},
                { "value": "10.3.3", "text": "IOS 10.3.3"},
            ],
            listVerionsMacOS: [
                { "value": null, "text": 'SelectOSVersion', disabled: true },
                { "value": "sonoma", "text": "macOS Sonoma"}, 
                { "value": "ventura", "text": "macOS Ventura"}, 
                { "value": "monterey", "text": "macOS Monterey"}, 
                { "value": "bigsur", "text": "macOS Big Sur"}, 
                { "value": "catalina", "text": "macOS Catalina"}, 
                { "value": "mojave", "text": "macOS Mojave"}, 
                { "value": "highsierra", "text": "macOS High Sierra"}, 
            ],
            selectedApp: '',
            listApp: [
                { "value": null, "text": 'Застосунок для тестування', disabled: true },
                { "value": "appskyservice", "text": "Застосунок SkyService"}, 
                { "value": "browser", "text": "Браузер"}, 
            ],
            selectedBrowser: '',
            listBrowser: [
                { "value": null, "text": 'Виберіть браузер', disabled: true },
                { "value": "chrome", "text": "Google Chrome"}, 
                { "value": "safari", "text": "Safari"}, 
                { "value": "edge", "text": "Edge"}, 
                { "value": "firefox", "text": "Mozilla Firefox"}
            ],
            selectedVesAppBrow: '',
            listVerAppSkyWindows: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice21", "text": "SkyService 2.1"}, 
                { "value": "skyservice20", "text": "SkyService 2.0"}, 
                { "value": "skyservice19", "text": "SkyService 1.9"}, 
                { "value": "skyservice18", "text": "SkyService 1.8"}, 
                { "value": "skyservice17", "text": "SkyService 1.7"}, 
                { "value": "skyservice16", "text": "SkyService 1.6"}, 
            ],
            listVerAppSkyAndroid: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice13.8", "text": "SkyService 13.8"}, 
                { "value": "skyservice13.7", "text": "SkyService 13.7"}, 
                { "value": "skyservice13.6", "text": "SkyService 13.6"}, 
                { "value": "skyservice13.5", "text": "SkyService 13.5"}, 
                { "value": "skyservice13.4", "text": "SkyService 13.4"}, 
                { "value": "skyservice13.3", "text": "SkyService 13.3"}, 
                { "value": "skyservice13.2", "text": "SkyService 13.2"}, 
                { "value": "skyservice13.1", "text": "SkyService 13.1"}, 
                { "value": "skyservice13.0", "text": "SkyService 13.0"}, 
                { "value": "skyservice12.9", "text": "SkyService 12.9"}, 
                { "value": "skyservice12.8", "text": "SkyService 12.8"}, 
                { "value": "skyservice12.7", "text": "SkyService 12.7"}, 
                { "value": "skyservice12.6", "text": "SkyService 12.6"}, 
                { "value": "skyservice12.5", "text": "SkyService 12.5"}, 
                { "value": "skyservice12.4", "text": "SkyService 12.4"}, 
                { "value": "skyservice12.3", "text": "SkyService 12.3"}, 
                { "value": "skyservice12.2", "text": "SkyService 12.2"}, 
                { "value": "skyservice12.1", "text": "SkyService 12.1"}, 
                { "value": "skyservice12.0", "text": "SkyService 12.0"}, 
                { "value": "skyservice11.9", "text": "SkyService 11.9"}, 
                { "value": "skyservice11.8", "text": "SkyService 11.8"}, 
                { "value": "skyservice11.7", "text": "SkyService 11.7"}, 
                { "value": "skyservice11.6", "text": "SkyService 11.6"}, 
                { "value": "skyservice11.5", "text": "SkyService 11.5"}, 
                { "value": "skyservice11.4", "text": "SkyService 11.4"}, 
                { "value": "skyservice11.3", "text": "SkyService 11.3"}, 
                { "value": "skyservice11.2", "text": "SkyService 11.2"}, 
                { "value": "skyservice11.1", "text": "SkyService 11.1"}, 
                { "value": "skyservice11.0", "text": "SkyService 11.0"}, 
            ],
            listVerAppSkyIOS: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice5.2", "text": "SkyService 5.2"}, 
                { "value": "skyservice5.1", "text": "SkyService 5.1"}, 
                { "value": "skyservice5.0", "text": "SkyService 5.0"}, 
                { "value": "skyservice4.3", "text": "SkyService 4.3"}, 
                { "value": "skyservice4.2", "text": "SkyService 4.2"}, 
                { "value": "skyservice4.1", "text": "SkyService 4.1"}, 
                { "value": "skyservice4.0", "text": "SkyService 4.0"}, 
                { "value": "skyservice3.2", "text": "SkyService 3.2"}, 
                { "value": "skyservice3.1", "text": "SkyService 3.1"}, 
                { "value": "skyservice3.0", "text": "SkyService 3.0"}, 
            ],
            listVerChrome: [
                { "value": null, "text": 'Виберіть версію браузера', disabled: true },
                { "value": "chrome120", "text": "Google Chrome 120"}, 
                { "value": "chrome119", "text": "Google Chrome 119"}, 
                { "value": "chrome118", "text": "Google Chrome 118"}, 
                { "value": "chrome117", "text": "Google Chrome 117"}, 
                { "value": "chrome116", "text": "Google Chrome 116"}, 
                { "value": "chrome115", "text": "Google Chrome 115"}, 
                { "value": "chrome114", "text": "Google Chrome 114"}, 
                { "value": "chrome113", "text": "Google Chrome 113"}, 
                { "value": "chrome112", "text": "Google Chrome 112"}, 
                { "value": "chrome111", "text": "Google Chrome 111"}, 
                { "value": "chrome110", "text": "Google Chrome 110"}, 
                { "value": "chrome109", "text": "Google Chrome 109"}, 
                { "value": "chrome108", "text": "Google Chrome 108"}, 
                { "value": "chrome107", "text": "Google Chrome 107"}, 
                { "value": "chrome106", "text": "Google Chrome 106"}, 
                { "value": "chrome105", "text": "Google Chrome 105"}, 
                { "value": "chrome104", "text": "Google Chrome 104"}, 
                { "value": "chrome103", "text": "Google Chrome 103"}, 
                { "value": "chrome102", "text": "Google Chrome 102"}, 
                { "value": "chrome101", "text": "Google Chrome 101"}, 
                { "value": "chrome100", "text": "Google Chrome 100"}, 
                { "value": "chrome99", "text": "Google Chrome 99"}, 
                { "value": "chrome98", "text": "Google Chrome 98"}, 
                { "value": "chrome97", "text": "Google Chrome 97"}, 
                { "value": "chrome96", "text": "Google Chrome 96"}, 
                { "value": "chrome95", "text": "Google Chrome 95"}, 
                { "value": "chrome94", "text": "Google Chrome 94"}, 
                { "value": "chrome92", "text": "Google Chrome 92"}, 
                { "value": "chrome85", "text": "Google Chrome 85"}, 
                { "value": "chrome81", "text": "Google Chrome 81"}, 
                { "value": "chrome80", "text": "Google Chrome 80"}, 
            ],
            problemEnvironment: [
                {
                    "label": this.$t('App'),
                    "value": "App"
                },
                {
                    "label": 'Inside',
                    "value": "inside"
                },
                {
                    "label": this.$t('NewAdmin_panel'),
                    "value": "newDashboard"
                },
                {
                    "label": this.$t('admin_allappsSkymarket'),
                    "value": "admin_allappsSkymarket"
                },
                {
                    "label": this.$t('admin_allappsSellup'),
                    "value": "admin_allappsSellup"
                },
                {
                    "label": this.$t('admin_allappsHscreen'),
                    "value": "admin_allappsHscreen"
                },
                {
                    "label": this.$t('admin_allappsMono'),
                    "value": "admin_allappsMono"
                },
                {
                    "label": this.$t('admin_allappsPrivat'),
                    "value": "admin_allappsPrivat"
                },
                {
                    "label": this.$t('admin_shake_to_pay'),
                    "value": "admin_shake_to_pay"
                },
                {
                    "label": this.$t('admin_allappsBookBeauty'),
                    "value": "admin_allappsBookBeauty"
                },
                {
                    "label": this.$t('admin_sendcheck'),
                    "value": "admin_sendcheck"
                },
                {
                    "label": this.$t('admin_panel'),
                    "value": "admin_panel"
                },
                {
                    "label": this.$t('dir_pos'),
                    "value": "pos_terminal"
                },
                {
                    "label": this.$t('dir_posnew'),
                    "value": "posnew"
                },
                {
                    "label": this.$t('admin_allProducts'),
                    "value": "admin_allProducts"
                },
                {
                    "label": this.$t('admin_warehouseProducts'),
                    "value": "admin_warehouseProducts"
                },
                {
                    "label": this.$t('admin_services'),
                    "value": "admin_services"
                },
                {
                    "label": this.$t('admin_Onsale'),
                    "value": "admin_Onsale"
                },
                {
                    "label": this.$t('admin_remains'),
                    "value": "admin_remains"
                },
                {
                    "label": this.$t('admin_reportsMain'),
                    "value": "admin_reportsMain"
                },
                {
                    "label": this.$t('admin_reportsSale'),
                    "value": "admin_reportsSale"
                },
                {
                    "label": this.$t('admin_reportsComings'),
                    "value": "admin_reportsComings"
                },
                {
                    "label": this.$t('admin_reportsReturns'),
                    "value": "admin_reportsReturns"
                },
                {
                    "label": this.$t('admin_reportsMove'),
                    "value": "admin_reportsMove"
                },
                {
                    "label": this.$t('admin_reportsManufacture'),
                    "value": "admin_reportsManufacture"
                },
                {
                    "label": this.$t('admin_reportsOpenshifts'),
                    "value": "admin_reportsOpenshifts"
                },
                {
                    "label": this.$t('admin_reportsBonusreports'),
                    "value": "admin_reportsBonusreports"
                },
                {
                    "label": this.$t('admin_reportsGoodsconsumption'),
                    "value": "admin_reportsGoodsconsumption"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsGoodscategory'),
                    "value": "admin_reportsGoodscategory"
                },
                {
                    "label": this.$t('admin_reportsSalesdynamic'),
                    "value": "admin_reportsSalesdynamic"
                },
                {
                    "label": this.$t('admin_reportsRevenuereport'),
                    "value": "admin_reportsRevenuereport"
                },
                {
                    "label": this.$t('admin_reportsWorkshop'),
                    "value": "admin_reportsWorkshop"
                },
                {
                    "label": this.$t('admin_reportsEmployee'),
                    "value": "admin_reportsEmployee"
                },
                {
                    "label": this.$t('admin_reportsInventory'),
                    "value": "admin_reportsInventory"
                },
                {
                    "label": this.$t('admin_reportsDanger'),
                    "value": "admin_reportsDanger"
                },
                {
                    "label": this.$t('admin_reportsRecommendpurchase'),
                    "value": "admin_reportsRecommendpurchase"
                },
                {
                    "label": this.$t('admin_reportsTaxes'),
                    "value": "admin_reportsTaxes"
                },
                {
                    "label": this.$t('admin_reportsShifts'),
                    "value": "admin_reportsShifts"
                },
                {
                    "label": this.$t('admin_reportsWalletsstatus'),
                    "value": "admin_reportsWalletsstatus"
                },
                {
                    "label": this.$t('admin_reportsFin'),
                    "value": "admin_reportsFin"
                },
                {
                    "label": this.$t('admin_reportsCashboxallmotions'),
                    "value": "admin_reportsCashboxallmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinkmotions'),
                    "value": "admin_reportsCashboxinkmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxoutmotions'),
                    "value": "admin_reportsCashboxoutmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxsalemotions'),
                    "value": "admin_reportsCashboxsalemotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinmotions'),
                    "value": "admin_reportsCashboxinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletallmotions'),
                    "value": "admin_reportsWalletallmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletinmotions'),
                    "value": "admin_reportsWalletinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletoutmotions'),
                    "value": "admin_reportsWalletoutmotions"
                },
                {
                    "label": this.$t('admin_marketingClients'),
                    "value": "admin_marketingClients"
                },
                {
                    "label": this.$t('admin_marketingOffers'),
                    "value": "admin_marketingOffers"
                },
                {
                    "label": this.$t('admin_marketingDiscount'),
                    "value": "admin_marketingDiscount"
                },
                {
                    "label": this.$t('admin_marketingStock'),
                    "value": "admin_marketingStock"
                },
                {
                    "label": this.$t('admin_marketingBonus'),
                    "value": "admin_marketingBonus"
                },
                {
                    "label": this.$t('admin_settingsglobal'),
                    "value": "admin_settingsglobal"
                },
                {
                    "label": this.$t('admin_settingstradepoints'),
                    "value": "admin_settingstradepoints"
                },
                {
                    "label": this.$t('admin_settingscategory'),
                    "value": "admin_settingscategory"
                },
                {
                    "label": this.$t('admin_settingsexpenses'),
                    "value": "admin_settingsexpenses"
                },
                {
                    "label": this.$t('admin_settingstax'),
                    "value": "admin_settingstax"
                },
                {
                    "label": this.$t('admin_settingsworkshop'),
                    "value": "admin_settingsworkshop"
                },
                {
                    "label": this.$t('admin_settingsnotices'),
                    "value": "admin_settingsnotices"
                },
                {
                    "label": this.$t('admin_settingshalls'),
                    "value": "admin_settingshalls"
                },
                {
                    "label": this.$t('admin_settingstables'),
                    "value": "admin_settingstables"
                },
                {
                    "label": this.$t('admin_settingsdevices'),
                    "value": "admin_settingsdevices"
                },
                {
                    "label": this.$t('admin_settingsusers'),
                    "value": "admin_settingsusers"
                },
                {
                    "label": this.$t('admin_settingsprovider'),
                    "value": "admin_settingsprovider"
                },
                {
                    "label": this.$t('admin_settingswallets'),
                    "value": "admin_settingswallets"
                },
                {
                    "label": this.$t('admin_settingsmybalance'),
                    "value": "admin_settingsmybalance"
                },
                {
                    "label": this.$t('admin_settingspartnersprogram'),
                    "value": "admin_settingspartnersprogram"
                },
                {
                    "label": this.$t('admin_help'),
                    "value": "admin_help"
                }
            ],
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        add(){
            // створення репорту

            console.log("form > ", this.form)

            apiServe.createReport(this.form).then(result => {
                if(result.status == 'done') {
                    console.log('result', result);
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                    this.eventBus.emit('UpdateCardTesting', true)
                } else {
                    console.log(result);
                    this.switchError(result.error[0])
                }
            })

        },
        switchError(error){
            // відображення помилки

            switch (error) {

                case "priority should be numeric":
                    this.$toast.error(this.$t('priorityShouldBeNumeric'))
                    break;
                case "testingPlan not found":
                    this.$toast.error(this.$t('TestingPlannotFound'))
                    break;
                case "data not found":
                    this.$toast.error(this.$t('dataNotFound'))
                    break;
            
                default:
                    this.$toast.error(error);
                    break;
            }
        },
        getdata(){
            if(this.objItem) {
                this.stepTitle = this.objItem.clauseName;
                this.form.clauseId = this.objItem.clauseId;
            }

            if(this.objCard.testingId != "") {
                this.form.testingId = this.objCard.testingId;
            }

            this.form.project = this.objCard.template;
        },
        addStep(e, index) {

            // Якщо індекс переданий (натиснуто "+", в конкретному пункті списку)
            if (index !== undefined) {
                // Додаємо порожній елемент після поточного індексу
                this.form.actions.splice(index + 1, 0, {
                    actionName: '', // Порожній пункт
                });
            } else {
                // Якщо індекс не переданий (натиснуто "+", в полі вводу нового пункту)
                this.form.actions.push({
                    actionName: e.taskName, // Додаємо в кінець зі значенням
                });
                this.step.taskName = ''; // Очищаємо поле вводу
            }
        },
        removeItem(index){
            this.form.actions.splice(index, 1);
        },
        addPlatform(){

            var tempForm = {
                osName: this.selectedOS,
                osVersion: this.selectedVerOS,
                testingApplication: this.selectedApp
            }

            if (this.selectedBrowser) {
                tempForm.browser = this.selectedBrowser;
            }
            if (this.selectedVesAppBrow && this.testingApplication == 'browser') {
                tempForm.browserVersion = this.selectedVesAppBrow;
            }
            if (this.selectedVesAppBrow && this.testingApplication != 'browser') {
                tempForm.applicationVersion = this.selectedVesAppBrow;
            }
            
            this.form.environments.push(tempForm)
            console.log("environment", this.form.environments);
        },
        removePlatform(index){
            this.form.environments.splice(index, 1)
        }
    }
}
</script>

<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
}

.header-line-container h6 {
    margin: 0;
    padding-right: 10px; /* Відступ між заголовком і лінією */
}

.header-line-container hr {
    flex-grow: 1;
    border: none;
    /* border-top: 2px solid #000; /* Можна змінити товщину та колір лінії */
    margin: 0;
}
</style>