<template>
    <!-- Компонент для вибору пріоритету -->

    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
        >
            {{ $t('priority') }}
        </label>

        <br />
        <div class="row g-2 list_filtr" >
            <!-- Вибір "Усі" -->
            <div class="col-lg-6">
                <div class="form-check mb-2" >
                    <input class="form-check-input" type="radio" @change="change()" name="radioPriority" id="all_priority" v-model="priority" :value="''">
                    <label class="form-check-label" for="all_priority">{{ $t('all') }}</label>
                </div>
            </div>
            
            <!-- Цикл для виведення радіобатонів для кожного пріоритету -->
            <template v-for="(value, index) in opt" :key="index" >
                <div 
                    class="col-lg-6" 
                    v-if="
                        (this.page === 'tickets') ||
                        (value.value !== '3' && this.page !== 'tickets')
                    "
                >
                    <div class="form-check mb-2" >
                        <input class="form-check-input" type="radio" @change="change()" name="radioPriority" :id="'priority'+index" v-model="priority" :value="value.value">
                        <label class="form-check-label" :for="'priority'+index">{{ value.name }}</label>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    props: ['page'],
    data(){
        return {
            priority: "", // Обраний пріоритет
            opt: [ // Масив зі списком пріоритетів
                {
                    // Низький
                    name: this.$t('priorityLow'),
                    value: "0",
                },
                {
                    // Середній
                    name: this.$t('priorityMedium'),
                    value: "1",
                },
                {
                    // Високий
                    name: this.$t('priorityHigh'),
                    value: "2",
                },
                {
                    // Критичний
                    name: this.$t('Critical'),
                    value: "3",
                }
            ]
        }
    },
    created() {
        this.eventBus.off('calls filters')
        this.eventBus.on('calls filters', data => {
            if(data.nameFilter == "priority") {
                this.priority = data.form.value
            }
        })
    },
    methods: {
        change(){
            // Метод, що викликається при зміні фільтру
            this.$emit('change', 'priority', this.priority)
        },
    },
    computed: {
    }
}
</script>