<template>

    <!-- 
        Компонент перегляду картки тестування
    -->
    
    <modal
        :title="form.name" 
        :stylebody="'var(--vz-body-bg)'" 
        :key="componentKey"
    >
    
        <!-- Заголовок -->
        <!-- <template v-slot:title>{{ $t('Створення завдання на тестування') }}</template> -->


        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button class="btn btn-ghost-warning btn-icon btn-sm fs-16" title="Додати в обране"><i class="bx bx-star align-bottom"></i></button>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <topBlock 
                :objCard="this.form"
            />

            <div class="row" >
                <div class="col-lg-9" >
                    <div class="tab-content text-muted" >

                        <!-- Таб Основний -->
                        <div class="tab-pane fade active show" id="testing-main" role="tabpanel" >
                            <tabMain 
                                :objCard="this.form"
                            />
                        </div>
                        
                        <!-- Таб Чату -->
                        <!-- <div class="tab-pane fade" id="testing-chat" role="tabpanel" >
                            <tabChat 
                                :objCard="this.form"
                            />
                        </div> -->

                        <!-- Таб Чек-листа -->
                        <div v-if="form.testingPlan == 'checklist'" class="tab-pane fade" id="testing-checklist" role="tabpanel" >
                            <tabCheckList 
                                :objCard="this.form"
                            />
                        </div>
                        
                        <!-- Таб Тест кейсів -->
                        <div v-if="form.testingPlan == 'testcases'" class="tab-pane fade" id="testing-testcases" role="tabpanel" >
                            <tabTestCases 
                                :objCard="this.form"
                            />
                        </div>

                        <!-- Таб Баг-репортів -->
                        <!-- <div class="tab-pane fade" id="testing-reports" role="tabpanel" >
                            <tabReports 
                                :objCard="this.form"
                            />
                        </div> -->

                        <!-- Таб Файлів -->
                        <div class="tab-pane fade" id="testing-files" role="tabpanel" >
                            <tabFiles 
                                :objCard="this.form"
                            />
                        </div>

                    </div>
                </div>
               
                <!-- 
                    Правий блок модального вікна
                    - Деталі по картці
                    - Блок тікету (якщо є)
                    - Учасники
                    - Файли
                -->
                <formRight 
                    :objCard="this.form"
                />
                
            </div>

        </template>

        <template v-slot:footer-bottom>

            <div class="mt-3">
            
                <!-- Кнопка "Видалити" -->
                <button 
                    type="button" 
                    class="btn btn-danger me-2" 
                    @click="dialogShow = true" 
                >
                    {{ $t('Delete') }}
                </button>

                <!-- 
                    Кнопка "Взяти в роботу", тестувальник бере завдання в роботу 
                    - надає змогу взяти завдання в роботу
                    - умову на права доступу todo perms
                    - йде перевірка на статус, щоб статус завдання був "новий"
                -->
                <button 
                    v-if="form.status == 'new'"
                    type="button" 
                    class="btn btn-success me-2" 
                    @click="toWork(form)" 
                >
                    {{ $t('takeToWork') }}
                </button>

                <!-- Кнопка "Закрити", закриття завдання -->
                <!-- <button 
                    type="button" 
                    class="btn btn-warning" 
                    @click="add()"
                >
                    {{ $t('close') }}
                </button> -->

            </div>

        </template>

    </modal>

    <!-- Підтвердження видалення картки -->
    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="toDelete()" 
    />

</template>

<script>
import modal from '@/components/modal' // компонент модального вікна
import topBlock from './topBlock.vue'; // шапка модального вікна
import formRight from './formRight/index'; // правий блок вікна
import tabFiles from './formLeft/files/index' // Таб файлів
import tabMain from './formLeft/main/index' // Таб основний
import tabDevices from './formLeft/devices/index' // Таб пристроїв
import tabChat from './formLeft/chat/index' // Таб чату
import tabCheckList from './formLeft/checkList/index'; // Таб чек-листа
import tabTestCases from './formLeft/testCases/index'; // Таб тест-кесів
import tabReports from './formLeft/reports/index' // Таб баг-репортів
import modalremove from '@/components/modal/modalremove'
import { Testing } from '@/API.js';

let apiServe = new Testing();

export default {
    props: ['objCard'],
    components: {
        modal,
        topBlock,
        formRight,
        tabFiles,
        tabMain,
        tabDevices,
        tabChat,
        tabCheckList,
        tabTestCases,
        tabReports,
        modalremove
    },
    data() {
        return {
            form: '',
            dialogShow: false, // стан відображення вікна на підтвердження видалення
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        toDelete(){
            // Видалення картки тестування
            apiServe.deleteTesting(this.form.testingId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        toWork(){
            // взяти в роботу завдання
            apiServe.inJobTesting(this.form.testingId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('TakentoWork'));
                    this.updateCard();
                } else {
                    this.$toast.error(`error`);
                }
            })
        },
        updateCard(){
            // componentKey
            apiServe.getTesting(this.form.testingId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.componentKey += 1
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
    },
    mounted() {
        this.eventBus.off('UpdateCardTesting');
        this.eventBus.on('UpdateCardTesting', (status) => {
            if(status == true) {
                this.updateCard();
            }
        })
    }
}
</script>

<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
}

.header-line-container h6 {
    margin: 0;
    padding-right: 10px; /* Відступ між заголовком і лінією */
}

.header-line-container hr {
    flex-grow: 1;
    border: none;
    /* border-top: 2px solid #000; /* Можна змінити товщину та колір лінії */
    margin: 0;
}
</style>