<template>
    <!-- 
        Компонент зі списком компаній, в якому ви можете - 
        - переглянути список компаній (активні та видалені) та скористатить пошуком (назві, id компанії)
        - обрати компанію 
        - змінити ліміт компаній
        - увімкнути нову версії
        - скопіювати дані з старої версію в нову
        - та інше :)
    -->

    <div >
        <h1 style="display: flex;">
            <!-- Заголовок компоненту "Всі компанії" -->
            <b>{{ $t('AllCompany') }}</b> 
            <button 
                type="button" 
                v-if="perms[20211] || perms[2000]" 
                @click="showDialog = true" 
                class="btn btn-outline-secondary waves-effect waves-light" 
                style="margin-left: 10px;"
            >
                {{ $t('ChangeLimitCompanies') }}
            </button>

            <!-- Кнопка для виклику додаткових опцій у випадаючому списку -->
            <div 
                class="dropdown" 
                v-if="perms[2021] || perms[20212] || perms[2027] || perms[2000]" 
                style="margin-left: 7px;align-items: center;display: flex;"
            >

                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>

                <!-- Випадаюче меню з додатковими опціями -->
                <div class="dropdown-menu dropdown-menu-end dropdownmenu-info">
                    
                    <div class="dropdown-header noti-title border-bottom mb-2">
                        <h5 class="font-size-13 text-muted text-truncate mn-0 fw-semibold">{{ $t('chooseATask') }}</h5>
                    </div>

                    <!-- Увімкнути нову версію -->
                    <button v-if="perms[2021] || perms[2000]" class="dropdown-item" @click="modalCreated.show = true">{{ $t('EnablenewVersion') }}</button>

                    <!-- Увімкнути нову версію з копіюванням -->
                    <button v-if="perms[2021] || perms[2000]" class="dropdown-item" @click="openCreatedSky()">{{ $t('EnablenewVersion') }} ({{ $t('WithCopying') }})</button>

                    <!-- Копіювати дані зі старої версії до нової -->
                    <button v-if="perms[20212] || perms[2000]" class="dropdown-item" @click="openCopyInSky()">{{ $t('trans_data_from_old_company') }}</button >

                    <div v-if="perms[2027] || perms[2000]" class="dropdown-divider "></div>

                    <!-- Скинути пароль -->
                    <button v-if="perms[2027] || perms[2000]" class="dropdown-item" @click="ResetPass()">{{ $t('resetPassword') }}</button > 
                </div>
            </div>

        </h1>

        <span>{{ $t('enterCompany') }}</span>

        <!-- Поле для введення тексту пошуку -->
        <input type="text" v-model="search" class="form-control" />

        <b-row class="mt-3">
            <template v-for="item in filteredCompanies" :key="item">
                <!-- Карточка для кожної компанії з можливістю кліку -->
                <b-col 
                    lg="3" 
                    @contextmenu.prevent="showContextMenu($event, item)"
                    @click="item.companyDescription != 'root' ? openComp(item.companyId) : this.$toast.error(this.$t('error'));
                ">
                    <!-- Змінюємо стилі картки компанії пошуку, щоб її помітити серед всього списку -->
                    <div 
                        class="card " 
                        :class="this.companyFromSearch != item.companyId ? 'card-body-success' : 'card-body-primary'"
                    >
                        <div class="d-flex mb-4 align-items-center" >
                            <div class="flex-grow-1 " >

                                <!-- Назва компанії -->
                                <h5 
                                    class="card-title mb-1 text-white fs-18" 
                                    style="word-break: break-all;"
                                >
                                    {{ item.companyName }}
                                </h5>
                                
                                <!-- Сервер компанії, визначаємо стара версія адмінки чи нова -->
                                <p 
                                    :class="`text-white mb-0 fs-12 ${item.more.companyServer ? mutateCompServer(item.more.companyServer).color : ''}`"
                                > 
                                    {{ item.more.companyServer != "" ? mutateCompServer(item.more.companyServer).name : '-' }}
                                </p>
                                
                                <!-- ID компанії -->
                                <p 
                                    class="text-white mb-0 fs-12"
                                >
                                    <i class="bx bx-info-circle fs-12"></i> 
                                    {{ item.companyId != "" ? item.companyId : '-' }}
                                </p>

                            </div>
                        </div>

                        <!-- Опис компанії -->
                        <h5 
                            class="fs-13 text-white"
                        >
                            {{ item.companyDescription ? item.companyDescription : '-' }}
                        </h5>

                        <!-- Тип права на компанію (адміністратор або співробітник) -->
                        <h6 
                            :class="`fs-12 text-white`"
                        >
                            {{ mutateUser(item.personAdmin) }}
                        </h6>
                        
                        <!-- Дата створення компанії -->
                        <p 
                            class="card-text text-white"
                        >
                            {{ item.more.dateOfCreate }}
                        </p>

                        <!-- Додання в карту "ви шукали цю компанію", щоб помітити пошукову компанію -->
                        <div 
                            class="alert alert-success" 
                            role="alert" 
                            v-if="this.companyFromSearch == item.companyId"
                        >
                            {{this.$t('youFoundComp')}}
                        </div>
                        
                    </div>
                </b-col>
            </template>

            <!-- Карточка для видалених компаній -->
            <b-col 
                lg="3" 
                v-for="item in filteredItemsDel" 
                :key="item" 
                @contextmenu.prevent="showContextMenu($event, item)"
                @click="openComp(item.companyId)"
            >
                <div class="card card-body-danger"  >
                    <div class="d-flex mb-4 align-items-center" >
                        <div class="flex-grow-1 " >

                            <!-- Назва компанії -->
                            <h5 
                                class="card-title mb-1 text-white fs-18"
                                style="word-break: break-all;"
                            >
                                {{ item.companyName }}
                            </h5>

                            <!-- ID компанії -->
                            <p 
                                class="text-white mb-0 fs-12"
                            >
                                <i class="bx bx-info-circle fs-12"></i> 
                                {{ item.companyId != "" ? item.companyId : '-' }}
                            </p>
                        </div>
                    </div>

                    <!-- Опис компанії -->
                    <h5 
                        class="fs-13 text-white"
                    >
                        {{ item.companyDescription ? item.companyDescription : '-' }}
                    </h5>

                    <!-- 
                        Тип права на компанію (адміністратор або співробітник) 
                    -->
                    <h6 
                        :class="`fs-12 text-white`"
                    >
                        {{ mutateUser(item.personAdmin) }}
                    </h6>

                    <!-- Дата створення компанії -->
                    <p 
                        class="card-text text-white"
                    >
                        {{ item.more.dateOfCreate }}
                    </p>

                </div>
            </b-col>
        </b-row>
    </div>

    <!-- Модальне вікно для редагування ліміту компаній -->
    <dialogBox 
        v-if="showDialog"
        @close="showDialog = false"
    >
        <template v-slot:title>{{ $t('ChangeLimitCompanies') }}</template>
        <template v-slot:body>
            <div class="mt-1 pb-3 text-center">
                <h3>
                    <b>{{ $t('SpecifyLimitCompanies') }}</b>
                </h3>

                <!-- Поле для введення нового ліміту компаній -->
                <input 
                    type="number" 
                    class="form-control w-50 m-auto" 
                    :placeholder="$t('enteraNumber')" 
                    v-model="form.newNumberOfCompanies" 
                    oninput="this.value = this.value.replace(/\./g, '')"  
                />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка для закриття модального вікна -->
                <button class="btn btn-danger" @click="showDialog = false">{{ $t('cancel') }}</button>

                <!-- Кнопка для редагування ліміту компаній -->
                <button class="btn btn-success" @click="editMaxNumber()">{{ $t('Edit') }}</button>
            </div>
        </template>
    </dialogBox>

    <!-- Модальне вікно створення нової компанії -->
    <dialogBox
        v-if="modalCreated.show"
        @close="modalCreated.show = false;modalCreated.copy = false"
    >
        <template v-slot:title>{{ $t('EnablenewVersion') }}</template>
        <template v-slot:body>
            <template v-if="modalCreated.copy == true">
                <div class="mt-1 pb-3 ">
                    <!-- Обрати компанію -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('enterCompany') }}</h4>
                        <Multiselect
                            v-model="companyId"
                            :close-on-select="true"
                            :searchable="true"
                            :object="true"
                            :create-option="false"
                            :options="listActiveCompany"
                            :placeholder="$t('enterCompany')"
                        />
                    </div>

                    <div class="mb-1">
                        <!-- 
                            Селектор на вибір точки (не масив)
                        -->
                        <h4 class="fs-15">{{ $t('EnterTradePoint') }}</h4>
                        <Multiselect
                            v-model="selectedOneTT"
                            :close-on-select="true"
                            :searchable="true"
                            :object="true"
                            :options="listTT"
                        />

                        <!-- 
                            Кнопка для отримання торгових точок по обраній компанії
                        -->
                        <button 
                            class="btn btn-outline-success mt-2 mb-2" 
                            type="button" 
                            @click="getTT"
                        >
                            {{ $t('getRequestTT') }}
                        </button>
                    </div>
                </div>
            </template>
            <template v-else>
                <h3 class="text-center">
                    <b>{{ $t('ConfirmCreationNewVersion') }}</b>
                </h3>
                <div class="alert alert-info">
                    <p class="mb-0">
                        <span class="fw-semibold">{{ $t('confirmation') }} :</span>
                        {{ $t('SpecifyTestWord') }} "new"
                    </p>
                </div>
                <input class="form-control" v-model="userInput" @input="checkInput" :placeholder="$t('SpecifyTestWord')" />
            </template>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка для закриття модального вікна -->
                <button class="btn btn-danger" @click="modalCreated.show = false;modalCreated.copy = false">{{ $t('cancel') }}</button>

                <!-- Кнопка для редагування ліміту компаній -->
                <button v-if="status || modalCreated.copy == true" class="btn btn-success" @click="modalCreated.copy == true ? createAndTransferDataSkyNew() : createSkyNewForClient()">{{ $t('perform') }}</button>
            </div>
        </template>
    </dialogBox>
    
    <!-- Модальне вікно копіювання між компаніями -->
    <dialogBox
        v-if="modalCopy.show"
        @close="modalCopy.show = false;"
    >
        <template v-slot:title>{{ $t('EnablenewVersion') }}</template>
        <template v-slot:body>
            <div class="mt-1 pb-3 ">
                <!-- Виберіть компанію з якою відбуватиметься копіювання -->
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('enterCompanyFirst') }}</h4>
                    <Multiselect
                        v-model="companyId"
                        :close-on-select="true"
                        :searchable="true"
                        :object="true"
                        :create-option="false"
                        :options="listActiveCompany"
                        :placeholder="$t('enterCompany')"
                    />
                </div>

                <!-- Виберіть компанію, на яку відбуватиметься копіювання -->
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('enterCompanySecond') }}</h4>
                    <Multiselect
                        v-model="companySecondId"
                        :close-on-select="true"
                        :searchable="true"
                        :object="true"
                        :create-option="false"
                        :options="listActiveNewCompany"
                        :placeholder="$t('enterCompany')"
                    />
                </div>

                <div class="mb-1">
                    <!-- 
                        Селектор на вибір точки (не масив)
                    -->
                    <h4 class="fs-15">{{ $t('EnterTradePoint') }}</h4>
                    <Multiselect
                        v-model="selectedOneTT"
                        :close-on-select="true"
                        :searchable="true"
                        :object="true"
                        :options="listTT"
                    />

                    <!-- 
                        Кнопка для отримання торгових точок по обраній компанії
                    -->
                    <button 
                        class="btn btn-outline-success mt-2 mb-2" 
                        type="button" 
                        @click="getTT"
                    >
                        {{ $t('getRequestTT') }}
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка для закриття модального вікна -->
                <button class="btn btn-danger" @click="modalCreated.show = false;modalCreated.copy = false">{{ $t('cancel') }}</button>

                <!-- Кнопка для редагування ліміту компаній -->
                <button class="btn btn-success" @click="transDataFromOldCompany()">{{ $t('perform') }}</button>
            </div>
        </template>
    </dialogBox>
    
    <!-- Модальне вікно з детальною інформацією про компанію -->
    <dialogBox 
        v-if="objInfoComp.show"
        @close="objInfoComp.show = false"
    >
        <template v-slot:title>{{ $t('AboutTheCompany') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('CompanyName') }}</h4>
                <input 
                    type="text" 
                    class="form-control m-auto" 
                    :value="objInfoComp.data.companyName" 
                    disabled
                />
            </div>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('desc') }}</h4>
                <input 
                    type="text" 
                    class="form-control m-auto" 
                    :value="objInfoComp.data.companyDescription ? objInfoComp.data.companyDescription : '-'" 
                    disabled
                />
            </div>
            <div class="mb-3">
                <h4 class="fs-15">ID {{ $t('Companies') }}</h4>
                <input 
                    type="text" 
                    class="form-control m-auto" 
                    :value="objInfoComp.data.companyId ? objInfoComp.data.companyId : '-'" 
                    disabled
                />
            </div>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('DateTimeCompanyCreation') }}</h4>
                <input 
                    type="text" 
                    class="form-control m-auto" 
                    :value="objInfoComp.data.more.dateOfCreate ? objInfoComp.data.more.dateOfCreate : '-'" 
                    disabled
                />
            </div>
            <div class="mb-3" v-if="objInfoComp.data.delete.dateOfDelete">
                <h4 class="fs-15">{{ $t('DateTimeCompanyDelete') }}</h4>
                <input 
                    type="text" 
                    class="form-control m-auto" 
                    :value="objInfoComp.data.delete.dateOfDelete ? objInfoComp.data.delete.dateOfDelete : '-'" 
                    disabled
                />
            </div>
            <hr />
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Server') }}</h4>
                <input 
                    type="text" 
                    class="form-control m-auto" 
                    :value="objInfoComp.data.more.companyServer ? objInfoComp.data.more.companyServer : '-'" 
                    disabled
                />
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">
                <!-- Кнопка для закриття модального вікна -->
                <button class="btn btn-info" @click="objInfoComp.show = false">{{ $t('close') }}</button>
            </div>
        </template>
    </dialogBox>

    <!-- Контекстне меню -->
    <my-context-menu 
        ref="contextMenu" 
        :btn="btnContextMenu"
        @select="switchCntMenu"
    />

</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import Multiselect from "@vueform/multiselect"; // компонент мультіселектора
import "@vueform/multiselect/themes/default.css"; // стилі до компоненту мультіселектора
import MyContextMenu from '@/components/contextMenu/index.vue'; // компонент контекст-меню
import { mutateUserCompanies, mutateCompServer } from '@/usabilityScripts/globalMutate.js'
import { SuperAdmin, Clients } from '@/API' // клас API, який викорстовується в файлі
import { storeS } from '@/store' // Глобальне сховище даних
import {copy} from '@/usabilityScripts/copy.js'

let apiClients = new Clients();
let apiServe = new SuperAdmin();

export default({
    props: ['companies','deletedcompanies', 'accountId', 'companyFromSearch'],
    components: {
        dialogBox,
        Multiselect,
        MyContextMenu
    },
    data(){
        return{
            search: '', // поле пошуку
            showDialog: false, // стан відображення діалогового вікна
            modalCreated: { // стан відображення діалогового вікна створення компанії та прапорець на копіювання
                show: false,
                copy: false,
            }, 
            modalCopy: { // стан відображення діалогового вікна копіювання між компаніями
                show: false,
            },
            companyId: '', // обрана компанія (стара адмінка)
            companySecondId: '', // обрана друга компанія (нова адмінка)
            listTT: [], // список точок
            selectedOneTT: '', // обрані точки/точка
            listActiveCompany: [], // список активованих компаній (стара версія)
            listActiveNewCompany: [], // список активованих компаній (нова версія)
            form: { // параметри форми
                newNumberOfCompanies: '' // новий ліміт компаній, має бути ціле число
            },
            userInput: '', // Поле вводу перевірочного слова
            status: false, // активація кнопки після капчі
            objInfoComp: {
                show: false,
                data: {}
            }, 
            btnContextMenu: [ // Параметри для контекстного меню
                { 
                    title: this.$t('Open'),
                    icon: 'bx bxs-door-open',
                    color: 'info',
                    value: 'open',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('CopyName'),
                    icon: 'bx bx-copy-alt',
                    color: 'info',
                    value: 'CopyName',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('copyIdCompany'),
                    icon: 'bx bxs-copy-alt',
                    color: 'info',
                    value: 'copyIdCompany',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('MoreInformation'),
                    icon: 'bx bxs-info-circle',
                    color: 'info',
                    value: 'MoreInformation',
                    status: true,
                    perms: null
                },
                { 
                    title: this.$t('Checks'),
                    icon: 'bx bx-list-ul',
                    color: 'info',
                    value: 'openChecks',
                    status: true,
                    perms: null
                },
            ],
            tabIndex: '',
            uniqueCompanies: []
        }
    },
    created(){
        // console.log("comp", this.companies)
        this.removeDuplicate();
    },
    methods: {
        removeDuplicate(){
            // Метод видалення дублікатів компанії

            let companies = this.companies;

            // Використовуємо об'єкт для зберігання унікальних значень за обраними властивостями
            let uniqueMap = {};

            // Проходимо по кожній компанії і додаємо її до об'єкту uniqueMap, якщо вона відповідає критеріям
            companies.forEach(company => {
                const { companyId, personAdmin } = company;
                const key = `${companyId}_${personAdmin}`;

                if (!uniqueMap[key]) {
                    uniqueMap[key] = company;
                }
            });

            // Перетворюємо об'єкт у масив унікальних компаній
            let uniqueCompanies = Object.values(uniqueMap);

            this.uniqueCompanies = uniqueCompanies;
        },
        openComp(accID, tab){
            // Метод для відкриття компанії за ідентифікатором
            this.$emit('opencompany', accID, tab)
        },
        editMaxNumber(){
            // Метод для редагування ліміту компаній
            apiServe.editMaxNumberOfCompanies(this.accountId, this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('newLimitBeenApplied'))
                    this.showDialog = false
                    this.form.newNumberOfCompanies = ''
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        },
        openCreatedSky(){
            this.listActiveCompany = [];

            // Формуємо список компаній в потрібно вигляді
            for(var item in this.companies) {
                if(this.companies[item].more.companyServer == 's-1') {
                    this.listActiveCompany.push({
                        label: `${this.companies[item].companyName}`,
                        value: `${this.companies[item].companyId}`,
                    })
                }
            }

            // Якщо тільки одна компанія, то образу її застосуємо у поле вибору компанії
            if(this.listActiveCompany.length == 1){
                this.companyId = this.listActiveCompany[0] 
            }

            this.modalCreated.copy = true
            this.modalCreated.show = true
        },
        openCopyInSky(){
            this.listActiveCompany = [];
            this.listActiveNewCompany = [];
            
            // Формуємо список компаній в потрібно вигляді (старі версії)
            for(var item in this.companies) {
                if(this.companies[item].more.companyServer == 's-1') {
                    this.listActiveCompany.push({
                        label: `${this.companies[item].companyName}`,
                        value: `${this.companies[item].companyId}`,
                    })
                }
            }

            // Якщо тільки одна компанія, то образу її застосуємо у поле вибору компанії
            if(this.listActiveCompany.length == 1){
                this.companyId = this.listActiveCompany[0] 
            }

            // Формуємо список компаній в потрібно вигляді (нові версії)
            for(var item in this.companies) {
                if(this.companies[item].more.companyServer != 's-1') {
                    this.listActiveNewCompany.push({
                        label: `${this.companies[item].companyName}`,
                        value: `${this.companies[item].companyId}`,
                    })
                }
            }

            // Якщо тільки одна компанія, то образу її застосуємо у поле вибору компанії
            if(this.listActiveNewCompany.length == 1){
                this.companySecondId = this.listActiveNewCompany[0] 
            }

            this.modalCopy.show = true

        },
        getTT(){
            // Отримати торгові точки по компанії
            this.listTT = []
            
            apiServe.getCompClient(this.companyId.value).then(result => {
                if(result.status == 'done'){
                    if(result.data.companyDataObj.tradepoints.length > 0){
                        var tradepoints = result.data.companyDataObj.tradepoints;
                        console.log('tradepoints', tradepoints);
                        for(var item in tradepoints){
                            this.listTT.push({
                                label: tradepoints[item].tradepointName,
                                value: tradepoints[item].tradepointId
                            })
                        }
                        this.$toast.success(this.$t('outletsReceived'));
                    } else {
                        this.$toast.error(this.$t('ThereNoTTFound'));
                    }
                } else {
                    // Помилка
                    this.$toast.error(this.$t('ThereNoTTFound'));

                    // Відправляємо помилку в телеграм
                    var errorText = 'Помилка відкриття компанії клієнта'; 
                    var statusRequest = result.status;
                    var api = 'getCompClient';
                    var fileRequest = 'src/views/tasks/createtask.vue';
                    var params = this.mainCompany.value;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 

                }
            })
        },
        checkInput(){
            // Перевіряємо вказане слово
            if (this.userInput === 'new') {
                this.status = true
            } else {
                this.status = false
            }
        },
        createSkyNewForClient(){
            // Стоврити нову версію (без копіювання)
            apiServe.createSkyNewForClient(this.accountId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('done'))
                    this.modalCreated.show = false;
                    this.modalCreated.copy = false;
                    this.$emit('updateCard');
                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при створені компанії нової-адмінки таски'; 
                    var statusRequest = result.status;
                    var api = 'createSkyNewForClient';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при створені компанії нової-адмінки таски'; 
                    var statusRequest = result.status;
                    var api = 'createSkyNewForClient';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('error'))

                    var errorText = 'Помилка при створені компанії нової-адмінки таски'; 
                    var statusRequest = result.status;
                    var api = 'createSkyNewForClient';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        createAndTransferDataSkyNew(){
            // створити нову компанію з новою адмінкою клієнту (з копіюванням даних зі старого скаю)

            var newObj = {
                "accountId": this.accountId,
                "oldCompanyId": this.companyId.value,
                "tradepointId": this.selectedOneTT.value
            }

            apiServe.createAndTransferDataSkyNew(newObj).then(result => {
                if(result.status == 'done'){

                    if(result.data?.transferred == true) {
                        this.$toast.success(this.$t('done'))
                        this.modalCreated.show = false;
                        this.modalCreated.copy = false;
                        this.$emit('updateCard');
                    } else {
                        this.$toast.error(this.$t('CopyingNotOccur'))

                        var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням зі статусом Done'; 
                        var statusRequest = result.status;
                        var api = 'createAndTransferDataSkyNew';
                        var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                        var params = newObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }

                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням таски'; 
                    var statusRequest = result.status;
                    var api = 'createAndTransferDataSkyNew';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням таски'; 
                    var statusRequest = result.status;
                    var api = 'createAndTransferDataSkyNew';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('error'))

                    console.log("else 4 >");

                    var errorText = 'Помилка при створені компанії нової-адмінки з копіюванням таски'; 
                    var statusRequest = result.status;
                    var api = 'createAndTransferDataSkyNew';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        transDataFromOldCompany(){
            // скопіювати дані з старої адмінки в нову

            var newObj = {
                "oldCompanyId": this.companyId.value,
                "newCompanyId": this.companySecondId.value,
                "tradepointId": this.selectedOneTT.value
            }

            console.log('newobj', newObj);
            apiServe.transDataFromOldCompany(newObj).then(result => {
                if(result.status == 'done'){

                    if(result.data?.transferred == true) {
                        this.$toast.success(this.$t('done'))
                        this.modalCopy.show = false;
                        this.$emit('updateCard');
                    } else {
                        this.$toast.error(this.$t('CopyingNotOccur'))

                        var errorText = 'Помилка при копіюванні даних з статусом Done'; 
                        var statusRequest = result.status;
                        var api = 'transDataFromOldCompany';
                        var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                        var params = newObj;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error)); 
                    }
                    
                } else if(result.status == 'error' && result.error[0] == 'account not found'){
                    this.$toast.error(this.$t('AccountNotFound'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter account id'){
                    this.$toast.error(this.$t('EnterYourAccount'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'enter data'){
                    this.$toast.error(this.$t('enterData'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else if(result.status == 'error' && result.error[0] == 'access denied'){
                    this.$toast.error(this.$t('accessDenied'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                } else {
                    this.$toast.error(this.$t('err'))

                    var errorText = 'Помилка при копіюванні даних'; 
                    var statusRequest = result.status;
                    var api = 'transDataFromOldCompany';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = newObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        ResetPass(){
            // Скинути пароль

            apiClients.new_reset_passwordClient(this.accountId).then(res => {
                if(res.status == "done"){
                    this.$toast.success(this.$t('resetPasswordDone'));
                }else {
                    this.$toast.error(this.$t('err'));

                    var errorText = 'Помилка при скидувані паролю'; 
                    var statusRequest = result.status;
                    var api = 'new_reset_passwordClient';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/companies.vue';
                    var params = this.form.accountId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error)); 
                }
            })
        },
        showContextMenu(event, item) {
            // Метод, який викликається при правому кліку на елементі, передаючи подію та об'єкт item

            // Викликаємо метод показу контекстного меню після оновлення DOM
            this.$nextTick(() => {
                // Виклик методу показу контекстного меню у компоненті contextMenu,
                // передаючи подію та об'єкт item
                this.$refs.contextMenu.showContextMenu(event, item);
            });
        },
        switchCntMenu(e, item){
            // Виконуємо обрану дію з контекстного меню

            switch (e) {
                case 'open':
                    // Відкрити компанію
                    this.openComp(item.companyId)
                    break;
                case 'CopyName':
                    // Відкрити компанію
                    this.copy(item.companyName)
                    break;
                case 'copyIdCompany':
                    // Відкрити компанію
                    this.copy(item.companyId)
                    break;
                case 'MoreInformation':
                    // Відкрити компанію
                    this.objInfoComp.data = item;
                    this.objInfoComp.show = true
                    break;
                case 'openChecks':
                    // Відкрити компанію
                    this.tabIndex = 3
                    this.openComp(item.companyId, this.tabIndex)
                    break;
                
                default:
                    break;
            }
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
        mutateUser(e){
            // Метод для форматування користувача компанії
            return mutateUserCompanies(e)
        },
        mutateCompServer(e){
            // Метод для форматування сервера компанії
            return mutateCompServer(e)
        }
    },
    computed: {
        filteredCompanies: function() {
            // Фільтрація компаній за текстом пошуку
            return this.uniqueCompanies?.filter(item => {
                    return this.search == '' || item.companyName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.companyId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        filteredItemsDel: function() {
            // Фільтрація видалених компаній за текстом пошуку
            return this.deletedcompanies?.filter(item => {
                    return this.search == '' || item.companyName.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || item.companyId.toString().toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
                })
        },
        perms(){
            // Повертаємо права доступу юзера з глобального стору
            return storeS.perms
        }
    }
})
</script>

<style scoped>
/* .scroll_comp{
    display: flex;
    max-width: 100%;
    overflow: scroll;
    width: 100%;
} */
.card-body-success {
    /* margin-right: 10px; */
    /* max-width: 250px; */
    padding: 15px;
    background-color: #009688;
    cursor: pointer;
}
.card-body-danger {
    /* margin-right: 10px; */
    /* max-width: 250px; */
    padding: 15px;
    background-color: #ee664e;
    cursor: pointer;
}

.card-body-primary{
    padding: 15px;
    background-color: #0d6efd !important;
    cursor: pointer;
}
</style>