<template>
    <dialogmodal @close="handleClose">
        <template v-slot:title>
            <span>Підтвердіть дію</span>
        </template>
        <template v-slot:body>
            <p class="mb-0">Ви впевнені, що хочете продовжити?</p>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn btn-success" @click="this.$emit('kickUser', this.id)">{{$t('y')}}</button>
            <button class="btn btn-danger" @click="this.$emit('close')">{{$t('n')}}</button>
        </template>
    </dialogmodal>
</template>

<script>
import dialogmodal from '@/components/modal/dialog.vue';

export default {
    props: ['id'],
    data() {
        return{
            
        }
    },
    components: {
        dialogmodal
    },
};
</script>