<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>Cинхронізовані після репорту</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <!-- Табличка айдішників -->
                <table class="table" v-if="this.data.items.filter(items => items.syncAfterReport === true).length > 0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <!-- <th>{{this.$t('Status')}}</th> -->
                            <th>{{this.$t('Sum')}}</th>
                            <th>{{this.$t('date_time')}}</th>
                            <th>{{this.$t('action')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, i in this.data.items.filter(items => items.syncAfterReport === true)" :key="i">
                            <th>{{ item.orderId }}</th>
                            <!-- <td>{{ this.mutateStatus(item.syncAfterReport) }}</td> -->
                            <td>{{ item.sum }}</td>
                            <td>{{ item.datetime }}</td>
                            <td>
                                <button 
                                    @click="syncCheck(item.orderId)"
                                    v-if="item.syncAfterReport === false" 
                                    class="btn btn-danger"
                                >
                                    {{ this.$t('sync') }}
                                </button>
                                <p v-else>
                                    {{ this.$t('Synchronized') }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Результатів нема -->
                <div v-else class="noresult" style="display: block;">
                    <div class="text-center">
                        <h5 class="mt-2">{{ 
                            this.data.items.filter(items => items.syncAfterReport === false).length > 0 ? 
                            $t('badNews') : $t('pashNotFound') 
                        }}</h5>
                        <p class="text-muted mb-0">{{ 
                            this.data.items.filter(items => items.syncAfterReport === false).length > 0 ? 
                            $t('youHaveUnSyncChecks') : $t('pashNotFoundDesc')  
                        }}</p>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import tablecustom from "@/components/globaltable/index"
import { storeS } from '@/store'

export default{
    props: ['data'],
    data(){
        return {

        }
    },
    components: {
        tablecustom
    },
    created(){
        
    },
    methods: {
        // Мутейт статусів
        mutateStatus(status) {
            if(status === true) {
                return this.$t('Synchronized')
            } else {
                return this.$t('OutofSync')
            }
        },
        // Функція синхронізації чеків
        syncCheck(id) {
            console.log('syncChecks')
        }
    },
    computed: {
        checks(){
            return storeS.checks
        }
    }
}
</script>
