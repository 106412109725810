<template>
    <div class="mb-4 border-top mt-3 pt-3" >
        <label
            for="status-select"
            class="form-label fs-15 text-success text-uppercase fw-semibold mb-3"
            >{{ $t('labels') }}</label
        >
        <br />
        <p class="text-muted mb-2">
            <a 
                @click="this.getdata()" 
                class="link copy float-end text-decoration-underline"
            >
                {{ $t('refresh') }}
            </a>
            {{ $t('project') }}
        </p>
        <div class="row g-2 list_filtr" >
            <Multiselect 
                v-model="tagsId"
                :close-on-select="true"
                :searchable="true"
                mode="tags"
                :create-option="false"
                :options="tagsList"
            />
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { storeS } from '@/store';
import { HashTags } from '@/API'

let apiHashTags = new HashTags()

export default {
    props: ['typeTags', 'hashtags'],
    components: { Multiselect },
    data(){
        return {
            tagsId: [],
            tagsList: [

            ],
        }
    },
    created() {
        if(this.typeTags === 'tickets') {
            this.getdata()
        } else {
            this.switchTags();
        }
    },
    watch: {
        tagsId(newValue, oldValue) {
            console.log(newValue, oldValue)
            this.change(newValue)
        }
    },
    methods: {
        switchTags(){
            this.tagsList.push({ label: this.$t('all'), value: '' })
            switch (this.typeTags) {
                case 'faq':
                    for(var item in this.tagsFaq){
                        this.tagsList.push({
                            label: this.tagsFaq[item].tagName,
                            value: this.tagsFaq[item].tagId,
                        })
                    }
                    break;
                case 'kw':

                    for(var item in this.tagsLessons){
                        this.tagsList.push({
                            label: this.tagsLessons[item].tagName,
                            value: this.tagsLessons[item].tagId,
                        })
                    }
                    break;
                // case 'tickets':
                //     this.getdata()
                //     break;
                case 'projects':
                    for(var item in this.mainTags){
                        this.tagsList.push({
                            label: this.mainTags[item].tagName,
                            value: this.mainTags[item].tagId,
                        })
                    }
                    break;
            
                default:
                    break;
            }
        },
        getdata() {
            this.tagsList = []
            apiHashTags.getHashtags().then(result => {
                if(result.status === 'done') {
                    result.data.items.forEach(element => {
                        console.log(element)
                        this.tagsList.push({
                            label: element.hashtagName,
                            value: element.hashtagId
                        })  
                    });
                }
            });
        },
        change(value){
            console.log(value)
            if(this.typeTags === 'tickets') {
                this.$emit('change', 'hashtags', JSON.stringify(value))
            } 
            // else {
            //     this.$emit('change', 'tags', this.tagsId)
            // }
        },
    },
    computed: {
        mainTags(){
            return storeS.tags
        },
        tagsFaq(){
            return storeS.tagsFaq
        },
        tagsLessons(){
            return storeS.tagsLessons
        }
    }
}
</script>