<template>
    <!-- 
        Модальне вікно створення завдання на тестування 
    -->

    <modal 
        :title="$t('CreatingTestTask')" 
    >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('CreatingTestTask') }}</template>

        <!-- Слот шапки модального вікна (кнопки по праву сторону шапки) -->
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-info btn-icon btn-sm fs-16 close-btn-email"  @click="infoShowbox = true"><i class="ri-information-line align-bottom"></i></button>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>

        <!-- Слот тіло модального вікна -->
        <template v-slot:body>

            <b-row>
                <!-- Основне -->
                <div class="preconditions  mb-3">
                    <div class="header-line-container">
                        <h6 class="fw-semibold text-uppercase">{{ $t('main') }}</h6>
                        <hr>
                    </div>
                </div>

                <!-- Назва завдання -->
                <div class="mb-3">
                    <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                    <input type="text" class="form-control" id="contactNumber" v-model="form.name" :placeholder="$t('ExampleTestFuncAddProduct')">
                </div>

            
                <b-col lg="6">
                    <!-- Проект -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('project') }}</h4>
                        <Multiselect 
                            v-model="this.form.problemEnvironment"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.problemEnvironment"
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <!-- термін виконання -->
                    <div class="mb-3">
                        <h4 class="fs-15">{{ $t('Deadline') }}</h4>
                        <flat-pickr
                            v-model="form.deadlineDatetime"
                            :config="config"
                            class="form-control"
                            :placeholder="$t('EnterDeadlineTaskTesting')"
                        ></flat-pickr>
                    </div>
                </b-col>

                <b-col lg="6">
                    <div class="mb-3 mb-lg-0" >
                        <h4 class="fs-15">{{ $t('priority') }}</h4>
                        <Multiselect 
                            v-model="this.form.priority"
                            :close-on-select="true"
                            :searchable="true"
                            :create-option="false"
                            :options="this.listPriority"
                            :placeholder='$t("choose_a_priority")'
                        />
                    </div>
                </b-col>
                <b-col lg="6">
                    <h4 class="fs-15">{{ $t('TypeofTesting') }} (ще під питанням чи потрібно?)</h4>
                    <Multiselect 
                        v-model="this.form.type"
                        :close-on-select="true"
                        :searchable="true"
                        :create-option="false"
                        :object="false"
                        :options="this.listTypes"
                    />
                </b-col>

            </b-row>

            <!-- опис -->
            <div class="mb-3 mt-3">
                <label class="form-label">{{ $t('desc') }}</label>
                <QuillEditor v-model:content="form.description" theme="snow" contentType="html" toolbar="full"/>
            </div>
        
            <!-- Передумови -->
            <div class="preconditions mt-4 mb-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('Prerequisites') }}</h6>
                    <hr>
                </div>
            </div>

            <!-- Передумови -->
            <div class="mb-3">
                <b-row>
                    <b-col lg="6" v-if="statusEditPrecondition">
                        <div class="d-flex mb-3">
                            <span class="text-muted mb-0 flex-grow-1">{{ $t('SpecifytheTestingPrerequisites') }}</span>
                        </div>
                        <div class="flex-grow-1 ms-2 mb-2">
                            <div class="input-group">
                                <input type="text" v-model="precondition.preconditionText" @keydown.enter="addPrecondition(precondition)" class="form-control"  >
                                <button @click="addPrecondition(precondition)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.form.preconditions" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-light text-black">#{{index+1}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.preconditionText" @keydown.enter="addPrecondition(input,index)">
                                            <button @click="addPrecondition(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="removePrecondition(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </b-col>
                    <b-col lg="6" v-else>
                        <ul class="ps-4 vstack gap-2 mt-3" >
                            <li v-for="item in form.preconditions" :key="item">{{ item.preconditionText }}</li>
                        </ul>
                    </b-col>
                </b-row>
                

                <div class="d-flex gap-2">

                    <!-- закріпити передумови -->
                    <button 
                        @click="statusEditPrecondition = !statusEditPrecondition" 
                        type="button" 
                        class="btn btn-soft-info btn-icon btn-md fs-16 mt-2 " 
                    >
                        <i :class="`${statusEditPrecondition ? 'ri-lock-fill' : 'ri-lock-unlock-fill'} align-bottom`" ></i>
                    </button>

                    <!-- 
                        очистити всі пункти todo
                        - доробити виклик вікна підтвердження
                        - очищати всі пункти передумов
                    -->
                    <button 
                        type="button" 
                        class="btn btn-soft-danger btn-md fs-13 mt-2" 
                    >
                        {{ $t('Clear') }}
                    </button>

                </div>
                
            </div>

            <!-- Чек-лист або Тест-кейси -->
            <div class="preconditions mt-4 mb-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('TestingPlan') }}</h6>
                    <hr>
                </div>
            </div>

            <b-row>
                <b-col lg="6">
                    <div class="card bg-light">

                        <!-- Кнопка для видалення активного блоку -->
                        <div class="disabledBlock" v-if="form.testingPlan === 'checklist'">
                            <button 
                                @click="form.testingPlan = ''" 
                                type="button" 
                                class="btn btn-danger mt-3 fs-14 fw-semibold"
                            >
                                {{ $t('RemoveP') }}
                            </button>
                        </div>
                        
                        <!-- Загальна інформація про чек-лист -->
                        <div class="card-body copy m-2" @click="changePlanTesting('checklist')">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="bx bx-square-rounded display-6 text-muted"></i>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h3 class="mb-0 fw-semibold">{{ $t('AddaChecklist') }}</h3>
                                    <span class="mt-1 fs-11 text-muted" style="display: block;">{{ $t('TestingCheckpoint') }}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                </b-col>

                <b-col lg="6">
                    <div class="card bg-light">

                        <!-- Кнопка для видалення активного блоку -->
                        <div class="disabledBlock" v-if="form.testingPlan === 'testcases'">
                            <button 
                                @click="form.testingPlan = ''" 
                                type="button" 
                                class="btn btn-danger mt-3 fs-14 fw-semibold"
                            >
                                {{ $t('RemoveP') }}
                            </button>
                        </div>

                        <!-- Загальна інформація про тест-кейси -->
                        <div class="card-body copy m-2" @click="changePlanTesting('testcases')">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <i class="ri-list-ordered display-6 text-muted"></i>
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h3 class="mb-0 fw-semibold">{{ $t('AddTestCases') }}</h3>
                                    <span class="mt-1 fs-11 text-muted" style="display: block;">{{ $t('DetailedTestingScenario') }}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </b-col>

            </b-row>

            <!-- Чек-лист -->
            <div class="mb-3" v-if="form.testingPlan == 'checklist'">
                <b-row>
                    <b-col lg="6" v-if="statusEditCheckList">
                        <div class="d-flex mb-3">
                            <span class="text-muted mb-0 flex-grow-1">{{ $t('AddcheckPointsToChecklist') }}</span>
                        </div>
                        <div class="flex-grow-1 ms-2 mb-2">
                            <div class="input-group">
                                <input type="text" v-model="checklistItem.name" @keydown.enter="addCheckList(checklistItem)" class="form-control"  >
                                <button @click="addCheckList(checklistItem)" class="btn btn-success" type="button" ><i class="ri-add-line"></i></button>
                            </div>
                        </div>
                        <ul class="list-unstyled vstack gap-3 mb-0">
                            <li v-for="(input, index) in this.tempPlanData.checklist" :key="index">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0">
                                        <div class="avatar-xs flex-shrink-0 me-1"><div class="avatar-title rounded bg-light text-black">#{{index+1}}</div></div>
                                    </div>
                                    <div class="flex-grow-1 ms-2">
                                        <div class="input-group">
                                            <input type="text" class="form-control" v-model="input.name" @keydown.enter="addCheckList(input,index)">
                                            <button @click="addCheckList(input,index)" class="btn btn-success" type="button"><i class="ri-add-line"></i></button>
                                            <button @click="removeCheckList(index)" class="btn btn-danger" type="button"><i class="ri-delete-bin-2-line"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </b-col>
                    <b-col lg="6" v-else>
                        <ul class="ps-4 vstack gap-2 mt-3" >
                            <li v-for="item in tempPlanData.checklist" :key="item">{{ item.name }}</li>
                        </ul>
                    </b-col>
                </b-row>
                

                <button @click="statusEditCheckList = !statusEditCheckList" type="button" class="btn btn-soft-info btn-icon btn-md fs-16 mt-2" >
                    <i :class="`${statusEditCheckList ? 'ri-lock-fill' : 'ri-lock-unlock-fill'} align-bottom`" ></i>
                </button>
            </div>

            <!-- Тест-кейси -->
            <div class="mb-3" v-if="form.testingPlan == 'testcases'">
                <b-row>
                    <b-col lg="12" v-if="statusEditCheckList">
                        <div class="d-flex mb-3">
                            <span class="text-muted mb-0 flex-grow-1">{{ $t('SpecifytheTestScenario') }}</span>
                        </div>
                        <div class="live-preview mt-3"  >
                            <div class="table-responsive"  >
                                <table class="table table-nowrap mb-0" >
                                    <thead class="table-light" >
                                        <tr >
                                            <th scope="col" >№</th>
                                            <th scope="col" >{{ $t('Step') }}</th>
                                            <th scope="col" >{{ $t('TestData') }}</th>
                                            <th scope="col" >{{ $t('expectedResult') }}</th>
                                            <th scope="col" >{{ $t('action') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-for="(row, rowIndex) in tempPlanData.testcases" :key="rowIndex">
                                            <td class="fw-medium">{{ rowIndex+1 }}</td>
                                            <td class="fw-medium">
                                                <textarea 
                                                    class="form-control" 
                                                    style="border: none;padding: 0;padding-bottom: 4px;" 
                                                    v-model="row.name" 
                                                    :ref="'field-' + rowIndex"
                                                    @keydown.enter.prevent="addRowBelow(rowIndex)" 
                                                ></textarea>
                                            </td>
                                            <td class="fw-medium">
                                                <textarea 
                                                    class="form-control" 
                                                    style="border: none;padding: 0;padding-bottom: 4px;" 
                                                    v-model="row.testData" 
                                                    @keydown.enter.prevent="addRowBelow(rowIndex)" 
                                                ></textarea>
                                            </td>
                                            <td class="fw-medium">
                                                <textarea 
                                                    class="form-control" 
                                                    style="border: none;padding: 0;padding-bottom: 4px;" 
                                                    v-model="row.expectedResult" 
                                                    @keydown.enter.prevent="addRowBelow(rowIndex)" 
                                                ></textarea>
                                            </td>
                                            <td>
                                                <button @click="removeRow(rowIndex)" type="button" class="btn btn-soft-danger btn-icon close-btn-email" title="Видалити" >
                                                    <i class="ri-delete-bin-2-line align-bottom" ></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <!-- Кнопка для додавання нового рядка -->
                                <button class="btn btn-success mt-2" @click="addRow">{{ $t('AddStep') }}</button>
                            </div>
                        </div>

                    </b-col>
                    <b-col lg="6" v-else>
                        <ul class="ps-4 vstack gap-2 mt-3" >
                            <li v-for="item in tempPlanData.testcases" :key="item">{{ item.text }}</li>
                        </ul>
                    </b-col>
                </b-row>
                
            </div>

            <!-- Середовище тестування -->
            <div class="preconditions mt-4 mb-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">{{ $t('TestingEnvironment') }}</h6>
                    <hr>
                </div>
            </div>

            <div>
                <button v-if="checkAddEnvironment == false" @click="checkAddEnvironment = true" type="button" class="btn btn-soft-info mb-3">
                    <span class="icon-on">
                        <i class="ri-add-line align-bottom me-1"></i> {{ $t('AddEnvironment') }}
                    </span>
                </button>

                <button v-if="checkAddEnvironment == true" type="button" class="btn btn-soft-danger mb-3">
                    <span class="icon-on">
                        <i class="ri-delete-bin-2-line align-bottom me-1"></i> {{ $t('DeleteAllEnvironments') }}
                    </span>
                </button>

                <div v-if="checkAddEnvironment">
                    <!-- Рядок з вибором опцій -->
                    <b-row >     

                        <!-- Блок для вибору операційної системи -->
                        <b-col lg="3">
                            <h4 class="fs-15">{{ $t('SelectOS') }}</h4>
                            <b-form-select v-model="selectedOS" :options="listOS" ></b-form-select>
                        </b-col>

                        <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
                        <b-col lg="3" v-if="selectedOS == 'windows'">
                            <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                            <b-form-select v-model="selectedVerOS" :options="listVerionsWindows" ></b-form-select>
                        </b-col>

                        <b-col lg="3" v-if="selectedOS == 'android'">
                            <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                            <b-form-select v-model="selectedVerOS" :options="listVerionsAndroid" ></b-form-select>
                        </b-col>
                        <b-col lg="3" v-if="selectedOS == 'macos'">
                            <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                            <b-form-select v-model="selectedVerOS" :options="listVerionsMacOS" ></b-form-select>
                        </b-col>
                        <b-col lg="3" v-if="selectedOS == 'ios'">
                            <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                            <b-form-select v-model="selectedVerOS" :options="listVerionsIOS" ></b-form-select>
                        </b-col>

                        <!-- Блок для вибору застосунку для тестування -->
                        <b-col lg="3">
                            <h4 class="fs-15">{{ $t('TestingApplication') }}</h4>
                            <b-form-select v-model="selectedApp" :options="listApp" ></b-form-select>
                        </b-col>

                        <!-- Блок для вибору браузера, якщо обрано застосунок "Браузер" -->
                        <b-col lg="3" v-if="selectedApp == 'browser'">
                            <h4 class="fs-15">{{ $t('SelectBrowser') }}</h4>
                            <b-form-select v-model="selectedBrowser" :options="listBrowser" ></b-form-select>
                        </b-col>

                        <!-- Блок для вибору версії застосунку або браузера, залежно від умов -->
                        <b-col :lg="`3 ${selectedApp == 'browser' ? 'mt-2' : ''}`" v-if="selectedApp == 'appskyservice' || (selectedApp == 'browser' && selectedBrowser == 'chrome')">
                            <h4 class="fs-15">{{ selectedApp == 'appskyservice' ? $t('ApplicationVersion') : $t('BrowserVersion') }}</h4>

                            <!-- Вибір версії застосунку для Windows -->
                            <b-form-select 
                                v-model="selectedVesAppBrow" 
                                v-if="selectedApp == 'appskyservice' && selectedOS == 'windows'" 
                                :options="listVerAppSkyWindows" 
                            ></b-form-select>
                            
                            <!-- Вибір версії застосунку для Android -->
                            <b-form-select 
                                v-model="selectedVesAppBrow" 
                                v-if="selectedApp == 'appskyservice' && selectedOS == 'android'" 
                                :options="listVerAppSkyAndroid" 
                            ></b-form-select>

                            <!-- Вибір версії застосунку для IOS -->
                            <b-form-select 
                                v-model="selectedVesAppBrow" 
                                v-if="selectedApp == 'appskyservice' && (selectedOS == 'ios' || selectedOS == 'macos')" 
                                :options="listVerAppSkyIOS" 
                            ></b-form-select>

                            <!-- Вибір версії застосунку для Браузера -->
                            <b-form-select 
                                class="mt-2"
                                v-model="selectedVesAppBrow" 
                                v-if="selectedApp == 'browser' && selectedBrowser == 'chrome'" 
                                :options="listVerChrome" 
                            ></b-form-select>
                        </b-col>

                    </b-row>

                    <button 
                        @click="addPlatform()"
                        type="button"
                        class="btn btn-outline-info btn-icon waves-effect waves-light mt-2"
                    >
                        <i class="ri-add-line"></i>
                    </button>

                    <template v-for="(item, index) in form.environments" :key="index">
                        <b-row class="mt-3"> 

                            <b-col lg="11">
                                <b-row>
                                    <!-- Блок для вибору операційної системи -->
                                    <b-col lg="3">
                                        <h4 class="fs-15">{{ $t('SelectOS') }}</h4>
                                        <b-form-select v-model="item.osName" :options="listOS" ></b-form-select>
                                    </b-col>

                                    <!-- Блоки для вибору версій ОС в залежності від обраної операційної системи -->
                                    <b-col lg="3" v-if="item.osName == 'windows'">
                                        <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                        <b-form-select v-model="item.osVersion" :options="listVerionsWindows" ></b-form-select>
                                    </b-col>

                                    <b-col lg="3" v-if="item.osName == 'android'">
                                        <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                        <b-form-select v-model="item.osVersion" :options="listVerionsAndroid" ></b-form-select>
                                    </b-col>
                                    <b-col lg="3" v-if="item.osName == 'macos'">
                                        <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                        <b-form-select v-model="item.osVersion" :options="listVerionsMacOS" ></b-form-select>
                                    </b-col>
                                    <b-col lg="3" v-if="item.osName == 'ios'">
                                        <h4 class="fs-15">{{ $t('SelectOSVersion') }}</h4>
                                        <b-form-select v-model="item.osVersion" :options="listVerionsIOS" ></b-form-select>
                                    </b-col>

                                    <!-- Блок для вибору застосунку для тестування -->
                                    <b-col lg="3">
                                        <h4 class="fs-15">{{ $t('TestingApplication') }}</h4>
                                        <b-form-select v-model="item.testingApplication" :options="listApp" ></b-form-select>
                                    </b-col>

                                    <!-- Блок для вибору браузера, якщо обрано застосунок "Браузер" -->
                                    <b-col lg="3" v-if="item.testingApplication == 'browser'">
                                        <h4 class="fs-15">{{ $t('SelectBrowser') }}</h4>
                                        <b-form-select v-model="item.browser" :options="listBrowser" ></b-form-select>
                                    </b-col>

                                    <!-- Блок для вибору версії застосунку або браузера, залежно від умов -->
                                    <b-col :lg="`3 ${item.testingApplication == 'browser' ? 'mt-2' : ''}`" v-if="item.testingApplication == 'appskyservice' || (item.testingApplication == 'browser' && item.browser == 'chrome')">
                                        <h4 class="fs-15">{{ selectedApp == 'appskyservice' ? $t('ApplicationVersion') : $t('BrowserVersion') }}</h4>

                                        <!-- Вибір версії застосунку для Windows -->
                                        <b-form-select 
                                            v-model="item.applicationVersion" 
                                            v-if="item.testingApplication == 'appskyservice' && item.osName == 'windows'" 
                                            :options="listVerAppSkyWindows" 
                                        ></b-form-select>
                                        
                                        <!-- Вибір версії застосунку для Android -->
                                        <b-form-select 
                                            v-model="item.applicationVersion" 
                                            v-if="item.testingApplication == 'appskyservice' && item.osName == 'android'" 
                                            :options="listVerAppSkyAndroid" 
                                        ></b-form-select>

                                        <!-- Вибір версії застосунку для IOS -->
                                        <b-form-select 
                                            v-model="item.applicationVersion" 
                                            v-if="item.testingApplication == 'appskyservice' && (item.osName == 'ios' || item.osName == 'macos')" 
                                            :options="listVerAppSkyIOS" 
                                        ></b-form-select>

                                        <!-- Вибір версії застосунку для Браузера -->
                                        <b-form-select 
                                            v-model="form.selectedVesAppBrow" 
                                            v-if="item.testingApplication == 'browser' && item.browser == 'chrome'" 
                                            :options="listVerChrome" 
                                        ></b-form-select>
                                        <!-- <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select>
                                        <b-form-select v-model="selectedVesAppBrow" v-if="selectedApp == 'appskyservice'" :options="listVerAppSky" ></b-form-select> -->
                                    </b-col>
                                </b-row>
                            </b-col>
                            
                            <div class="col-lg-1">
                                <h4 class="fs-15" >Дія</h4>
                                <button @click="removePlatform(index)" type="button" class="btn btn-soft-danger btn-icon close-btn-email" :title="$t('DeleteEnvironment')" >
                                    <i class="ri-delete-bin-2-line align-bottom" ></i>
                                </button>
                            </div>


                        </b-row>
                    </template>

                </div>

            </div>
            

            <!-- Обладнання -->
            <!-- <div class="preconditions mt-4 mb-4">
                <div class="header-line-container">
                    <h6 class="fw-semibold text-uppercase">Обладнання тестування (поки під питанням)</h6>
                    <hr>
                </div>
            </div> -->

            <!-- <div>
                <button type="button" class="btn btn-soft-secondary" @click="showdevice = true">
                    <span class="icon-on">
                        <i class="ri-add-line align-bottom me-1"></i> Додати обладнання 
                    </span>
                </button>
            </div> -->
            

            <!-- Файли -->
            <files 
                :obj="files" 
                :objFiles="files" 
                @uploadFiles="uploadFiles"
                @downloadFile="downloadFile"
                @deleteFile="deleteFile"
                @getFile="viewFile"
            />


        </template>

        <template v-slot:footer-bottom>

            <!-- Кнопка "Додати", створює картку завдання -->
            <button 
                type="button" 
                class="btn btn-success" 
                @click="add()"
            >
                {{ $t('Add') }}
            </button>

        </template>

    </modal>

    <!-- Вікно з вибором параметрів -->
    <addParams 
        v-if="showParamsBox"
        @close="showParamsBox = false"
        :activeBlocks="activeBlocks"
        @saveBlock="getBlocks"
    />

    <addDevice 
        v-if="showdevice == true" 
        @close="closeCreate"
    />

    <!-- Компонент з гайдом на створення тестінгу -->
    <infoBox 
        v-if="infoShowbox"
        @close="infoShowbox = false"
    />

    <!-- модальне вікно перегляду файлу -->
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="dialogShow = true" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modallg>

    <!-- Модальне вікно підтвердження видалення -->
    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />


</template>

<script>
// import modal from '@/components/modal'
import modal from '@/components/modal-small'
// import modal from '@/components/modal-small' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import addParams from './params/addParams' // компонент вибору параметрів
import mainParams from './params/main/index' // компонент з загальними параметрами
import devicesParams from './params/devices/index' // компонент з параметрами обладнання
import testSteps from './params/testSteps/index'; // компонент з кроками
import descTesting from './params/desc/index'; // компонент з описом
import checkList from './params/checklist/index'; // компонент з чеклистом
import files from '@/components/files/index'
import addDevice from '../created_testing/params/devices/addDevice.vue' // компонент девайсу
import infoBox from './infoCreatedTesting.vue'; // гайд по тестінгу
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import { Testing } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Testing();

export default {
    components: {
        modal,
        Multiselect,
        addParams,
        mainParams,
        devicesParams,
        testSteps,
        descTesting,
        checkList,
        files,
        addDevice,
        infoBox,
        modallg,
        modalremove,
        flatPickr
    },
    data() {
        return {
            infoShowbox: false, // прапорець відображення вікна з гайдом по тестінгу
            showdevice: false,
            itemdevice: '',
            permToEdit: false,
            form: {
                name: '', // назва завдання
                priority: '', // пріоритет завдання
                problemEnvironment: '', // проект
                deadlineDatetime: '', // термін виконання
                type: '', // тип тестування
                description: '', // опис тестування
                testingUuid: '', // uuid, повертається з додавання файлу
                preconditions: [], // передумови
                environments: [], // середовище тестування
                clauses: [], // пункти 
                ticketId: "", // тікет id
                testingPlan: '' // план тестування
            },
            dialogShow: false, // Прапорець для відображення діалогового вікна видалення файлу
            showImg: false, // Прапорець для відображення зображення
            gFile: { // Дані про обраний файл
                fileId: '',
                src: '',
                type: ''
            },
            ifPrevimg: false,
            ifNextimg: false,
            urlCaches: [], // для відео яке завантажували вже
            files: [], // файли
            config: {
                mode: "single",
                minDate: "today",
                altInput: true,
                altFormat: "F j, Y",
                enableTime: false,
                dateFormat: "Y-m-d",
                locale: ""
            }, // налаштування до поля дедлайну
            selectedOS: 'windows',
            selectedVerOS: '',
            selectedApp: '',
            selectedBrowser: '',
            selectedVesAppBrow: '',
            precondition: {
                preconditionText: ""
            },
            checklistItem: {
                name: ""
            },
            showCheckList: {
                show: false,
                text: ''
            },
            showTestCases: {
                show: false,
                text: ''
            },
            tempPlanData: {
                checklist: [], // масив для чек-листів
                testcases: [] // масив для тест-кейсів
            },
            isFocused: false, // Стан фокусу поля вводу
            inputValue: '',    // Значення інпуту
            nextId: 2, // Для збільшення індексу кожного наступного рядка
            statusEditPrecondition: true, // фіксація передумов
            statusEditCheckList: true, // фіксація чек-листа
            showParamsBox: false,
            activeBlocks: [],
            checkAddEnvironment: false,
            selectedOS: 'windows',
            listTypes: [
                { "value": "manual", "label": "Ручне"},
                // { "value": "automation", "label": "Автоматизоване"},
                { "value": "regression", "label": "Регресійне"},
                { "value": "load_testing", "label": "Навантажувальне"},
            ],
            listOS: [
                { "value": null, "text": 'Оберіть ОС', disabled: true },
                { "value": "windows", "text": "Windows"},
                { "value": "android", "text": "Android"},
                { "value": "ios", "text": "IOS"},
                { "value": "macos", "text": "MacOS"},
            ],
            selectedVerOS: '',
            listVerionsWindows: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "windows11", "text": "Windows 11"},
                { "value": "windows10", "text": "Windows 10"},
                { "value": "windows81", "text": "Windows 8.1"},
                { "value": "windows8", "text": "Windows 8"},
            ],
            listVerionsAndroid: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "14.0", "text": "Android 14.0"},
                { "value": "13.0", "text": "Android 13.0"},
                { "value": "12.1", "text": "Android 12.1"},
                { "value": "12.0", "text": "Android 12.0"},
                { "value": "11.0", "text": "Android 11.0"},
                { "value": "10.0", "text": "Android 10.0"},
                { "value": "9.0", "text": "Android 9.0"},
                { "value": "8.1", "text": "Android 8.1"},
                { "value": "8.0", "text": "Android 8.0"},
                { "value": "7.1", "text": "Android 7.1"},
                { "value": "7.0", "text": "Android 7.0"},
                { "value": "6.0", "text": "Android 6.0"},
                { "value": "5.1", "text": "Android 5.1"},
                { "value": "5.0", "text": "Android 5.0"},
                { "value": "4.4", "text": "Android 4.4"},
            ],
            listVerionsIOS: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "17.2", "text": "IOS 17.2"},
                { "value": "17.1", "text": "IOS 17.1"},
                { "value": "17", "text": "IOS 17"},
                { "value": "16", "text": "IOS 16"},
                { "value": "15", "text": "IOS 15"},
                { "value": "14", "text": "IOS 14"},
                { "value": "13", "text": "IOS 13"},
                { "value": "12", "text": "IOS 12"},
                { "value": "11", "text": "IOS 11"},
                { "value": "10.3.4", "text": "IOS 10.3.4"},
                { "value": "10.3.3", "text": "IOS 10.3.3"},
            ],
            listVerionsMacOS: [
                { "value": null, "text": 'Оберіть версію ОС', disabled: true },
                { "value": "sonoma", "text": "macOS Sonoma"}, 
                { "value": "ventura", "text": "macOS Ventura"}, 
                { "value": "monterey", "text": "macOS Monterey"}, 
                { "value": "bigsur", "text": "macOS Big Sur"}, 
                { "value": "catalina", "text": "macOS Catalina"}, 
                { "value": "mojave", "text": "macOS Mojave"}, 
                { "value": "highsierra", "text": "macOS High Sierra"}, 
            ],
            selectedApp: '',
            listApp: [
                { "value": null, "text": 'Застосунок для тестування', disabled: true },
                { "value": "appskyservice", "text": "Застосунок SkyService"}, 
                { "value": "browser", "text": "Браузер"}, 
            ],
            selectedBrowser: '',
            listBrowser: [
                { "value": null, "text": 'Виберіть браузер', disabled: true },
                { "value": "chrome", "text": "Google Chrome"}, 
                { "value": "safari", "text": "Safari"}, 
                { "value": "edge", "text": "Edge"}, 
                { "value": "firefox", "text": "Mozilla Firefox"}
            ],
            selectedVesAppBrow: '',
            listVerAppSkyWindows: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice21", "text": "SkyService 2.1"}, 
                { "value": "skyservice20", "text": "SkyService 2.0"}, 
                { "value": "skyservice19", "text": "SkyService 1.9"}, 
                { "value": "skyservice18", "text": "SkyService 1.8"}, 
                { "value": "skyservice17", "text": "SkyService 1.7"}, 
                { "value": "skyservice16", "text": "SkyService 1.6"}, 
            ],
            listVerAppSkyAndroid: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice13.8", "text": "SkyService 13.8"}, 
                { "value": "skyservice13.7", "text": "SkyService 13.7"}, 
                { "value": "skyservice13.6", "text": "SkyService 13.6"}, 
                { "value": "skyservice13.5", "text": "SkyService 13.5"}, 
                { "value": "skyservice13.4", "text": "SkyService 13.4"}, 
                { "value": "skyservice13.3", "text": "SkyService 13.3"}, 
                { "value": "skyservice13.2", "text": "SkyService 13.2"}, 
                { "value": "skyservice13.1", "text": "SkyService 13.1"}, 
                { "value": "skyservice13.0", "text": "SkyService 13.0"}, 
                { "value": "skyservice12.9", "text": "SkyService 12.9"}, 
                { "value": "skyservice12.8", "text": "SkyService 12.8"}, 
                { "value": "skyservice12.7", "text": "SkyService 12.7"}, 
                { "value": "skyservice12.6", "text": "SkyService 12.6"}, 
                { "value": "skyservice12.5", "text": "SkyService 12.5"}, 
                { "value": "skyservice12.4", "text": "SkyService 12.4"}, 
                { "value": "skyservice12.3", "text": "SkyService 12.3"}, 
                { "value": "skyservice12.2", "text": "SkyService 12.2"}, 
                { "value": "skyservice12.1", "text": "SkyService 12.1"}, 
                { "value": "skyservice12.0", "text": "SkyService 12.0"}, 
                { "value": "skyservice11.9", "text": "SkyService 11.9"}, 
                { "value": "skyservice11.8", "text": "SkyService 11.8"}, 
                { "value": "skyservice11.7", "text": "SkyService 11.7"}, 
                { "value": "skyservice11.6", "text": "SkyService 11.6"}, 
                { "value": "skyservice11.5", "text": "SkyService 11.5"}, 
                { "value": "skyservice11.4", "text": "SkyService 11.4"}, 
                { "value": "skyservice11.3", "text": "SkyService 11.3"}, 
                { "value": "skyservice11.2", "text": "SkyService 11.2"}, 
                { "value": "skyservice11.1", "text": "SkyService 11.1"}, 
                { "value": "skyservice11.0", "text": "SkyService 11.0"}, 
            ],
            listVerAppSkyIOS: [
                { "value": null, "text": 'Виберіть версію застосунку', disabled: true },
                { "value": "skyservice5.2", "text": "SkyService 5.2"}, 
                { "value": "skyservice5.1", "text": "SkyService 5.1"}, 
                { "value": "skyservice5.0", "text": "SkyService 5.0"}, 
                { "value": "skyservice4.3", "text": "SkyService 4.3"}, 
                { "value": "skyservice4.2", "text": "SkyService 4.2"}, 
                { "value": "skyservice4.1", "text": "SkyService 4.1"}, 
                { "value": "skyservice4.0", "text": "SkyService 4.0"}, 
                { "value": "skyservice3.2", "text": "SkyService 3.2"}, 
                { "value": "skyservice3.1", "text": "SkyService 3.1"}, 
                { "value": "skyservice3.0", "text": "SkyService 3.0"}, 
            ],
            listVerChrome: [
                { "value": null, "text": 'Виберіть версію браузера', disabled: true },
                { "value": "chrome120", "text": "Google Chrome 120"}, 
                { "value": "chrome119", "text": "Google Chrome 119"}, 
                { "value": "chrome118", "text": "Google Chrome 118"}, 
                { "value": "chrome117", "text": "Google Chrome 117"}, 
                { "value": "chrome116", "text": "Google Chrome 116"}, 
                { "value": "chrome115", "text": "Google Chrome 115"}, 
                { "value": "chrome114", "text": "Google Chrome 114"}, 
                { "value": "chrome113", "text": "Google Chrome 113"}, 
                { "value": "chrome112", "text": "Google Chrome 112"}, 
                { "value": "chrome111", "text": "Google Chrome 111"}, 
                { "value": "chrome110", "text": "Google Chrome 110"}, 
                { "value": "chrome109", "text": "Google Chrome 109"}, 
                { "value": "chrome108", "text": "Google Chrome 108"}, 
                { "value": "chrome107", "text": "Google Chrome 107"}, 
                { "value": "chrome106", "text": "Google Chrome 106"}, 
                { "value": "chrome105", "text": "Google Chrome 105"}, 
                { "value": "chrome104", "text": "Google Chrome 104"}, 
                { "value": "chrome103", "text": "Google Chrome 103"}, 
                { "value": "chrome102", "text": "Google Chrome 102"}, 
                { "value": "chrome101", "text": "Google Chrome 101"}, 
                { "value": "chrome100", "text": "Google Chrome 100"}, 
                { "value": "chrome99", "text": "Google Chrome 99"}, 
                { "value": "chrome98", "text": "Google Chrome 98"}, 
                { "value": "chrome97", "text": "Google Chrome 97"}, 
                { "value": "chrome96", "text": "Google Chrome 96"}, 
                { "value": "chrome95", "text": "Google Chrome 95"}, 
                { "value": "chrome94", "text": "Google Chrome 94"}, 
                { "value": "chrome92", "text": "Google Chrome 92"}, 
                { "value": "chrome85", "text": "Google Chrome 85"}, 
                { "value": "chrome81", "text": "Google Chrome 81"}, 
                { "value": "chrome80", "text": "Google Chrome 80"}, 
            ],
            listPriority: [
                {
                    "label": this.$t('priorityHigh'),
                    "value": "2"
                },
                {
                    "label": this.$t('priorityMedium'),
                    "value": "1"
                },
                {
                    "label": this.$t('priorityLow'),
                    "value": "0"
                }
            ],
            problemEnvironment: [
                {
                    "label": this.$t('App'),
                    "value": "App"
                },
                {
                    "label": 'Inside',
                    "value": "inside"
                },
                {
                    "label": this.$t('NewAdmin_panel'),
                    "value": "newDashboard"
                },
                {
                    "label": this.$t('admin_allappsSkymarket'),
                    "value": "admin_allappsSkymarket"
                },
                {
                    "label": this.$t('admin_allappsSellup'),
                    "value": "admin_allappsSellup"
                },
                {
                    "label": this.$t('admin_allappsHscreen'),
                    "value": "admin_allappsHscreen"
                },
                {
                    "label": this.$t('admin_allappsMono'),
                    "value": "admin_allappsMono"
                },
                {
                    "label": this.$t('admin_allappsPrivat'),
                    "value": "admin_allappsPrivat"
                },
                {
                    "label": this.$t('admin_shake_to_pay'),
                    "value": "admin_shake_to_pay"
                },
                {
                    "label": this.$t('admin_allappsBookBeauty'),
                    "value": "admin_allappsBookBeauty"
                },
                {
                    "label": this.$t('admin_sendcheck'),
                    "value": "admin_sendcheck"
                },
                {
                    "label": this.$t('admin_panel'),
                    "value": "admin_panel"
                },
                {
                    "label": this.$t('dir_pos'),
                    "value": "pos_terminal"
                },
                {
                    "label": this.$t('dir_posnew'),
                    "value": "posnew"
                },
                {
                    "label": this.$t('admin_allProducts'),
                    "value": "admin_allProducts"
                },
                {
                    "label": this.$t('admin_warehouseProducts'),
                    "value": "admin_warehouseProducts"
                },
                {
                    "label": this.$t('admin_services'),
                    "value": "admin_services"
                },
                {
                    "label": this.$t('admin_Onsale'),
                    "value": "admin_Onsale"
                },
                {
                    "label": this.$t('admin_remains'),
                    "value": "admin_remains"
                },
                {
                    "label": this.$t('admin_reportsMain'),
                    "value": "admin_reportsMain"
                },
                {
                    "label": this.$t('admin_reportsSale'),
                    "value": "admin_reportsSale"
                },
                {
                    "label": this.$t('admin_reportsComings'),
                    "value": "admin_reportsComings"
                },
                {
                    "label": this.$t('admin_reportsReturns'),
                    "value": "admin_reportsReturns"
                },
                {
                    "label": this.$t('admin_reportsMove'),
                    "value": "admin_reportsMove"
                },
                {
                    "label": this.$t('admin_reportsManufacture'),
                    "value": "admin_reportsManufacture"
                },
                {
                    "label": this.$t('admin_reportsOpenshifts'),
                    "value": "admin_reportsOpenshifts"
                },
                {
                    "label": this.$t('admin_reportsBonusreports'),
                    "value": "admin_reportsBonusreports"
                },
                {
                    "label": this.$t('admin_reportsGoodsconsumption'),
                    "value": "admin_reportsGoodsconsumption"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsTopsales'),
                    "value": "admin_reportsTopsales"
                },
                {
                    "label": this.$t('admin_reportsABCproducts'),
                    "value": "admin_reportsABCproducts"
                },
                {
                    "label": this.$t('admin_reportsABCcategories'),
                    "value": "admin_reportsABCcategories"
                },
                {
                    "label": this.$t('admin_reportsTopcomings'),
                    "value": "admin_reportsTopcomings"
                },
                {
                    "label": this.$t('admin_reportsTopclients'),
                    "value": "admin_reportsTopclients"
                },
                {
                    "label": this.$t('admin_reportsDays'),
                    "value": "admin_reportsDays"
                },
                {
                    "label": this.$t('admin_reportsDecommissionedgoods'),
                    "value": "admin_reportsDecommissionedgoods"
                },
                {
                    "label": this.$t('admin_reportsGoods'),
                    "value": "admin_reportsGoods"
                },
                {
                    "label": this.$t('admin_reportsGoodscategory'),
                    "value": "admin_reportsGoodscategory"
                },
                {
                    "label": this.$t('admin_reportsSalesdynamic'),
                    "value": "admin_reportsSalesdynamic"
                },
                {
                    "label": this.$t('admin_reportsRevenuereport'),
                    "value": "admin_reportsRevenuereport"
                },
                {
                    "label": this.$t('admin_reportsWorkshop'),
                    "value": "admin_reportsWorkshop"
                },
                {
                    "label": this.$t('admin_reportsEmployee'),
                    "value": "admin_reportsEmployee"
                },
                {
                    "label": this.$t('admin_reportsInventory'),
                    "value": "admin_reportsInventory"
                },
                {
                    "label": this.$t('admin_reportsDanger'),
                    "value": "admin_reportsDanger"
                },
                {
                    "label": this.$t('admin_reportsRecommendpurchase'),
                    "value": "admin_reportsRecommendpurchase"
                },
                {
                    "label": this.$t('admin_reportsTaxes'),
                    "value": "admin_reportsTaxes"
                },
                {
                    "label": this.$t('admin_reportsShifts'),
                    "value": "admin_reportsShifts"
                },
                {
                    "label": this.$t('admin_reportsWalletsstatus'),
                    "value": "admin_reportsWalletsstatus"
                },
                {
                    "label": this.$t('admin_reportsFin'),
                    "value": "admin_reportsFin"
                },
                {
                    "label": this.$t('admin_reportsCashboxallmotions'),
                    "value": "admin_reportsCashboxallmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinkmotions'),
                    "value": "admin_reportsCashboxinkmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxoutmotions'),
                    "value": "admin_reportsCashboxoutmotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxsalemotions'),
                    "value": "admin_reportsCashboxsalemotions"
                },
                {
                    "label": this.$t('admin_reportsCashboxinmotions'),
                    "value": "admin_reportsCashboxinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletallmotions'),
                    "value": "admin_reportsWalletallmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletinmotions'),
                    "value": "admin_reportsWalletinmotions"
                },
                {
                    "label": this.$t('admin_reportsWalletoutmotions'),
                    "value": "admin_reportsWalletoutmotions"
                },
                {
                    "label": this.$t('admin_marketingClients'),
                    "value": "admin_marketingClients"
                },
                {
                    "label": this.$t('admin_marketingOffers'),
                    "value": "admin_marketingOffers"
                },
                {
                    "label": this.$t('admin_marketingDiscount'),
                    "value": "admin_marketingDiscount"
                },
                {
                    "label": this.$t('admin_marketingStock'),
                    "value": "admin_marketingStock"
                },
                {
                    "label": this.$t('admin_marketingBonus'),
                    "value": "admin_marketingBonus"
                },
                {
                    "label": this.$t('admin_settingsglobal'),
                    "value": "admin_settingsglobal"
                },
                {
                    "label": this.$t('admin_settingstradepoints'),
                    "value": "admin_settingstradepoints"
                },
                {
                    "label": this.$t('admin_settingscategory'),
                    "value": "admin_settingscategory"
                },
                {
                    "label": this.$t('admin_settingsexpenses'),
                    "value": "admin_settingsexpenses"
                },
                {
                    "label": this.$t('admin_settingstax'),
                    "value": "admin_settingstax"
                },
                {
                    "label": this.$t('admin_settingsworkshop'),
                    "value": "admin_settingsworkshop"
                },
                {
                    "label": this.$t('admin_settingsnotices'),
                    "value": "admin_settingsnotices"
                },
                {
                    "label": this.$t('admin_settingshalls'),
                    "value": "admin_settingshalls"
                },
                {
                    "label": this.$t('admin_settingstables'),
                    "value": "admin_settingstables"
                },
                {
                    "label": this.$t('admin_settingsdevices'),
                    "value": "admin_settingsdevices"
                },
                {
                    "label": this.$t('admin_settingsusers'),
                    "value": "admin_settingsusers"
                },
                {
                    "label": this.$t('admin_settingsprovider'),
                    "value": "admin_settingsprovider"
                },
                {
                    "label": this.$t('admin_settingswallets'),
                    "value": "admin_settingswallets"
                },
                {
                    "label": this.$t('admin_settingsmybalance'),
                    "value": "admin_settingsmybalance"
                },
                {
                    "label": this.$t('admin_settingspartnersprogram'),
                    "value": "admin_settingspartnersprogram"
                },
                {
                    "label": this.$t('admin_help'),
                    "value": "admin_help"
                }
            ],
        }
    },
    created(){
        if(storeS.lang == 'uk'){
            this.config.locale = Ukrainian
        } else if(storeS.lang == 'ru'){
            this.config.locale = Russian
        }
    },
    methods: {
        add(){
            // Створення картки надходження
            
            // Застосовуємо план тестування
            if (this.form.testingPlan == 'checklist') {
                this.form.clauses = this.tempPlanData.checklist
            } else if (this.form.testingPlan == 'testcases') {
                this.form.clauses = this.tempPlanData.testcases
            } else {
                this.form.testingPlan = "withoutPlan"
            }

            console.log("form > ", this.form)

            // apiServe.createTesting(this.form).then(result => {
            //     if(result.status == 'done') {
            //         console.log('result', result);
            //         this.$toast.success(this.$t('сreatedT'));
            //         this.$emit('close')
            //     } else {
            //         console.log(result);
            //         this.switchError(result.error[0])
            //     }
            // })
        },
        switchError(error){
            // відображення помилки

            switch (error) {

                case "priority should be numeric":
                    this.$toast.error(this.$t('priorityShouldBeNumeric'))
                    break;
                case "testingPlan not found":
                    this.$toast.error(this.$t('TestingPlannotFound'))
                    break;
                case "data not found":
                    this.$toast.error(this.$t('dataNotFound'))
                    break;
            
                default:
                    this.$toast.error(error);
                    break;
            }
        },
        checkActBlocks(e){
            // Перевірка активних блоків

            var check = false
            this.activeBlocks.forEach(element => {
                if(e == element.type && element.status == true){
                    check = true
                }
            });

            return check
        },
        getBlocks(e){
            // Отримання активних блоків
            this.activeBlocks = e
        },
        addPrecondition(e, index) {;

            // Якщо індекс переданий (натиснуто "+", в конкретному пункті списку)
            if (index !== undefined) {
                // Додаємо порожній елемент після поточного індексу
                this.form.preconditions.splice(index + 1, 0, {
                    preconditionText: '', // Порожнє завдання
                });
            } else {
                // Якщо індекс не переданий (натиснуто "+", в полі вводу нового пункту)
                this.form.preconditions.push({
                    preconditionText: e.preconditionText, // Додаємо в кінець зі значенням
                });
                this.precondition.preconditionText = ''; // Очищаємо поле вводу
            }
        },
        removePrecondition(index){
            this.form.preconditions.splice(index, 1);
        },
        addCheckList(e, index) {;
            console.log("checklistItem", e);
            
            // Якщо індекс переданий (натиснуто "+", в конкретному пункті списку)
            if (index !== undefined) {
                // Додаємо порожній елемент після поточного індексу
                // this.form.clauses.splice(index + 1, 0, {
                this.tempPlanData.checklist.splice(index + 1, 0, {
                    name: '', // Порожнє завдання
                });
            } else {
                // Якщо індекс не переданий (натиснуто "+", в полі вводу нового пункту)
                this.tempPlanData.checklist.push({
                    name: e.name, // Додаємо в кінець зі значенням
                });
                this.checklistItem.name = ''; // Очищаємо поле вводу
            }
        },
        removeCheckList(index){
            this.tempPlanData.checklist.splice(index, 1);
        },
        addRow() {
            // Додаємо новий рядок в таблицю
            this.tempPlanData.testcases.push({
                name: '',
                testData: '',
                expectedResult: ''
            });
            this.nextId++;
        },
        addRowBelow(rowIndex) {
            // Метод для додавання нового рядка нижче активного
            this.tempPlanData.testcases.splice(rowIndex + 1, 0, {
                name: '',
                testData: '',
                expectedResult: ''
            });

            // Перевести фокус на новий рядок
            this.$nextTick(() => {
                this.$refs[`field-${rowIndex + 1}`][0].focus(); // Фокус на перше поле нового рядка
            });
        },
        removeRow(index){
            // Видалення строчки тест-кейсу
            this.tempPlanData.testcases.splice(index, 1);
        },
        addPlatform(){
            // додати платформу тестування

            var tempForm = {
                osName: this.selectedOS,
                osVersion: this.selectedVerOS,
                testingApplication: this.selectedApp
            }

            if (this.selectedBrowser) {
                tempForm.browser = this.selectedBrowser;
            }
            if (this.selectedVesAppBrow && this.testingApplication == 'browser') {
                tempForm.browserVersion = this.selectedVesAppBrow;
            }
            if (this.selectedVesAppBrow && this.testingApplication != 'browser') {
                tempForm.applicationVersion = this.selectedVesAppBrow;
            }

            this.form.environments.push(tempForm)
            console.log("environment", this.form.environments);
        },
        removePlatform(index){
            this.form.environments.splice(index, 1)
        },
        uploadFiles(obj, formData) {
            // завантаження файлів до картки тестування

            apiServe.uploadFiles(this.form.testingUuid, formData).then(result => {
                if(result.status == 'done'){
                    console.log("result > ",result);
                    this.files = result.data.files
                    this.form.testingUuid = result.data.testingUuid
                } else {
                    console.log(result)
                }
            })
        },
        downloadFile(e){
            // завантажити файл на пристрій
            apiServe.getFile(e.fileId)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', e.name);
                    document.body.appendChild(link);
                    link.click();
                    storeS.uploadPercentage = 0;
                })
                .catch((error) => console.log(error));
        },
        deleteFile(e){
            // Видалення файлу

            apiServe.deleteFile(e.fileId).then(result => {

                if(result.status == 'done') {
                    // this.form.opportunities = result.data;
                    this.files = result.data.files;
                    this.$toast.success(this.$t("Removed"))
                } else {
                    
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    // sendTelegramMessage
                    var errorText = 'Помилка видалення файлу при створені картки на тестування'; 
                    var statusRequest = result.status;
                    var api = 'deleteFile';
                    var fileRequest = 'src/views/software-testing/created_testing/index.vue';
                    var params = {
                        id: e.fileId
                    };
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }

            })

        },
        viewFile(e) {
            // Перегляд файлу

            if(e.type == 'image/jpeg' || e.type == 'image/png' || e.type == 'image/heic' || e.type == 'video/quicktime' || e.type == 'video/mp4'){
                // this.$emit('getFile', e)

                var id = e.fileId ? e.fileId : e;
                this.ifPrevimg = id == this.files[0].fileId ? false : true; // чи показувати стрілку на попередній док
                this.ifNextimg = id == this.files[this.files.length-1].fileId ? false : true; // чи показувати стрілку на наступний док
                
                if(storeS.blob != undefined){
                    var foundName = 'urlFrom' + this.form.testingUuid + "fileId" + id;
                    var urlCache = ''
                    var statusCache = this.urlCaches.some(function(e){ 
                        urlCache = e.url
                        return e.name == foundName;
                    });
                    if(statusCache){
                        console.log(urlCache);
                        // якщо відео є в локалстор
                        this.gFile.fileId = id;
                        this.gFile.src = urlCache;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name
                        this.showImg = true;
                    } else{
                        // якщо відео немає в локалстор - додаємо
                        apiServe.getFile(e.fileId).then(result => {

                            var blob = new Blob([result.data])
                            const url = window.URL.createObjectURL(blob);

                            let opUrl = {
                                name: 'urlFrom' + this.deadlineId + "fileId" + id,
                                url: url
                            }

                            this.urlCaches.push(opUrl)
                            this.saveUrlFile(this.urlCaches);

                            this.gFile.fileId = id;
                            this.gFile.src = url;
                            this.gFile.type = e.type;
                            this.gFile.name = e.name

                            this.showImg = true;
                            storeS.uploadPercentage = 0;

                        })
                    }

                } else {
                   apiServe.getFile(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);

                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })
                }

            } else {
                // this.$toast.error(this.$t('fileNotSupported'));
                this.downloadFile(e)
            }
        },
        saveUrlFile(e){
            // Збереження файлу в локал
            storeS.blob = e;
        },
        changePlanTesting(plan){
            // змінення плану тестування

            if (this.form.testingPlan === plan) return; // Якщо план уже активний, нічого не робимо

            // Встановити новий активний план
            this.form.testingPlan = plan;

            if (plan === 'testcases' && this.tempPlanData.testcases.length < 1) {
                // Додати порожній тест-кейс, якщо вибрано "Тест-кейси"
                this.tempPlanData.testcases.push({ name: '', testData: '', expectedResult: '' });
            }

            if (plan === 'checklist') {
                // Додати порожній пункт до чек-листа, якщо вибрано "Чек-лист"
                // this.tempPlanData.checklist.push({ name: '' });
            }

        }
        // handleKeyPress(event) {
        //     // Якщо натискається Enter
        //     if (event.key === 'Enter') {
        //         this.addRow(); // Додаємо новий рядок
        //     }
        // }
    },
    mounted() {
        // Додаємо слухача подій на клавіатуру при завантаженні компонента
        window.addEventListener('keydown', this.handleKeyPress);
    },
    beforeDestroy() {
        // Видаляємо слухача подій перед знищенням компонента
        window.removeEventListener('keydown', this.handleKeyPress);
    }
}
</script>

<style scoped>
.header-line-container {
    display: flex;
    align-items: center;
}

.header-line-container h6 {
    margin: 0;
    padding-right: 10px; /* Відступ між заголовком і лінією */
}

.header-line-container hr {
    flex-grow: 1;
    border: none;
    /* border-top: 2px solid #000; /* Можна змінити товщину та колір лінії */
    margin: 0;
}
.disabledBlockActive{
    width: 100%;
    height: 100%;
    background: hsl(0deg 0% 84.79% / 65%);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.input-wrapper {
  position: relative;
  display: inline-block;
}

input.with-icon {
  padding-right: 24px; /* Додаємо відступ для іконки */
}

.edit-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: gray; /* Колір іконки */
}
</style>