<template>
    <!-- Модальне вікно для додавання та редагування тегів -->

    <modal>
        <!-- Заголовок модального вікна -->
        <template v-slot:title>{{ $t('addLabel') }}</template>

        <!-- Тіло модального вікна з формою для введення даних про тег -->
        <template v-slot:body>
            <div class="border-bottom mb-3 pb-3">
                <b-row>
                    <b-col lg="12">
                        <div class="rounded text-white text-center p-3" :style="`background-color: ${form.color};`">
                            <!-- Заголовок тегу, який можна редагувати подвійним кліком -->
                            <span @dblclick="editTitle = true" class="fs-16 fw-semibold">{{ form.tagName }}</span>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <!-- Карточка з полем введення для назви тегу -->
            <b-card>
                <label class="fs-15"><b>{{ $t('Point_name') }}</b></label>
                <input class="form-control" v-model="form.tagName" />
            </b-card>

            <!-- Карточка зі списком кольорів для вибору фону тегу -->
            <b-card>
                <b-row>
                    <template v-for="item in colorsNew" :key="item">
                        <b-col lg="2" class="mb-2 copy" @click="form.color = item.color">
                            <div class="avatar-sm" >
                                <div class="avatar-title rounded text-white" :style="`background-color: ${item.color};`"></div>
                            </div>
                        </b-col>
                    </template>
                </b-row>
            </b-card>
        </template>
        <template v-slot:footer-bottom>
            <div class="hstack gap-2 mt-3 justify-content-center remove">

                <!-- Кнопка "Скасувати", видима при додаванні нового тегу -->
                <button 
                    v-if="!item" 
                    class="btn btn-danger" 
                    @click="$emit('close')"
                >
                    {{ $t('cancel') }}
                </button>

                <!-- Кнопка "Додати", видима при додаванні нового тегу -->
                <button 
                    v-if="!item" 
                    class="btn btn-success" 
                    @click="add"
                >
                    {{ $t('Add') }}
                </button>

                <!-- Кнопка "Видалити", видима при редагуванні існуючого тегу -->
                <button 
                    v-if="item" 
                    class="btn btn-danger" 
                    @click="remove"
                >
                    {{ $t('Delete') }}
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/dialog'
import { HashTags } from '@/API'

let apiServe = new HashTags();

export default{
    props: ['item', 'typeTags'],
    components: {
        modal
    },
    data(){
        return{
            
        }
    },
    created(){
        console.log('d', this);
    },
    methods: {
        
    }
}
</script>