<template>
    <!-- Модалка перегляду тікету -->
    <modal :key="componentKey" :title="$t('ticket')" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" :stylebody="'var(--vz-body-bg)'" @close="close">
        <template v-slot:head-bottom>
            <!-- 
                Компонент 'шапки' тікету
                    :obj - пропс даних тікетів
                    @close - дія, при закритті тікета
                    @opensmsbox - відкриття вікна смс
                    @openapplicbox - відкриття вікна заявки
                    @opentaskBox - відкриття вікна таски
                    @openticketBox - відкриття вікна тікету
                    @openreportBox - відкриття вікна репорту
                    @edit - відкриття редагування тікету
                    @getUpdates - отримання дедлайну
                    @addReminder - дія, для додавання нагадування 
            -->
            <headmodal 
                :obj="this.form" 
                @close="close" 
                @opensmsbox="opensmsbox" 
                @openapplicbox="openapplicbox" 
                @opentaskBox="opentaskBox" 
                @openticketBox="openticketBox" 
                @openreportBox="openreportBox" 
                @edit="editTicket"
                @getUpdates = "getUpdates" 
                @addReminder="addReminder"
                @kickUserReq="this.showModalAccept = true"
            />
        </template>
        <template v-slot:body>
            <!-- <topblock :obj="this.form" /> -->
            <b-row>
                
                <b-col
                lg="3">
                    <!--
                        Правий блок
                            :viewRepBox - подивитися вікно репорту
                            :obj - пропс даних тікетів 
                            @openreport - дія, відкривання репорту
                            @showteams - дія для показу команди
                            @openclient - дія, щоб відкрити клієнта
                            @editnum - дія, щоб редагувати номер
                            @opendevice - дія, щоб відкрити девайс
                    -->
                    <rightblock 
                        v-if="updateForm"
                        :viewRepBox="viewRepBox"
                        :obj="this.form" 
                        @openreport="openreport" 
                        @showteams="showteams" 
                        @openclient="openclient" 
                        @editnum="editnum"
                        @opendevice="opendevice"
                        @updateCard = "this.updateCard()"
                    />
                </b-col>
                <b-col
                lg="9">
                    <!--
                        Лівий блок

                            :obj - пропс даних тікетів 
                            :activeTab - пропс активного табу
                            @openclient - дія, щоб відкрити клієнта 
                            @inRelease - дія, що відповідає за підтвердження тестінгу
                            @showReopen - дія, що показує модалку перевідкриття тікету
                            @showClose - дія, що показує модалку закриття тікету
                            @updateCard - дія, що викликає апдейт картки
                    -->
                    <leftblock 
                        :obj="this.form" 
                        :activeTab="activeTab"
                        :messages="this.messages"
                        @openclient="openclient" 
                        @inRelease = 'this.showConfirmTesting = true'
                        @showReopen = 'this.showModalReopen = true'
                        @showClose = 'this.showModalClose = true'
                        @updateCard = "this.updateCard()"
                    />
                </b-col>
            </b-row>
        </template>

        <template v-slot:footer-bottom>
            
            <!-- 
                кнопка "Приєднатися" до тікету 
                - надає змогу спостерігати за тікетом але не виконувати його. Будуть прхиодити по ньому сповіщення, а користувач буде відображатися в призначенних
            -->
            <b-button 
                v-if="
                        !this.form.assignedWorkers.find(el => el.workerId == this.user.userid) 
                        && !this.form.inJobWorkers.find(el => el.workerId == this.user.userid)
                    "
                @click="joinToTicket" 
                variant="warning" 
                lass="waves-effect waves-light" 
            >
                    {{ this.$t('join') }}
            </b-button>
            
            <!-- 
                кнопка "Видалити" 
                - надає змогу видалити тікет
                - йде перевірка на права доступу під номером 303 (видалити тікет) та перевірка, щоб статус тікету не був "видалений" або "закритий тікет"
            -->
            <b-button 
                v-if="
                        perms[303] == true 
                        && (this.form.status !== 'deleted' || this.form.status !== 'closed')
                    " 
                @click="deleteModal = true" 
                variant="danger" 
                class="waves-effect waves-light"
            >
                <i class="ri-delete-bin-line align-bottom me-1"></i>{{ $t('Delete') }}
            </b-button>

            <!-- <b-button v-if="this.checks['026'] == true && this.form.status=='new'"  variant="success" class="waves-effect waves-light" @click="addReminder">{{ $t('AddReminderInCalendar') }}</b-button> -->
            <!-- <b-button v-if="perms[303] == true && form.status == 'new'" variant="info" class="waves-effect waves-light">{{ $t('postpone') }}</b-button> -->


            <!-- 
                кнопка "Додати рішення" 
                - відкривається модальне вікно, де можна обрати статус рішення тікета, та вказати коментар
                - йде перевірка на права доступу під номером 302 (взяти в роботу тікет) та 2000 (режим бога) 
                - йде перевірка на статус, щоб статус тікету був "в роботі" або "перевідкритий"
                - йде перевірка на те, чи є в списку "хто взяв в роботу" користувач який відкриває картку тікета
                - йде перевірка на те, чи вирішенні всі сабтаски
            -->
            <b-button 
                v-if="
                        (
                            (perms[302] == true || perms[2000] == true) 
                            && (form.status == 'jobs' || form.status == 'restored') 
                            && (
                                form.inJobWorkers.find(el => el.workerId == this.user.userid)
                            )
                            && (this.form.tasks.filter(el => el.checked == 0)).length == 0
                        )
                    " 
                @click="this.modalDialog = true" 
                variant="success" 
                class="waves-effect waves-light"
            >
                {{ $t('AddSolution') }}
            </b-button>

            <!-- 
                кнопка "Взяти в роботу" 
                - надає змогу взяти тікет в роботу, та потім по ньому надати рішення
                - йде перевірка на права доступу під номером 302 (взяти в роботу тікет) та 2000 (режим бога) 
                - йде перевірка на статус, щоб статус тікету був "новий", або "очікує на взяття в роботу", або "переглянуто", або "перевідкрито"
                - йде перевірка на те, чи в списку "призначені" немає користувача який відкриває картку тікета
            -->
            <b-button 
                v-if="
                        (perms[302] == true || perms[2000] == true) 
                        && (
                                (
                                    (
                                        form.status == 'new' || 
                                        (form.status == 'wait_jobs') || 
                                        form.status == 'viewed' || form.status == 'restored'
                                    ) && 
                                    (
                                        !this.form.assignedWorkers.find(el => el.workerId == this.user.userid)
                                    )
                                ) || (
                                    this.form.assignedWorkers.find(el => el.workerId == this.user.userid) &&
                                    this.form.inJobWorkers.length == 0
                                )
                            )
                    " 
                    @click="toWork(form)" 
                    variant="secondary" 
                    class="waves-effect waves-light"
            >
                {{ $t('takeToWork') }}
            </b-button>

            
            <!-- Кнопка закриття тикета, доступна якщо користувач є автором тикета та має відповідні права -->
            <b-button v-if="((perms[301] == true) && form.createWorkerId == this.user.userid && (form.status == 'decided' || form.status == 'restored')) || perms[2000] == true" @click="showModalClose = true" variant="success" class="waves-effect waves-light">{{ $t('closeTicket') }}</b-button>

            <!-- Кнопкаreopen тикета, доступна якщо користувач є автором тикета та має відповідні права -->
            <b-button v-if="(perms[301] == true || perms[2000] == true) && form.createWorkerId == this.user.userid && form.status == 'decided'" @click="showModalReopen = true" variant="warning" class="waves-effect waves-light">{{ $t('ReopenTicket') }}</b-button>

            <!-- Кнопка відновлення тикета, доступна якщо користувач має відповідні права та тикет знаходиться в статусі "deleted" або "closed" -->
            <b-button v-if="(perms[303] == true || perms[2000] == true) && (form.status == 'deleted' || this.form.status == 'closed')" @click="toRecover(form)" variant="secondary" class="waves-effect waves-light">{{ $t('Restore') }}</b-button>

            <!-- Кнопка виходу з тикета, доступна якщо користувач є виконавцем тикета або приєднаний до тикета -->
            <b-button v-if="(this.form.inJobWorkers[0]?.workerId == this.user.userid && (this.form.status == 'jobs' || this.form.status == 'wait_jobs')) || this.form.assignedWorkers.find(el => el.workerId == this.user.userid)" @click="!this.form.assignedWorkers.find(el => el.workerId == this.user.userid) ? leaveFromTicket(form) : exitTicket(form)" variant="secondary" class="waves-effect waves-light">{{ $t('leaveFromTicket') }}</b-button>

            <!-- Кнопка зупинки тикета, доступна якщо користувач є виконавцем тикета та тикет знаходиться в статусі "jobs" -->
            <b-button v-if="this.form.inJobWorkers.length > 0 && this.form.inJobWorkers[0].workerId == this.user.userid && this.form.status == 'jobs'" @click="stopTheTicket(form)" variant="danger" class="waves-effect waves-light">{{ $t('stopTicket') }}</b-button>

            <!-- Кнопка відновлення тикета, доступна якщо користувач є виконавцем тикета та тикет знаходиться в статусі "stop" -->
            <b-button v-if="this.form.inJobWorkers.length > 0 && this.form.inJobWorkers[0].workerId == this.user.userid && this.form.status == 'stop'" @click="continueTheTicket(form)" variant="secondary" class="waves-effect waves-light">{{ $t('resumeTicketStatus') }}</b-button>
        </template>
    </modal>

    <preloader v-if="this.show_in_preloader" :show_in_preloader="loader" />

    <!-- 
        Відкрити картку репорта
            @close - дія, що закриває модалку перегляду репорту
            @removeReport - дія, для видалення репорту
            :obj - пропс, що передає інфу по репорту
    -->
    <viewreport 
        v-if="showModalReport == true" 
        @close="showModalReport = false" 
        @removeReport="removeReport"
        :obj="this.info_report" 
    ></viewreport>
    <!-- Відкрити картку репорта -->

    <!-- 
        Відкрити команду 
            @close - дія, що закриває модалку перегляду репорту 
            @addTeam - дія, що додає тімейтів в команду
    -->
    <viewteams 
        v-if="showModalTeam == true" 
        @close="showModalTeam = false" 
        @addTeam="addTeam"
        >
    </viewteams>
    <!-- Відкрити команду -->

    <!-- карточка клиента -->
    <!-- <viewclient v-if="showModalClient == true" @close="showModalClient = false" @openTicket="this.getData()" :emp_phone="this.emp_number" :emp_user="this.user_name" :obj="this.client.data"></viewclient> -->
    <!-- карточка клиента -->
    <viewclient 
        v-if="showModalClient == true" 
        @close="showModalClient = false" 
        @call = "call"
        @openTicket="this.getData()"
        :objcard="this.objCard" 
    ></viewclient>

    <createapplicBox v-if="modalReqShow" @close="modalReqShow = false" ></createapplicBox>
    <createsmsBox v-if="modalSmsbox" @close="modalSmsbox = false" @sendSMS="sendSMS"></createsmsBox>
    <createTaskBox v-if="modalTaskBox" @close="modalTaskBox = false"></createTaskBox>
    <createTicketBox v-if="modalTicketBox" @close="modalTicketBox = false"></createTicketBox>
    <createReport v-if="modalReportBox" @close="modalReportBox = false" :id="form.ticketId"></createReport>

    <modalImg v-if="fullImageShow" @close="fullImageShow = false">
        <template v-slot:body>
            <img :src="this.fullImageUrl" style="">
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile($event, 'img')" ><i class="ri-download-cloud-2-fill"></i></button>
        </template>
    </modalImg>

    <modalDec 
        v-if="modalDialog == true"
        @close="modalDialog = false"
        :id="this.form.ticketId"
        :deadlines = "this.updateslist"
        :typeTicket = "this.form.type"
    />

    <dialogdeleteVue 
        v-if="this.deleteModal===true" 
        @close="deleteModal = false"
        @remove="remove()"
    ></dialogdeleteVue>

    <modalClose 
        v-if="showModalClose == true"
        @close="showModalClose = false"
        @toClose="close"
        :id="this.form.ticketId"
    />

    <modalReopen 
        v-if="showModalReopen == true"
        @close="showModalReopen = false"
        :id="this.form.ticketId"
    />

     <!-- редагування дедлайну -->
     <editDeadline
        v-if="this.modalEdit == true"
        @close = "this.modalEdit = false"
        :deadlines = "this.updateslist"
        :obj = "this.form"
    ></editDeadline>
    <!-- редагування дедлайну -->

    <!-- <dialogBox v-if="modalEdit == true" @close="modalEdit = false">
        <template v-slot:title>{{ $t('confirmation') }}</template>
        <template v-slot:body>
            <div class="mb-3">
                <label class="form-label">{{ $t('version') }}</label>
                <select
                    class="form-select mb-3"
                    data-plugin="choices"
                    v-model="form.id_deadline"
                    >
                    <option v-for="item in updateslist" :key="item" :value="item.id">{{ item.version }}</option>
                </select>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <div style="margin: auto;">
                <a class="btn btn-link link-success fw-medium" data-bs-dismiss="modal" @click="modalEdit = false"><i class="ri-close-line me-1 align-middle"></i> {{ $t('cancel') }} </a>
                <button 
                    type="button" 
                    class="btn btn-primary" 
                    @click="toedit(form)"
                >
                    {{ $t('Edit') }}
                </button>
            </div>
        </template>
    </dialogBox> -->

    <device 
        v-if="modaldevice" 
        @close="modaldevice = false"
        @addDeivce="addDeivce"
        @editDeivce="editDeivce"
        @removeDeivce="removeDeivce"
        :itemdevice="itemdevice"
        :editform="false"
    />

    <addEvent
        v-if="showAddEvent"
        @close="showAddEvent = false"
        :crItem="crItem"
        @updateCard="updateCard"
    />

    <!-- <modal v-if="modaldevice" @close="modaldevice = false">
        <template v-slot:head-bottom>
            <div class="" >
                <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="modaldevice = false"><i class="ri-close-fill align-bottom"></i></button>
            </div>
        </template>
        <template v-slot:body>
            <viewbox :obj="this.itemdevice" :editform="this.false"  />
        </template>
    </modal> -->
    <acceptSMS v-if="showAcceptSMS == true" @close = "showAcceptSMS = false" :obj="this.formForSMS"></acceptSMS>

    <modalAccept 
       v-if="this.showModalAccept === true"
       :id="this.form.ticketId"
       @close="this.showModalAccept = false"
       @kickUser="kickUser" 
    />

    <dialogBox v-if="showConfirmTesting" @close="showConfirmTesting = false">
        <template v-slot:title>{{ $t('Confirm') }}</template>
        <template v-slot:body>
            <div class="mt-4 text-center">
                <h3><b>{{ $t('confirmDecTicketTest') }}</b></h3>
                <p class="text-muted fs-15 mb-4">{{ $t('confirmDecTicketTestdesc') }}</p>
            </div>
        </template>
        <template v-slot:footer-bottom>
            <button class="btn link-danger fw-medium"  @click="showConfirmTesting = false"><i class="ri-close-line me-1 align-middle"></i> {{ $t('n') }} </button>
            <button 
                type="button" 
                class="btn btn-success" 
                @click="inRelease"
            >
                {{ $t('y') }}
            </button>
        </template>
    </dialogBox>
</template>

<script>
import editDeadline from './actions/editDeadline'
import modal from '@/components/modal'
import dialogBox from '@/components/modal/dialog'
import headmodal from './headmodal'
import topblock from './topblock'
import leftblock from './formleft/leftblock'
import rightblock from './formright/rightblock'
import viewreport from './formright/reports/view'
import viewteams from '@/views/users/modalTeam.vue'
// import viewclient from '@/views/clientnew/index.vue'
import viewclient from '@/views/accounts/allAccounts/viewnew/index.vue'
import createapplicBox from '@/views/application/createapplic.vue'
import createsmsBox from '@/views/application/createsms.vue'
import createTaskBox from '@/views/tasks/createtask.vue'
import createTicketBox from '@/views/newtickets/createticket.vue'
import createReport from '@/views/newtickets/view/formright/reports/createReport'
import device from '@/components/ticketdeviceblock/index.vue'
import modallg from '@/components/modal/modal-lg'
import viewbox from '@/views/clientnew/blocks/activity/devices/view'
import preloader from "@/components/preloader";
import modalDec from './actions/decision'
import modalClose from './actions/closeTicket'
import modalReopen from './actions/reopenTicket'
import modalImg from '@/components/modal/img'
import { SuperAdmin, ReportsTickets, Tickets, Updates } from '@/API.js';
import { storeS } from '@/store'
import dialogdeleteVue from '@/components/globaltable/actions/dialogdelete.vue'
import addEvent from '@/components/calendar/addEvent'
import acceptSMSVue from '@/views/application/acceptSMS.vue';
import modalAccept from "./actions/kickUser.vue"
import { sendTelegramLogKickUser, sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiClients = new SuperAdmin();
let apiServe = new ReportsTickets();
let apiTickets = new Tickets();
let apiUpdates = new Updates();

export default{
    props: ["obj", 'ifPrevopen', 'ifNextopen', 'activeTab', 'closeOne', 'messages'],
    components: { 
        editDeadline,
        modal, 
        dialogBox,
        addEvent,
        headmodal, 
        topblock, 
        leftblock, 
        dialogdeleteVue,
        rightblock, 
        viewreport, 
        viewteams, 
        viewclient,
        createapplicBox, 
        createsmsBox, 
        createTaskBox, 
        createTicketBox,
        createReport,
        device,
        viewbox,
        modallg,
        preloader,
        modalDec,
        modalClose,
        modalReopen,
        acceptSMSVue,
        modalImg,
        modalAccept
    },
    data(){
        return{
            showModalAccept: false,
            fullImageShow: false,
            fullImageUrl: '',
            showConfirmTesting: false,
            formForSMS: '',
            showAcceptSMS: false,
            updateForm: true,
            showAddEvent: false,
            crItem: {
                id: '',
                type: 'ticket',
                name: '',
                description: '',
                date: '',
                time: ''
            },
            viewRepBox: true,
            showModalClose: false,
            showModalReopen: false,
            form: {},
            deleteModal: false,
            show_in_preloader: false,
            checked: false,
            info_report:"",
            showModalReport: false,
            showModalTeam: false,
            showModalClient: false,
            modalSmsbox: false,
            modalReqShow: false,
            modalTaskBox: false,
            modalTicketBox: false,
            modalReportBox: false,
            modalDialog: false,
            modalEdit: false,
            modaldevice: false,
            itemdevice:"",
            bugsreport:"",
            user_name: "",
            emp_number: "",
            number: '',
            client: {
                data: null
            },
            objCard: '',
            requestform: {},
            updateslist: {},
            componentKey: '',
            openUrl: false
        }
    },
    created() {
        console.log('messages', this.messages)
        this.getData();
        storeS.checkModal.type = 'ticket';
        // storeS.checkModal.id = this.form.ticketId;
        // this.getUpdates()
        
        this.eventBus.off('fullImageSize')
        this.eventBus.on('fullImageSize', (status) => {
            this.fullImageUrl = status.url
            this.fullImageShow = true
        })

        this.eventBus.off('getMessagesWithScrollPos');
        this.eventBus.on('getMessagesWithScrollPos', (status) => {
            console.log('getMessagesWithScrollPos')
            this.$emit('getDataMessages')
        })
    },
    methods:{
        kickUser(id) {
            let workerName = this.form.inJobWorkers[0].workerName
            apiTickets.kickUser(id).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('success'))
                    this.updateCard();
                    
                    var ticketId = this.form.ticketId;
                    var userId = this.user.userid;
                    var userName = this.user.name;
                    var kickedUserId = workerName;

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramLogKickUser(ticketId, userName, userId, kickedUserId)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                } else {
                    this.$toast.error('error')

                    var errorText = 'Помилка виключення користувача з тікету'; 
                    var statusRequest = "error";
                    var api = 'kickUser';
                    var fileRequest = 'src/views/newtickets/view/index.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }, 
        exitTicket(e) {
            apiTickets.exitTicket(e.ticketId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('leaved'))
                    this.updateCard();
                } else {
                    this.$toast.error('error')
                }
            })
        },
        joinToTicket() {
            apiTickets.addTeammates(this.form.ticketId, {workers: [this.user.userid]}).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('joined'))
                    this.updateCard()
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        inRelease() {
            apiTickets.ticketInRelease(this.form.ticketId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('success'))
                    this.showConfirmTesting = false;
                    this.updateCard();
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        leaveFromTicket(e) {
            apiTickets.leaveFromTicket(e.ticketId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('leaved'))
                    this.updateCard();
                } else {
                    this.$toast.error('error')
                }
            })
        },
        stopTheTicket(e) {
            apiTickets.stopTicket(e.ticketId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('stoped'))
                    this.updateCard();
                } else {
                    this.$toast.error('error')
                }
            })
        },
        continueTheTicket(e) {
            apiTickets.continueTicket(e.ticketId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('continued'))
                    this.updateCard();
                } else {
                    this.$toast.error('error')
                }
            })
        },
        toRecover(e) {
            apiTickets.recoverTicket(e.ticketId).then(result => {
                if(result.status == 'done') {
                    this.$toast.success(this.$t('Restored'))
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })
        },
        sendSMS(e){
            this.formForSMS = e 
            this.showAcceptSMS = true
        },
        getUpdates(a){
            apiUpdates.getAllUpdates({pagelimit: 100}).then(result => {
                if(result.status === 'done'){
                    this.updateslist = result.data.items
                    if(a) {
                        this.modalEdit = true
                    }
                }
            })
        },
        addReminder(){
            this.crItem.id = this.form.ticketId;
            this.crItem.name = this.$t('ticket');
            this.crItem.description = this.filterText(this.form.createComment);
            this.showAddEvent = true;
        },
        filterText(e) {
            return e.replace(/<font[^>]*>/g,'<p>').replace(/<\/font>/g,'</p>');
        },
        getData(){
            this.form = this.obj;
        },
        toedit(e){
            this.$emit('toedit', e)
            this.modalEdit = false
        },
        close(){
            if(this.openUrl == false){
                this.showModalClose == true ? false : ''
                storeS.checkModal.type = '';
                storeS.checkModal.id = '';
                // this.$emit('getdata');
                this.$emit('close')
            } else {
                console.log("dsdsd");
                this.showModalClose == true ? false : ''
                this.$router.push('/newtickets/all'); // перехід до /newtickets/all
            }
        },
        prevOpen(){
            this.$emit('close');
            this.$emit('prevOpen', this.form.ticketId)
        },
        nextOpen(){
            this.$emit('close');
            this.$emit('nextOpen', this.form.ticketId)
        },
        openapplicbox: function(){
            this.modalReqShow = true
        },
        opentaskBox: function(){
            this.modalTaskBox = true
        },
        openticketBox: function(){
            this.modalTicketBox = true
        },
        openservicebox: function(){
            this.serviceShow = true
        },
        openreportBox: function(){
            this.modalReportBox = true
        },
        openreport: function(e){
            this.show_in_preloader = true;
            apiServe.getReport(e.reportId).then(result => {
                this.show_in_preloader = false;
                if(result.status == 'done'){
                    this.info_report = result.data;
                    this.showModalReport = true
                }
            })
        },
        opendevice: function(e){
            this.itemdevice = e
            this.modaldevice = true
        },
        toWork(){
            apiTickets.inJobTicket(this.form.ticketId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('TakentoWork'));
                    this.updateCard();
                } else {
                    this.$toast.error(`error`);
                }
            })
        },
        editTicket(e){
            this.$emit('edit', e)
        },
        remove(){
            apiTickets.deleteTicket(this.form.ticketId).then(result => {
                if(result.status === 'done'){
                    this.$toast.success(this.$t('Removed'));
                    this.$emit('close')
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        showteams: function(){
            this.showModalTeam = true
        },
        openclient: function(e){
            this.getClient(e)
        },
        editnum: function(e){
            apiTickets.editTicket(e.id, e).then(res =>{
                if(res){
                    this.$toast.success(this.$t('alertChanged'));
                }
            })
        },
        getClient(num){
            // додаємо можливість пошуку, якщо номер вказаний без 38
            var customNumber = num.toString();
            var numcode = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "091", "092", "089", "094"]
            for(var item in numcode) {
              if(customNumber.substr(0, 3) == numcode[item] ) {
                customNumber = "38" + num
              }
            }

            this.number = customNumber
            this.eventBus.emit('getClient', {status: true, number: this.number})


            // apiClients.getClient(customNumber).then(res => {
            //     if(res.status == "done"){
            //         this.client.data = res.data;
            //             if(customNumber != this.client.data.user_login) {
            //                 this.client.data.emp_phone = customNumber;
            //                 for(var item in this.client.data.users) {
            //                     if(this.client.data.users[item].user_phone == customNumber) {
            //                     this.user_name = this.client.data.users[item].user_name
            //                     }
            //                 }
            //                 this.client.data.emp_username = this.user_name;
            //                 this.$toast.success(this.$t('Employee') + ` - ` + customNumber);
            //             }
            //             this.showModalClient = true
            //             this.$toast.success(this.$t('Account') + ` - ` + this.client.data.user_login);
            //         } else{
            //             this.$toast.error(this.$t('notFoundClient') + ` - ` + this.emp_number);
            //         }
            //     })
        },
        updateCard(){
            // componentKey
            apiTickets.getTicket(this.form.ticketId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data
                    this.componentKey += 1
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        downloadFile(event, name){
            event.preventDefault(); // запобігаємо переходу за посиланням
            let base64ImageSrc = this.fullImageUrl; // значення зображення base64
            let base64ImageParts = base64ImageSrc.split(','); //розбиваємо на дві частини
            let base64ContentType = base64ImageParts[0]; // 1 - інформація про тип файлу та кодування
            let base64Content = base64ImageParts[1]; // 2 - міститиме сам base64
            let prefix = base64ImageSrc.substring(0, 20);
            let format; //формат зображення
            switch (true) {
                case prefix.includes('data:image/png'):
                    format = 'png';
                break;
                case prefix.includes('data:image/jpeg') || prefix.includes('data:image/jpg'):
                    format = 'jpeg';
                break;
            
                default:
                    format = 'unknown';
                    break;
            }

            let decodedContent = atob(base64Content);
            let byteArray = new Uint8Array(decodedContent.length); 
            for (let i = 0; i < decodedContent.length; i++) {
                byteArray[i] = decodedContent.charCodeAt(i);
            }

            let blob = new Blob([byteArray], { type: base64ContentType });
            let url = URL.createObjectURL(blob);
            let downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = name + '.' + format;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            URL.revokeObjectURL(url);
        }   
    },
    mounted() {
        this.eventBus.off('UpdateCard');
        this.eventBus.on('UpdateCard', (status) => {
            if(status == true) {
                this.updateCard();
            }
        }),
        this.eventBus.off('saveTicket');
        this.eventBus.on('saveTicket', (status) => {
            this.updateForm = false
            if(status == true){
                this.updateCard()
            }
        }),
        this.eventBus.off('saveReport');
        this.eventBus.on('saveReport', (status) => {
            this.updateForm = false
            if(status == true){
                this.updateCard()
            }
        })
        
        this.eventBus.off('updateTicket');
        this.eventBus.on('updateTicket', (status) => {
            if(status == true) {
                this.updateCard();
            }
        })

        this.eventBus.off('getCardTicket');
        this.eventBus.on('getCardTicket', (data) => {
            if(data.status == true){
                this.form = data.data
                this.componentKey += 1
            }
        })
    },
    computed:{
        perms(){
            return storeS.perms
        },
        user(){
            return storeS.userbase
        },
        checks(){
            return storeS.checks
        }
    }
}
</script>