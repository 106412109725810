<template>

    <!-- 
        Таб Файли
    -->

    <div class="card" >
        <div class="card-body" >
            <div class="mb-4 card-header border-bottom-dashed" >
                <h5 class="card-title flex-grow-1">{{ $t('Files') }}</h5>
            </div>
            <div class="row" >
                <div class="col-lg-12" >

                    <template v-for="item in form.files" :key="item">
                        <div class="d-flex align-items-center border border-dashed p-2 rounded mb-2 mt-3" >
                            
                            <!-- <div :class="`avatar-title bg-light text-${this.mutateImg(item.type).color} rounded fs-24 copy`">
                                <i :class="this.mutateImg(item.type).img"></i>
                            </div> -->
                            <div class="avatar-sm copy" @click="viewFile(item)" >
                                <div :class="`avatar-title bg-light text-${this.mutateImg(item.type).color} rounded fs-24`" >
                                    <i :class="this.mutateImg(item.type).img"></i>
                                </div>
                            </div>

                            <div class="flex-grow-1 ms-3 copy" @click="viewFile(item)" >
                                <h6 class="mb-1"><a>{{ item.name }}</a></h6>
                                <!-- <small class="text-muted">{{ item.date }}</small> -->
                            </div>

                            <div class="hstack gap-3 fs-16" >
                                
                                <!-- завантажити файл на пристрій -->
                                <button 
                                    @click="downloadFile(item)"
                                    class="copy btn btn-soft-info btn-icon"
                                >
                                    <i class="ri-download-2-line"></i>
                                </button>
                                
                                <button 
                                    @click="this.gFile = item;this.dialogShow = true;"
                                    class="copy btn btn-soft-danger btn-icon"
                                >
                                    <i class="ri-delete-bin-line"></i>
                                </button>

                            </div>

                        </div>

                    </template>

                </div>
            </div>
        </div>
    </div>

    <!-- модальне вікно перегляду файлу -->
    <modallg v-if="showImg" @close="showImg = false">
        <template v-slot:body>
            <template v-if="gFile.type == 'image/jpeg' || gFile.type == 'image/png' || gFile.type == 'image/heic'">
                <img  :src="`${gFile.src}`" style="">
            </template>
            <template v-if="gFile.type == 'video/quicktime' || gFile.type == 'video/mp4'">
                <video controls>
                    <source :src="`${gFile.src}`" type="video/mp4">
                </video>
            </template>
        </template>
        <template v-slot:buttons>
            <button class="gdownload gbtn" @click="this.downloadFile(gFile)" ><i class="ri-download-cloud-2-fill"></i></button>
            <button class="gdelete gbtn" @click="dialogShow = true" ><i class="ri-delete-bin-fill"></i></button>
            <button :class="`gprev gbtn ${ifPrevimg == true ? '' : 'disabled'}`" @click="prevOpen(gFile)" aria-label="Previous" data-taborder="2"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
            <button :class="`gnext gbtn ${ifNextimg == true ? '' : 'disabled'}`" @click="nextOpen(gFile)" aria-label="Next" data-taborder="1"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
        </template>
    </modallg>

    <!-- Модальне вікно підтвердження видалення -->
    <modalremove v-if="dialogShow" @close="dialogShow = false" @remove="this.deleteFile(gFile)" />

</template>

<script>
import modallg from '@/components/modal/img'
import modalremove from '@/components/modal/modalremove'
import { Testing } from '@/API.js';
import { 
    mutateImgFiles
} from '@/usabilityScripts/globalMutate.js'
import { storeS } from '@/store' // глобальне сховище даних

let apiServe = new Testing();

export default {
    props: ['objCard'],
    components: {
        modallg,
        modalremove
    },
    data() {
        return {
            form: '',
            dialogShow: false, // Прапорець для відображення діалогового вікна видалення файлу
            showImg: false, // Прапорець для відображення зображення
            gFile: { // Дані про обраний файл
                fileId: '',
                src: '',
                type: ''
            },
            ifPrevimg: false,
            ifNextimg: false,
            urlCaches: [], // для відео яке завантажували вже
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objCard
        },
        downloadFile(e){
            // завантажити файл на пристрій
            apiServe.getFile(e.fileId)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', e.name);
                    document.body.appendChild(link);
                    link.click();
                    storeS.uploadPercentage = 0;
                })
                .catch((error) => console.log(error));
        },
        saveUrlFile(e){
            // Збереження файлу в локал
            storeS.blob = e;
        },
        viewFile(e) {
            // Перегляд файлу

            if(e.type == 'image/jpeg' || e.type == 'image/png' || e.type == 'image/heic' || e.type == 'video/quicktime' || e.type == 'video/mp4'){
                // this.$emit('getFile', e)

                var id = e.fileId ? e.fileId : e;
                this.ifPrevimg = id == this.form.files[0].fileId ? false : true; // чи показувати стрілку на попередній док
                this.ifNextimg = id == this.form.files[this.form.files.length-1].fileId ? false : true; // чи показувати стрілку на наступний док
                
                if(storeS.blob != undefined){
                    var foundName = 'urlFrom' + this.form.testingUuid + "fileId" + id;
                    var urlCache = ''
                    var statusCache = this.urlCaches.some(function(e){ 
                        urlCache = e.url
                        return e.name == foundName;
                    });
                    if(statusCache){
                        console.log(urlCache);
                        // якщо відео є в локалстор
                        this.gFile.fileId = id;
                        this.gFile.src = urlCache;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name
                        this.showImg = true;
                    } else{
                        // якщо відео немає в локалстор - додаємо
                        apiServe.getFile(e.fileId).then(result => {

                            var blob = new Blob([result.data])
                            const url = window.URL.createObjectURL(blob);

                            let opUrl = {
                                name: 'urlFrom' + this.deadlineId + "fileId" + id,
                                url: url
                            }

                            this.urlCaches.push(opUrl)
                            this.saveUrlFile(this.urlCaches);

                            this.gFile.fileId = id;
                            this.gFile.src = url;
                            this.gFile.type = e.type;
                            this.gFile.name = e.name

                            this.showImg = true;
                            storeS.uploadPercentage = 0;

                        })
                    }

                } else {
                   apiServe.getFile(e.fileId).then(result => {

                        var blob = new Blob([result.data])
                        const url = window.URL.createObjectURL(blob);

                        let opUrl = {
                            name: 'urlFrom' + this.deadlineId + "fileId" + id,
                            url: url
                        }

                        this.urlCaches.push(opUrl)
                        this.saveUrlFile(this.urlCaches);

                        this.gFile.fileId = id;
                        this.gFile.src = url;
                        this.gFile.type = e.type;
                        this.gFile.name = e.name

                        this.showImg = true;
                        storeS.uploadPercentage = 0;

                    })
                }

            } else {
                // this.$toast.error(this.$t('fileNotSupported'));
                this.downloadFile(e)
            }
        },
        deleteFile(e){
            // Видалення файлу

            apiServe.deleteFile(e.fileId).then(result => {

                if(result.status == 'done') {
                    this.form.files = result.data.files;
                    this.dialogShow = false;
                    this.$toast.success(this.$t("Removed"))
                } else {
                    
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    // sendTelegramMessage
                    var errorText = 'Помилка видалення файлу в картці на тестування'; 
                    var statusRequest = result.status;
                    var api = 'deleteFile';
                    var fileRequest = 'src/views/software-testing/view_testing/formRight/index.vue';
                    var params = {
                        id: e.fileId
                    };
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }

            })

        },
        mutateImg(e) {
            // Функція для отримання кольору та іконки файлу
            return mutateImgFiles(e)
        },
    }
}
</script>
