<template>
    <!-- 
        Компонент Контекстного меню
    -->
    <teleport to="body">
      <transition name="fade">
        <div v-if="isVisible" id="contextMenu" class="context-menu" :style="{ left: posX + 'px', top: posY + 'px' }" @click.stop>
            <ul class="menu" :style="btn.find(el => el.children?.length > 0) ? `width: 200px` : ``"> 
                <template v-for="item in btn" :key="item" >
                    <li v-if="isShowItem(item) && item.status == true" :class="item.color" @click="handleOptionClick($event, item)">
                      <a href="#" :style="item.children?.length > 0 ? `display: flex; align-items: center` : ``">
                        <i :class="item.icon" aria-hidden="true"></i> 
                        {{ item.title }}
                        <i v-if="item.children?.length > 0" style="font-size: 24px; padding: 0; margin-left: auto;" class="bx bx-right-arrow-alt"></i>
                      </a>
                      <template v-if="item.children?.length > 0">
                        <ul 
                        class="menu" 
                        v-if="item.isOpen === true" 
                        :style="{ 
                            left: this.childrenMenu.posX + 'px', 
                            top: this.childrenMenu.posY + 'px', 
                            position: 'absolute',
                            width: '200px',
                            zIndex: 2
                          }"
                        >
                          <li v-for="child, index in item.children" :key="index" @click="handleOptionClick($event, child)">
                            <a href="#">
                              <i :class="child.icon" aria-hidden="true"></i> 
                              {{ child.title }}
                            </a>
                          </li>
                        </ul>
                      </template>
                    </li> 
                </template>
            </ul> 
        </div>
      </transition>
    </teleport>
</template>
  
<script>
import { storeS } from '@/store';

export default {
    props: ['btn'],
    data() {
      return {
        isVisible: false,
        posX: 0,
        posY: 0,
        tempItem: '',
        childrenMenu: {
          posX: 0,
          posY: 0,
        }
      };
    },
    methods: {
      showContextMenu(event, item) {
        // Виклик меню
        this.tempItem = item
        this.isVisible = true;
        this.posX = event.clientX;
        this.posY = event.clientY;
        document.addEventListener('click', this.hideContextMenu);
        console.log(this.btn)
        this.btn.forEach(el => {
          if(el.isOpen === true) {
            el.isOpen = false
          }
        })
      },
      hideContextMenu() {
        // Ховаємо меню
        this.btn.forEach(element => {
          if(element?.isOpen) {
            element.isOpen = false
          }
        });
        this.isVisible = false;
        document.removeEventListener('click', this.hideContextMenu);
      },
      handleOptionClick(event, option) {
        console.log(event, option)
        if(option?.children) {
          this.childrenMenu.posX = event.layerX
          this.childrenMenu.posY = event.layerY
          option.isOpen = !option.isOpen
        } else {
          // Опрацьовуємо вибір
          this.hideContextMenu();
          this.$emit('select', option.value, this.tempItem);
        }
      },
      isShowItem(option) {
        /**
          * Метод для перевірки, чи слід показувати пункт меню відповідно до прав доступу користувача.
          * @param {Object} option - Об'єкт з параметрами елемента.
          * @returns {boolean} - Результат перевірки на відображення елемента.
        */

        return this.perms[2000] == true || this.perms[option.perms] == true || option.perms == null;
      }
    },
    computed: {
      perms(){
        /*
            Повертає дані прав доступу користувача з глобального стору.
        */
        return storeS.perms
      }
    }
  };
</script>
  
<style scoped>
.context-menu { 
    position: absolute; 
    z-index: 5;
} 

.menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    /* box-shadow: -1px 1px 35px -11px rgba(0,0,0,0.7);
    -webkit-box-shadow: -1px 1px 35px -11px rgba(0,0,0,0.7);
    -moz-box-shadow: -1px 1px 35px -11px rgba(0,0,0,0.7); */
    padding: 10px 0;
    list-style: none;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.menu > li > a {
    font: inherit;
    font-size: 15px;
    border: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    transition: 0.3s linear;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
}

.menu > li > a:hover {
    background: #f1f7f2;
    color: #409b56;
}

.menu > li > a > i {
    padding-right: 10px;
}

.menu > li.danger > a:hover {
    color: red;
}

.menu > li.info > a:hover {
    color: #0dcaf0;
}

.menu > li.warning > a:hover {
    color: #ffc107;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s ease-in-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
  