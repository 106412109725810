<template>
    <div>
        <!-- Вкладка з табами -->
        <b-tabs 
            v-model="this.tabIndex"
            pills 
            content-class="mt-3"
        >
            <b-tab :title="$t('Main')" active>
                <!-- Головний таб -->
                <mainTab :obj="this.obj" @inRelease="inRelease" @showReopen="showReopen" @showClose="showClose" />
            </b-tab>
            <b-tab  @click="isOpenChat = true">
                <template #title>
                    <!-- Таб з чатом -->
                    <span>{{ $t('chat') }} <span v-if="this.obj.countMessages > 0" class="badge bg-info fs-12">{{ this.obj.countMessages }}</span></span> 
                </template>
                <template v-if="isOpenChat == true">
                    <chatTab :messages="this.messages" :id="this.obj.ticketId" @refreshCount="refreshCount"/>
                </template>
            </b-tab>
            <!-- Таб з клієнтами -->
            <b-tab :title="$t('clients')" >
                <clientsTab :obj="this.obj" @openclient="openclient"/>
            </b-tab>
            <b-tab >
                <!-- Таб з тасками по тікету -->
                <taskTab :obj="this.obj" @updateCard="this.$emit('updateCard')" :bigger="this.bigger"/>
                <template #title>
                    <span>{{ $t('tasks') }} <span v-if="obj.tasks.length > 0" class="badge bg-info fs-12">{{ obj.tasks.length }}</span></span> 
                </template>
            </b-tab>
            <b-tab >
                <!-- Таб з пристроями -->
                <devicesTab :obj="this.obj" @updateCount="updateCount"/>
                <template #title>
                    <span>{{ $t('devices') }} <span v-if="countDevices > 0" class="badge bg-secondary fs-12">{{ countDevices }}</span></span> 
                </template>
            </b-tab>
            <!-- Таб з Репортами -->
            <b-tab :title="$t('reports')" v-if="this.obj.reports != false">
                <reportsTab :obj="this.obj" />
            </b-tab>
            <b-tab v-if="this.obj.reports != false">
                <!-- Таб з файлами -->
                <filesTab :obj="this.obj"/>
                <template #title>
                    <span>
                        {{ $t('Files') }} 
                        <span v-if="obj.files.length > 0" class="badge bg-warning fs-12">{{ obj.files.length }}</span>
                    </span> 
                </template>
            </b-tab>
            <!-- Таб з призначеннями -->
            <b-tab :title="$t('appointed')" >
                <workersTab :obj="this.obj" />
            </b-tab>
            <b-tab v-if="this.obj.reports === false">
                <!-- Таб з файлами -->
                <filesTab :obj="this.obj"/>
                <template #title>
                    <span>
                        {{ $t('Files') }} 
                        <span v-if="obj.files.length > 0" class="badge bg-warning fs-12">{{ obj.files.length }}</span>
                    </span> 
                </template>
            </b-tab>
            <b-tab v-if="this.obj?.hashtags?.length > 0" @click="isOpenTags = true">
                <template #title>
                    <!-- Таб з хештегами -->
                    <span>{{ $t('ticketsWithSimillarTags') }}</span> 
                </template>
                <template v-if="isOpenTags == true">
                    <tagsTab :ticket="this.obj"/>
                </template>
            </b-tab>
            <!-- Таб з історією змін -->
            <b-tab :title="$t('Story')">
                <historyOfChange :obj="this.obj"></historyOfChange>
            </b-tab>

            <!-- Таб з таймланом -->
            <!-- <template v-if="true"> -->
                <b-tab @click="isOpenTimeline = true">
                    <!-- <timelineTab :obj="this.obj" /> -->
                    <template #title>
                        <!-- Таб з Timeline -->
                        <span>{{ $t('Timeline') }}</span> 
                    </template>
                    <template v-if="isOpenTimeline == true">
                        <timelineTab :obj="this.obj"/>
                    </template>
                </b-tab>
            <!-- </template> -->

        </b-tabs>
    </div>

</template>

<script>
import historyOfChange from './history/index'
import taskTab from './tasks/index'
import mainTab from './main/index'
import chatTab from './chat/index'
import clientsTab from './clients/index'
import devicesTab from './devices/index'
import reportsTab from './reports/index'
import workersTab from './workers/index'
import filesTab from './files/index'
import timelineTab from './timeline/index'
import tagsTab from "./tags/index.vue"
import { storeS } from '@/store'

export default ({
    props: ['obj', 'activeTab', 'messages'],
    components: {
        historyOfChange,
        mainTab,
        chatTab,
        clientsTab,
        devicesTab,
        reportsTab,
        workersTab,
        filesTab,
        taskTab,
        timelineTab,
        tagsTab
    },
    data(){
        return{
            isOpenTags: false,
            bigger: true,
            form:'',
            tabIndex: 0,
            isOpenChat: false,
            isOpenTimeline: false,
            countNewMsg: '',
            countDevices: ''
        }
    },
    created(){
        if(this.activeTab && this.activeTab == 'chat'){
            this.tabIndex = 1
            this.isOpenChat = true
        }
        this.newMsgs()
        this.countDevices = this.obj.devices.length;
    },
    watch: {
        // Слідкуємо за змінами в інсексі і кидаємо його в стор
        tabIndex (newIdx) {
            storeS.tabIndex = newIdx
            // Блок на завантаження файлів
            // @/components/files/index.vue - блок на файли тут
        }
    },
    methods: {
        inRelease(e) {
            this.$emit('inRelease', e)
        },
        showReopen(e) {
            this.$emit('showReopen', e)
        },
        showClose(e) {
            this.$emit('showClose', e)
        },
        openclient(e){
            this.$emit('openclient', e)
        },
        newMsgs(){
            var id = this.obj.ticketId;
            this.countNewMsg = storeS.readMsg.filter(item => item.ticketId == id).length
        },
        refreshCount(){
            this.countNewMsg = []
        },
        updateCount(count){
            this.countDevices = count;
        }
    },
    computed: {
        tab() {
            return storeS.tabIndex
        },
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        }
    }
})

</script>