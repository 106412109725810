<template>
    <div class="card">
        <div class="card-header">
        </div>
        <div class="card-card-body">
            <tablecustom
                :rows="this.rows"
                :columns="this.columns"
                @open="openTicket"
            />
        </div>
    </div>
</template>

<script>
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { 
    mutateTypeTicket, 
    mutateStatusTicket,
    mutateDecidedStatusTicket,
    mutatePriorityTicket,
    mutateProblemEnv,
    nameTag
} from '@/usabilityScripts/globalMutate.js'
import {Tickets} from "@/API.js"
import tablecustom from "@/components/globaltable/index"

let apiServe = new Tickets()

export default {
    props: ['ticket'],
    components: {
        tablecustom
    },
    data() {
        return {
            objParams:{
                page: '1',
                pagelimit: '25',
                search: '',
                priority: '',
                status: '',
                decidedStatus: '',
                type: '',
                workerId: '',
                inJobWorkerId: '',
                problemEnvironment: '',
                pr1: '',
                pr2: '',
                tags: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            rows: [],
            columns: [
                {
                    name: "ID",
                    text: "ticketId",
                    align: "left",
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("ticket"),
                    text: "type",
                    align: "left",
                    mutate: (item) => this.mutateticket(item),
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("problem_area"),
                    text: "problemEnvironment",
                    align: "left",
                    mutate: (item) => item != null ? this.mutateProblemEnv(item) : '-',
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("ticket_name"),
                    text: "ticketName",
                    align: "left",
                    status: true,
                    mutate: (item) => this.mutateTicketName(item),
                    width: '10'
                },
                {
                    name: this.$t("priority"),
                    text: "priority",
                    align: "right",
                    mutate: (item) => this.mutatepriority(item).name,
                    mutateClass: (item) => this.mutatepriority(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("added"),
                    text: "createWorkerId",
                    align: "right",
                    mutate: (item) => this.nameWorker(item),
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("date"),
                    text: "createDatetime",
                    align: "right",
                    status: true,
                    sort: 'create_datetime',
                    sortType: 'asc',
                    width: '10'
                },
                {
                    name: this.$t("Status"),
                    text: "status",
                    align: "right",
                    mutate: (item) => this.mutatestatus(item).name,
                    mutateClass: (item) => this.mutatestatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("decidedStatus"),
                    text: "decidedStatus",
                    align: "right",
                    mutate: (item) => this.mutateDecidedStatus(item).name,
                    mutateClass: (item) => this.mutateDecidedStatus(item).color,
                    status: true,
                    width: '10'
                },
                {
                    name: this.$t("clients"),
                    text: "userPhones",
                    align: "right",
                    mutate: (item) => item ? this.mutateNumber(item) : '-',
                    status: false,
                    width: '10'
                },
                {
                    name: this.$t("labels"),
                    text: "tags",
                    align: "right",
                    status: false,
                    mutate: (item) => item ? this.nameTag(item) : '-',
                    width: '10'
                },
            ]
        }
    },
    created() {
        this.getdata()
    },
    methods: {
        mutateticket(e){
            // мутейт типу тікета
            return mutateTypeTicket(e)
        },
        mutateProblemEnv(e){
            // мутейт середовища проблеми
            return mutateProblemEnv(e)
        },
        // скорочує назву тікету
        mutateTicketName(e) {
            let name = ''
            if(e.length > 50) {
                name = e.slice(0, 46) + '...'
            } else {
                name = e
            }
            return name
        },
        mutatepriority(e){
            // мутейт пріорітету
            return mutatePriorityTicket(e)
        },
        // мутейт співробітника
        nameWorker(item){
            return nameWorker(item)
        },
        mutatestatus(e){
            // мутейт статусу
            return mutateStatusTicket(e)
        },
        mutateDecidedStatus(e){
            // мутейт типу статусу, коли тікет "Виконано"
            return mutateDecidedStatusTicket(e)
        },
        mutateDecidedStatus(e){
            // мутейт типу статусу, коли тікет "Виконано"
            return mutateDecidedStatusTicket(e)
        },
        // мутейт телефонів клієнта
        mutateNumber(value){
            var r = []
            if(value != ''){
                for(var item in value){
                r.push('<span class="fs-11 badge badge-soft-info">'+value[item]+'</span>')
                }
            }
            return r
        },  
        nameTag(value){
            var r = []
            if(value != ''){
                for(var item in value){
                r.push('<span class="fs-11 fw-semibold" style="background:' + nameTag(value[item]).color + ';border-radius:5px;padding:5px;color:white;">'+nameTag(value[item]).name+'</span>')
                }
            }
            return r
        },
        // отримання інформації
        getdata(page){
            this.objParams.hashtags = JSON.stringify(this.ticket.hashtags.map(el => {return el.hashtagId}))
            apiServe.getTickets(this.objParams).then(result => {
                if(result.status === 'done') {
                this.rows = result.data.items;  
                } else {
                    this.$toast.error(this.$t('error') + ` #300`);
                }
            }).catch(err => {
                this.$toast.error(this.$t('error') + ` # 300`);
            })
        },
        openTicket(id) {
            var id = id.ticketId ? id.ticketId : id;
            
            apiServe.getTicket(id).then(result => {
                if(result.status == 'done'){
                    if(!Array.isArray(result.data.userPhones)) {
                        this.$toast.error(this.$t('error'));

                        var errorText = 'Помилка відкриття тікету. Номери string'; 
                        var statusRequest = "error";
                        var api = 'getTicket';
                        var fileRequest = 'src/views/newtickets/all.vue';
                        var params = id;
                        var response = result.data.userPhones

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                        return
                    }

                    this.eventBus.emit('modalInModal', {
                        status: true,
                        data: result.data,
                        type: 'ticket',
                    })
                } else {
                    this.$toast.error(this.$t('error'));

                    var errorText = 'Помилка відкриття тікету'; 
                    var statusRequest = result.status;
                    var api = 'getTicket';
                    var fileRequest = 'src/views/newtickets/all.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
    }
}
</script>

<style>

</style>