<template>
    <b-card>
        <div class="card-header">
            <b-row>
                <div class="col">
                    <h5 class="card-title mb-0">
                        <b>
                            Несинхронізовані документи
                            <span class="text-muted">{{this.data.unsyncDocuments.items.filter(items => items.syncAfterReport === false).length}} шт.</span>
                        </b>
                    </h5>
                </div>
                <div class="col-auto" >
                    <button 
                        v-if="this.data.unsyncDocuments.items.length > 0" 
                        @click="checkBtnAllSyns = true; syncAllChecks()"
                        class="btn btn-soft-danger fs-12"
                    >
                        Синхронізувати всі beta
                    </button>
                </div>
            </b-row>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <!-- Табличка айдішників -->
                <table class="table" v-if="this.data.unsyncDocuments.items.filter(items => items.syncAfterReport === false).length > 0">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <!-- <th>{{this.$t('Status')}}</th> -->
                            <th>{{this.$t('Sum')}}</th>
                            <th>{{this.$t('date_time')}}</th>
                            <th>{{this.$t('action')}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item, i in this.data.unsyncDocuments.items.filter(items => items.syncAfterReport === false)" :key="i">
                            <th>{{ item.orderId }}</th>
                            <!-- <td>{{ this.mutateStatus(item.syncAfterReport) }}</td> -->
                            <td>{{ item.sum }}</td>
                            <td>{{ item.datetime }}</td>
                            <td>
                                <button 
                                    @click="syncCheck(item.orderId)"
                                    v-if="item.syncAfterReport === false" 
                                    class="btn btn-danger"
                                >
                                    {{ this.$t('sync') }}
                                </button>
                                <p v-else>
                                    {{ this.$t('Synchronized') }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- Результатів нема -->
                <div v-else class="noresult" style="display: block;">
                    <div class="text-center" >
                        <h5 class="mt-2">{{ $t('goodNews') }}!</h5>
                        <p class="text-muted mb-0">{{ $t('unSyncDocsNotFound') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import tablecustom from "@/components/globaltable/index"
import { Documents } from "@/API_Dash.js"
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
import { storeS } from '@/store'

let apiServe = new Documents()

export default{
    props: ['data'],
    data(){
        return {
            syncing: false, // Прапорець для статусу синхронізації
            checkBtnAllSyns: false // Прапорець відстеження чи натиснули синхронізувати всі 
        }
    },
    components: {
        tablecustom
    },
    created(){
        
    },
    methods: {
        // Мутейт статусів
        mutateStatus(status) {
            if(status === true) {
                return this.$t('Synchronized')
            } else {
                return this.$t('OutofSync')
            }
        },
        // Функція синхронізації чеків
        // syncCheck(orderId) {
        //     apiServe.syncCheck(this.data.reportId, this.data.companyId, orderId).then(result => {
        //         if(result.data.status == 'done') {
        //             console.log(result)
        //             this.$toast.success(this.$t('Synchronized'))
        //             this.$emit('getReport', this.data.reportId)
        //         } else {
        //             this.switchError(result.error[0], result)
        //         }
        //     })
        // },
        syncCheck(orderId) {
            return apiServe
                .syncCheck(this.data.reportId, this.data.companyId, orderId)
                .then((result) => {
                    if (result.data.status === "done") {
                        this.$toast.success(this.$t("Synchronized"));
                        if (this.checkBtnAllSyns == false) {
                            this.$emit("getReport", this.data.reportId);
                        }
                    } else {
                        if (this.checkBtnAllSyns == false) {
                            this.switchError(result.data.error[0])
                        } else {
                            throw new Error(result.data.error[0]);
                        }
                    }
                });
        },
        async syncAllChecks() {
            if (this.syncing) return;

            this.syncing = true;

            for (const item of this.unsyncedDocuments) {
                try {
                    await this.syncCheck(item.orderId);
                } catch (error) {
                    this.switchError(error.message)
                    break; // Зупиняємо цикл у разі помилки
                }
            }

            this.$emit("getReport", this.data.reportId); // Оновлюємо після завершення синхронізації всіх чеків
            this.checkBtnAllSyns = false;
            this.syncing = false;
        },
        switchError(error){
            // відображення помилки

            // switch (error, result) {
            switch (error) {

                case "company is not paid":
                    this.$toast.error(this.$t('CompanyIsnotPaid'))
                    break;
            
                default:
                    this.$toast.error(error);

                    var errorText = 'Помилка синхронізації чеків'; 
                    var statusRequest = "error";
                    var api = 'syncCheck';
                    var fileRequest = 'src/views/accounts/allAccounts/viewnew/blocks/activity/checks/blocks/unSyncDocs.vue';
                    var params = JSON.stringify(
                        {
                            "orderId": orderId,
                            "reportId": this.data.reportId,
                            "companyId": this.data.companyId,
                        }
                    );
                    var response = result;

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                    
                    break;
            }
 
        },
    },
    computed: {
        checks(){
            return storeS.checks
        },
        unsyncedDocuments() {
            return this.data.unsyncDocuments.items.filter(
                (item) => !item.syncAfterReport
            );
        },
        unsyncedCount() {
            return this.unsyncedDocuments.length;
        },
    }
}
</script>
