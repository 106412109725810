<template>
    <!-- Верхній навбар картки клієнта -->

    <!-- Відображення статусу клієнта -->
    <span class="d-none d-lg-flex d-xl-flex">
        <div class="d-flex" style="min-width:200px">
            <statusclient :clientstatus="form.status" :phone="this.obj.personalData?.phone"/>
        </div>
    </span>

    <!-- Відкрити вікно калькулятора -->
    <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="opencalculator()" 
        :title="$t('openCalc')"
    >
        <i class="bx bx-calculator align-bottom"></i>
    </button>

    <!-- Відкрити вікно магазину -->
    <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="openshop()" 
        :title="$t('openShop')"
    >
        <i class="bx bxs-shopping-bags align-bottom"></i>
    </button>

    <!-- Відкрити вікно статистики -->
    <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="openstatistics()" 
        :title="$t('openStatistics')"
    >
        <i class="bx bx-analyse align-bottom"></i>
    </button>

    <!-- Відкрити вікно бази знань -->
    <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="openknowledge()" 
        :title="$t('openKnowledge')"
    >
        <i class="ri-information-line align-bottom"></i>
    </button>

    <!-- Відкрити вікно FAQ -->
    <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="openfaq()" 
        :title="$t('openFaq')"
    >
        <i class="ri-question-line align-bottom"></i>
    </button>
    
    <!-- Відкрити вікно надсилання листа (Юра попросив прибрати звідси) -->
    <!-- <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="openmailbox()"
    >
        <i class="ri-mail-send-line align-bottom"></i>
    </button> -->

    <!-- Відкрити вікно надсилання смс (Юра попросив прибрати звідси) -->
    <!-- <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="opensmsbox()" 
        :title="$t('sendSms')"
    >
        <i class="bx bxs-message-dots align-bottom"></i>
    </button> -->

    <!-- Відкрити вікно скриптів (Юра попросив прибрати звідси) -->
    <!-- <button 
        class="d-none d-lg-flex d-xl-flex btn btn-ghost-secondary btn-icon btn-sm fs-16" 
        @click="openScriptBox()" 
        :title="$t('openScript')"
    >
        <i class='bx bxs-spreadsheet'></i>
    </button> -->

    <!-- Створення картки моніторингу -->
    <button 
        class="d-none d-lg-flex d-xl-flex btn btn-success" 
        @click="this.$emit('addToMonitoring', this.obj.userPhone, this.objComp.companyId)" 
        :title="$t('addToMonitor')"
        style="min-width: 100px;"
    >
        {{ this.$t('addToMonitor') }}
    </button>
    
    <!-- Кнопка для виклику додаткових опцій у випадаючому списку -->
    <div class="dropdown">

        <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bxs-plus-circle align-bottom"></i>
        </button>

        <!-- Випадаюче меню з додатковими опціями -->
        <div class="dropdown-menu dropdown-menu-end">

            <!-- Відкрити вікно створити заявку -->
            <button class="dropdown-item" @click="openapplicbox()">{{ $t('Application') }}</button>

            <!-- Відкрити вікно створити завдання -->
            <button class="dropdown-item" @click="opentaskBox()">{{ $t('Task') }}</button >

            <!-- Відкрити вікно створити тікет -->
            <button class="dropdown-item" @click="openticketBox()">{{ $t('ticket') }}</button > 

            <!-- Відкрити вікно смс -->
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="opensmsbox()">{{ $t('Message') }}</button > 

            <!-- Відкрити вікно email --> 
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item">{{ $t('E-mail') }}</button > 

            <!-- Відкрити вікно коментарів -->
            <button class="dropdown-item" @click="openblockComment()">{{ $t('Comment') }}</button >

            <!-- Відкрити вікно створити налаштування для підключення -->
            <button class="dropdown-item" @click="createdconnectbox()">{{ $t('settings_connects') }}</button >
                
            <!-- Відкрити вікно створити послугу -->
            <button class="dropdown-item" @click="openservicebox()">{{ $t('service') }}</button >

            <!-- Відкрити вікно створити рахунок -->
            <button class="dropdown-item" @click="openinvoicesbox()">{{ $t('invoic') }}</button >

            <!-- Розділювач для мобільного вигляду -->
            <div class="dropdown-divider d-flex d-sm-flex d-md-flex d-lg-none d-xl-none"></div>
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openshop()">{{ $t('shop_title') }}</button >
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openstatistics()">{{ $t('statistics') }}</button >
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openknowledge()">{{ $t('Knowledge_base') }}</button >
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openfaq()">{{ $t('qua_and_ans') }}</button >
            <button class="d-flex d-sm-flex d-md-flex d-lg-none d-xl-none dropdown-item" @click="openScriptBox()">{{ $t('openScript') }}</button >
        </div>
    </div>
    
    <!-- Кнопки для небезпечних операцій, доступні тільки при відповідних дозволах та у певних умовах -->
    <div class="dropdown" v-if="(this.objComp && this.objComp?.more?.companyServer != 's-1') && perms[2000]">
        <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bx bx-dots-vertical-rounded align-bottom"></i>
        </button>
        
        <!-- Випадаюче меню з небезпечними операціями -->
        <div class="dropdown-menu dropdown-menu-end"> 
            <label class="text-muted fs-12 fw-semibold w-100 border-bottom border-bottom-dashed pb-2" style="padding-left: 20px;">{{ $t('DangerousOperations') }}</label>

            <!-- Продовжити оплату рахунку/змінити день наступної оплати -->
            <button 
                class="dropdown-item"
                @click="$emit('extendPayment')"
            >
                {{ $t('ContinueAccountPayment') }}
            </button > 

            <!-- <button class="dropdown-item" @click="$emit('replenBalance', 'replen')">{{ $t('ToppingUptheAccountBalance') }}</button > --> <!-- Поповнення балансу компанії -->
            <!-- <button class="dropdown-item" @click="$emit('replenBalance', 'debit')">{{ $t('DebitFundsFromBalance') }}</button > --> <!-- Списання коштів із балансу -->
            
            <!-- Змінити плати за підписку/змінити коф -->
            <button 
                class="dropdown-item" 
                @click="$emit('changeSubsfee')"
            >
                {{ $t('ChangeSubscriptionFee') }}
            </button> 
        </div>
    </div>
    
    <!-- Кнопка додавання в обране (список юзера) -->
    <button 
        v-if="this.user.userid == '34' || this.user.userid == '32' || this.user.userid == '43' || this.user.userid == '52'"
        class="btn btn-ghost-warning btn-icon btn-sm fs-16" 
        @click="checkFavorite == false ? objFavorite.show = true : removeFavotire()" 
        :title="checkFavorite == false ? $t('AddtoFavorites') : $t('RemoveFromFavorites')"
    >
        <i :class="`bx ${checkFavorite == false ? 'bx-star' : 'bxs-star'} align-bottom`"></i>
    </button>

    <!-- Кнопка отримання url доступу до акаунту -->
    <button 
        v-if="perms[2000]"
        class="btn btn-ghost-danger btn-icon btn-sm fs-16" 
        @click="openClientAcc()" 
    >
        <i :class="`bx bx-link-alt align-bottom`"></i>
    </button>

    <!-- Кнопка для закриття вікна -->
    <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')" :title="$t('close')"><i class="ri-close-fill align-bottom"></i></button>

    <!-- Модальне вікно додавання клієнта в обрані -->
    <addFavorite
        v-if="objFavorite.show"
        @close="objFavorite.show = false"
        :obj="this.form"
        :objForm="objFavorite"
        @changeFav="checkFavorite = true"
    />

</template>

<script>
import statusclient from './clientstatus'
import addFavorite from '@/views/dashboard/favorites/add'
import { storeS } from '@/store';
import { SuperAdmin } from '@/API'
import { copy } from '../../../../usabilityScripts/copy';

let apiServe = new SuperAdmin();

export default {
    props: ['obj', 'objComp'],
    components: { statusclient, addFavorite },
    data(){
        return {
            form: "",
            checkFavorite: false,
            objFavorite: {
                show: false,
                obj: {},
                permEdit: false,
            },
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        openScriptBox() {
            this.$emit('openScriptBox')
        },
        openmailbox() {
            this.$emit('openmailbox')
        },
        updatedata(){
            this.$emit('updatedata')
        },
        openshop(){
            this.$emit('openshop')
        },
        openstatistics(){
            this.$emit('openstatistics')
        },
        openknowledge(){
            this.$emit('openknowledge')
        },
        openfaq(){
            this.$emit('openfaq')
        },
        opensmsbox(){
            this.$emit('opensmsbox')
        },
        openapplicbox(){
            this.$emit('openapplicbox')
        },
        opentaskBox(){
            this.$emit('opentaskBox')
        },
        openticketBox(){
            this.$emit('openticketBox')
        },
        openservicebox(){
            this.$emit('openservicebox')
        },
        createdconnectbox(){
            this.$emit('createdconnectbox')
        },
        openblockComment(){
            this.$emit('openblockComment')
        },
        openinvoicesbox(){
            this.$emit('openinvoicesbox')
        },
        opencalculator(){
            this.$emit('opencalculator')
        },
        addFavorite(){
            // Додаємо акаунт в обрані

            let accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];
            
            let newAcc = {
                "accountId": this.form.accountId,
                "userPhone": this.form.userPhone,
                "type": 'account',
                "date": this.currentDate()
            };

            // Перевірка, чи існує номер в масиві
            let existingAccount = accounts.find(acc => acc.userPhone === newAcc.userPhone);

            if (!existingAccount) {
                accounts.push(newAcc);
                localStorage.setItem('favoriteAccounts', JSON.stringify(accounts));
                this.checkFavorite = true
                this.$toast.success("numberAddedtoFavorites")
            } else {
                console.log('Account already exists in the favorites.');
                this.$toast.error("AccountAlreadyExistsFavorites")
            }
        },
        removeFavotire(){
            // Видаляємо акаунт з обраних

            let accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];

            let targetUserPhone = this.form.userPhone;

            // Пошук індексу обраного акаунта за userPhone
            let indexToRemove = accounts.findIndex(acc => acc.userPhone === targetUserPhone);

            if (indexToRemove !== -1) {
                // Видаляємо акаунт за індексом
                accounts.splice(indexToRemove, 1);
                localStorage.setItem('favoriteAccounts', JSON.stringify(accounts));
                this.checkFavorite = false
                this.$toast.success("numberRemovedfromFavorites");
            } else {
                this.$toast.error("AccountNotFoundinFavorites");
            }
        },
        currentDate() {
            var date = new Date();
            var dateStr =
            date.getFullYear() + "-" +
            ("00" + (date.getMonth() + 1)).slice(-2) + "-" +
            ("00" + (date.getDate())).slice(-2) + " " +
            ("00" + date.getHours()).slice(-2) + ":" +
            ("00" + date.getMinutes()).slice(-2);
            return dateStr
        },
        openClientAcc(){
            apiServe.entryInUserAccount(this.form.accountId).then(result => {
                if(result.status == "done"){
                    var url = "https://cabinet.skyservice.online/?token="+result.data.token;
                    // копіювання
                    if(copy(url) == true) {
                        this.$toast.success(this.$t('Copied'));
                    } else {
                        this.$toast.error(`Fucking mistake, contact Borys`);
                    }
                    return
                } else {
                    this.$toast.error(this.$t('err'))
                }
            })
        }
    },
    mounted(){
        // Отримайте поточний масив акаунтів з localStorage
        let accounts = JSON.parse(localStorage.getItem('favoriteAccounts')) || [];

        // номер клієнта, яке ми шукаємо
        let accToCheck = this.form.userPhone;

        // Перевірте, чи існує номер в масиві
        let isIdList = accounts.some(item => item.userPhone === accToCheck);

        // isIdList має значення true, якщо id присутнє в масиві
        if (isIdList) {
            this.checkFavorite = true
        } else {
            this.checkFavorite = false
        }
    },
    computed:{
        perms(){
            return storeS.perms
        },
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
    }
}
</script>
